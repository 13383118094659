import React from 'react';
import {inject, observer} from 'mobx-react';

import PCPageLoading from '../../shared/components/page-loading/page-loading.component';

import './pc-loading-overlay.component.scss';

@inject('baseStore')
@observer
class PCLoadingOverlay extends React.Component {
    render() {
        if (!this.props.baseStore.loadingOverlayVisible) {
            return null;
        }

        return (
            <div className='loading-overlay'>
                <PCPageLoading/>
            </div>
        );
    }
}

export default PCLoadingOverlay;
