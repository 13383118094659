import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';

@inject('checkoutStore')
@observer
class PcCheckoutSummaryTotalPartsWeightDisplay extends React.Component {
    render() {
        return (
            <PCPriceDisplay
                value={this.props.weight}
                currency={this.props.unit}
                inline={true}
            />
        );
    }
}

PcCheckoutSummaryTotalPartsWeightDisplay.propTypes = {
    weight: PropTypes.number,
    unit: PropTypes.string,
    inline: PropTypes.bool
};

export default PcCheckoutSummaryTotalPartsWeightDisplay;
