import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchWithAuth, fetchJsonWithAuth} from '../fetch-json.util';

class CustomerInfoService {
    static getCustomerInfo() {
        return fetchJsonWithAuth(
            buildUrl(CUSTOMER_INFO_URL)
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }

    static postCustomerInfo(dealerId) {
        return fetchWithAuth(
            buildUrl(CUSTOMER_INFO_URL, {
                queryParams: {
                    'dealer-id': dealerId
                }
            }),
            {
                method: 'POST'
            }
        );
    }

    static putCustomerInfo(newDealerId) {
        return fetchWithAuth(
            buildUrl(CUSTOMER_INFO_URL, {
                queryParams: {
                    'new-dealer-id': newDealerId
                }
            }),
            {
                method: 'PUT'
            }
        );
    }
}

export default CustomerInfoService;
