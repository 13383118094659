import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import './pc-checkout-step-container.component.scss';

@inject('checkoutStore')
@observer
class PCCheckoutStepContainer extends React.Component {
    constructor(props) {
        super(props);

        this.editStep = this.editStep.bind(this);
    }

    editStep() {
        this.props.checkoutStore.activeCheckoutStep = this.props.id;
    }

    render() {
        let children;

        if (this.props.checkoutStore.activeCheckoutStep === this.props.id) {
            children = (
                <div className='step-content'>
                    {this.props.children}
                </div>
            );
        }

        let editIcon;

        if (this.props.checkoutStore.activeCheckoutStep !== this.props.id &&
            _.indexOf(this.props.checkoutStore.filledCheckoutSteps, this.props.id) !== -1) {
            editIcon = (
                <button
                    className='edit-icon pull-right'
                    onClick={this.editStep}
                />
            );
        }

        return (
            <div className='checkout-step'>
                <div className='step-header'>
                    <span className='step-number'>
                        {_.indexOf(this.props.checkoutStore.availableCheckoutSteps, this.props.id) + 1}
                    </span>
                    <span className='step-title'>
                        {this.props.title}
                    </span>
                    {editIcon}
                </div>
                {children}
            </div>
        );
    }
}

PCCheckoutStepContainer.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default PCCheckoutStepContainer;
