import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {action} from 'mobx';
import {inject, observer} from 'mobx-react';

const MAX_EXPIRATION_MONTHS = 12;
const MAX_EXPIRATION_YEARS = 20;

@inject('checkoutStore')
@observer
class PCCheckoutBillingForm extends React.Component {
    constructor(props) {
        super(props);

        this.attachMicroform = this.attachMicroform.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
    }

    componentWillUnmount() {
        this.props.checkoutStore.microform.destroy();
    }

    attachMicroform(ref) {
        this.props.checkoutStore.microform.attach(
            ref,
            '#card-number-label',
            '#card-number-container',
            this.props.checkoutStore.merchantInfo.paymentFlexResponse.jsonWebKey
        );
    }

    @action.bound
    onInputChange(event) {
        this.props.checkoutStore.transactionInfo[event.target.name] = event.target.value;
    }

    render() {
        const now = new Date();
        const {
            merchantInfo,
            transactionInfo
        } = this.props.checkoutStore;

        return (
            <div>
                <div className='form-group'>
                    <label>
                        <FormattedMessage
                            defaultMessage='Select Card Type'
                            id='pc-checkout-billing-form.card-type'
                        />
                        <select
                            className='form-control'
                            name='cardType'
                            onChange={this.onInputChange}
                            value={transactionInfo.cardType}
                        >
                            <option/>
                            {
                                _.map(merchantInfo.cardType, (cardType, index) => {
                                    return (
                                        <option
                                            key={index}
                                            value={cardType.value}
                                        >
                                            {cardType.displayValue}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </label>
                </div>

                <div className='form-group'>
                    <label id='card-number-label'>
                        <FormattedMessage
                            defaultMessage='Credit Card Number'
                            id='pc-checkout-billing-form.card-number'
                        />
                    </label>
                    <div
                        id='card-number-container'
                        className='form-control card-number-container'
                    >
                        <input
                            type='text'
                            ref={this.attachMicroform}
                            style={{
                                display: 'none'
                            }}
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='form-group col-md-14'>
                        <label>
                            <FormattedMessage
                                defaultMessage='Expiration Date'
                                id='pc-checkout-billing-form.expiration-date'
                            />
                        </label>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <select
                                    className='form-control'
                                    name='cardExpirationMonth'
                                    onChange={this.onInputChange}
                                    value={transactionInfo.cardExpirationMonth}
                                >
                                    <option/>
                                    {
                                        _.map(
                                            _.range(1, MAX_EXPIRATION_MONTHS + 1),
                                            (month) => {
                                                const paddedMonth = _.padStart(month, 2, '0');

                                                return (
                                                    <option
                                                        key={month}
                                                        value={paddedMonth}
                                                    >
                                                        {paddedMonth}
                                                    </option>
                                                );
                                            })
                                    }
                                </select>
                            </div>
                            <div className='col-xs-12'>
                                <select
                                    className='form-control'
                                    name='cardExpirationYear'
                                    onChange={this.onInputChange}
                                    value={transactionInfo.cardExpirationYear}
                                >
                                    <option/>
                                    {
                                        _.map(
                                            _.range(
                                                now.getFullYear(),
                                                now.getFullYear() + MAX_EXPIRATION_YEARS + 1
                                            ),
                                            (year) => {
                                                return (
                                                    <option
                                                        key={year}
                                                        value={year}
                                                    >
                                                        {year}
                                                    </option>
                                                );
                                            }
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PCCheckoutBillingForm;
