
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import PCPriceDisplay from '../shared/components/price-display/price-display.component';
import PCWeightDisplay from '../shared/components/weight-display/weight-display.component';

const PCOrderCompleteLine = ({
    currency, showTaxPerLineitem, orderLine
}) => {
    let emergencyOrder;

    if (orderLine.isEmergencyOrderLine) {
        emergencyOrder = (
            <div>
                <FormattedMessage
                    defaultMessage='Emergency Order'
                    id='pc.emergency-order'
                />
            </div>
        );
    }

    let taxPerLineItem = orderLine.totalPriceIncludingTax - orderLine.totalNetPrice;

    return (
        <React.Fragment>
            <tr >
                <td className='part-description'>
                    {orderLine.partDescription || orderLine.partNumber}
                </td>
                <td rowSpan={2} style={{textAlign: 'right'}}>
                    <PCWeightDisplay
                        weight={orderLine.weight}
                        unit={orderLine.weightUnit}
                    />
                </td>

                <td rowSpan={2} style={{textAlign: 'center', minWidth: '125px'}}>
                    {orderLine.quantity}
                    {emergencyOrder}
                </td>
                <td rowSpan={2} style={{textAlign: 'center'}}>
                    {orderLine.orderType}
                </td>
                <td rowSpan={2} style={{textAlign: 'right'}}>
                    <PCPriceDisplay
                        currency={currency}
                        inline={true}
                        value={orderLine.unitPrice}
                    />
                </td>
                <td rowSpan={2} style={{textAlign: 'right'}}>
                    <PCPriceDisplay
                        currency={currency}
                        inline={true}
                        value={orderLine.totalNetPrice}
                    />
                </td>
                <td rowSpan={2} style={{textAlign: 'right', display: showTaxPerLineitem ? '' : 'none'}}>
                    <PCPriceDisplay
                        currency={currency}
                        inline={true}
                        value={taxPerLineItem}
                    />
                </td>
                <td rowSpan={2} style={{textAlign: 'right', display: showTaxPerLineitem ? '' : 'none'}}>
                    <PCPriceDisplay
                        currency={currency}
                        inline={true}
                        value={orderLine.totalPriceIncludingTax}
                    />
                </td>
            </tr>
            <tr>
                <td style={{paddingBottom: '10px', paddingTop: 0}}>
                    <FormattedMessage
                        defaultMessage='Part Number'
                        id='pc.part-number'
                    />
                    {' '}
                    <span>{orderLine.partNumber}</span>
                </td>
            </tr>
        </React.Fragment>


    );
};

PCOrderCompleteLine.propTypes = {
    currency: PropTypes.string.isRequired,
    showTaxPerLineitem: PropTypes.bool,
    orderLine: PropTypes.shape({
        partNumber: PropTypes.string.isRequired,
        partDescription: PropTypes.string,
        totalPriceIncludingTax: PropTypes.number,
        unitPrice: PropTypes.number,
        quantity: PropTypes.number.isRequired,
        totalNetPrice: PropTypes.number
    }).isRequired
};

export default PCOrderCompleteLine;
