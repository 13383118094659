import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from './../fetch-json.util';

class ShippingMethodsService {
    static getShippingMethods(countryCd, localeCd, dealerId) {
        return fetchJsonWithAuth(
            buildUrl(SHIPPING_METHODS_URL, {
                queryParams: {
                    'iso2-country-code': countryCd,
                    'locale-cd': localeCd,
                    'dealer-id': dealerId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default ShippingMethodsService;
