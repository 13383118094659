import {action, computed, observable, reaction} from 'mobx';
import OrdersService from '../shared/services/orders.service';
import _ from 'lodash';
import {subDays, subMonths, endOfYear, startOfYear, subYears} from 'date-fns';

const NUM_DAYS = 30;
const NUM_MONTHS = 6;
const NUM_YEARS = 2;

export default class PCOrdersStore {
    @observable orders;
    @observable detailedOrder;
    @observable searchKey = '';
    @observable dateRangeIndex = 0;
    @observable orderStatus;

    constructor(baseStore, routerStore) {
        this._baseStore = baseStore;
        this._routerStore = routerStore;
        this._reactions = [];
        this._dateRanges = [];

        const now = new Date();
        const startOfYearNow = startOfYear(now);

        // last 30 days
        this._dateRanges.push({
            start: subDays(now, NUM_DAYS),
            end: now
        });
        // last 6 months
        this._dateRanges.push({
            start: subMonths(now, NUM_MONTHS),
            end: now
        });
        // current year
        this._dateRanges.push({
            start: startOfYearNow,
            end: now
        });
        // current year - 1
        this._dateRanges.push({
            start: subYears(startOfYearNow, 1),
            end: endOfYear(subYears(startOfYearNow, 1))
        });
        // current year - 2
        this._dateRanges.push({
            start: subYears(startOfYearNow, 2),
            end: endOfYear(subYears(startOfYearNow, 2))
        });
        // last two years
        this._dateRanges.push({
            start: subYears(now, NUM_YEARS),
            end: now
        });

        // load the order by ID when it becomes possible to do so
        this._reactions.push(reaction(
            () => {
                return {
                    customerId: _.get(this._baseStore.userSession, 'currentUser.id'),
                    locale: this._baseStore.locale,
                    orderId: this.detailedOrderId
                };
            },
            ({
                customerId, locale, orderId
            }) => {
                if (!customerId || !locale || !orderId) {
                    return;
                }

                OrdersService.getTrackingInfo(locale, orderId)
                    .then(action((orderStatusOptions) => {
                      this.orderStatus = orderStatusOptions;
                    }))
                    .catch(action(() => {
                        this.orderStatus =null;
                    }));

                OrdersService
                    .getOrders(undefined, locale, orderId)
                    .then(action((orders) => {
                        this.detailedOrder = orders[0];
                    }))
                    .catch(action(() => {
                        this.detailedOrder = null;
                    }));
            }, {
                fireImmediately: true
            }


        ));

        // load the orders when it becomes possible to do so
        this._reactions.push(reaction(
            () => {
                return {
                    customerId: _.get(this._baseStore.userSession, 'currentUser.id'),
                    locale: this._baseStore.locale,
                    searchKey: this.searchKey,
                    dateRange: this.dateRange
                };
            },
            ({
                customerId, locale, searchKey, dateRange
            }) => {
                if (!customerId || !locale || !dateRange) {
                    return;
                }

                this.orders = undefined;

                OrdersService
                    .getOrders(
                        undefined,
                        locale,
                        undefined,
                        searchKey === '' ? undefined : searchKey,
                        dateRange.start,
                        dateRange.end
                    ).then(action((orders) => {
                        this.orders = orders;
                    }))
                    .catch(action(() => {
                        this.orders = null;
                    }));
            }, {
                fireImmediately: true
            }
        ));

        // delete stale detailedOrder
        this._reactions.push(reaction(
            () => this.detailedOrderId,
            (orderId) => {
                if (orderId) {
                    this.detailedOrder = undefined;
                }
            }
        ));
    }

    destroy() {
        this._reactions.forEach((destroyReaction) => destroyReaction());
    }

    @computed
    get dateRange() {
        return this._dateRanges[this.dateRangeIndex];
    }

    get dateRanges() {
        return this._dateRanges;
    }

    get detailedOrderId() {
        return _.get(this._routerStore, 'route.params.orderId');
    }

    get orderStatus(){
         return this.orderStatus;
    }
}

