import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCProfileDealer from './pc-profile-dealer.component';
import PCContentModal from '../../shared/components/content-modal/content-modal.component';

@inject('baseStore')
@observer
class PCProfileDealersSwitchDialog extends React.Component {
    render() {
        const profileDealers = this.props.baseStore.profileDealers;
        let replaceErrorMessage;

        if (profileDealers.replaceError) {
            replaceErrorMessage = (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to update your profile dealer.'
                        id='pc-profile-dealers-switch-dialog.error'
                    />
                </div>
            );
        }

        return (
            <PCContentModal
                isOpen={true}
                onRequestClose={_.noop}
            >
                <div className='profile-dealers switch-dialog'>
                    <div className='alert alert-danger'>
                        <FormattedMessage
                            defaultMessage='Your selected dealer is different than your profile dealer.'
                            id='pc-profile-dealers-choose-dialog.different-title'
                        />
                        <p className='alert-description'>
                            <FormattedMessage
                                defaultMessage='In order to proceed you must either use your existing profile dealer or update your profile dealer to match your selected dealer.'
                                id='pc-profile-dealers-choose-dialog.different-message'
                            />
                        </p>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12 profile-dealer'>
                            <h4>
                                <FormattedMessage
                                    defaultMessage='Selected Dealer'
                                    id='pc-profile-dealers-choose-dialog.selected-dealer'
                                />
                            </h4>
                            <PCProfileDealer
                                profileDealer={profileDealers.nonProfileDealer}
                                buttonAction={profileDealers.replaceProfileDealer}
                                buttonText={(
                                    <FormattedMessage
                                        defaultMessage='Update Profile Dealer'
                                        id='pc-profile-dealers-choose-dialog.update-profile-dealer'
                                    />
                                )}
                            />
                        </div>
                        <div className='col-sm-12 profile-dealer'>
                            <h4>
                                <FormattedMessage
                                    defaultMessage='Profile Dealer'
                                    id='pc-profile-dealers-choose-dialog.profile-dealer'
                                />
                            </h4>
                            <PCProfileDealer
                                profileDealer={profileDealers.suggestedProfileDealer}
                                buttonAction={profileDealers.useProfileDealer}
                                buttonText={(
                                    <FormattedMessage
                                        defaultMessage='Use Profile Dealer'
                                        id='pc.use-profile-dealer'
                                    />
                                )}
                            />
                        </div>
                    </div>
                    {replaceErrorMessage}
                </div>
            </PCContentModal>
        );
    }
}

export default PCProfileDealersSwitchDialog;
