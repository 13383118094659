import React from 'react';
import {inject, observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import PCSavedCartsRow from './pc-saved-carts-row.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import PCLargeError from '../../shared/components/large-error/large-error.component';

@inject('savedStore')
@observer
export default class PCSavedCarts extends React.Component {
    render() {
        const {savedCarts} = this.props.savedStore;

        if (savedCarts === undefined) {
            return <PCPageLoading standalone={true}/>;
        }

        if (savedCarts === null) {
            return (
                <PCLargeError>
                    <FormattedMessage
                        id='pc.saved.cart-load-error'
                        defaultMessage='Unable to Load Carts Details'
                    />
                </PCLargeError>
            );
        }

        let table;

        if (savedCarts.length === 0) {
            table = (
                <div className='no-content-message'>
                    <FormattedMessage
                        id='pc-saved-carts.empty'
                        defaultMessage='You have not saved any carts.'
                    />
                </div>
            );
        } else {
            table = (
                <React.Fragment>
                    <div className='row hidden-sm hidden-xs hidden-md item-header'>
                        <div className='col-lg-10 carts-cell'>
                            <FormattedMessage
                                id='pc.cart-name'
                                defaultMessage='Cart Name'
                            />
                        </div>

                        <div className='col-lg-6 carts-cell'>
                            <FormattedMessage
                                id='pc.number-of-parts'
                                defaultMessage='Number of Parts'
                            />
                        </div>

                    </div>
                    {
                        savedCarts.map((cart) => {
                            return (
                                <PCSavedCartsRow
                                    key={cart.cartId}
                                    cart={cart}
                                />
                            );
                        })
                    }
                </React.Fragment>
            );
        }

        return (
            <div className='pc-saved-carts'>
                <h3 className='section-header'>
                    <FormattedMessage
                        id='pc.carts'
                        defaultMessage='Carts ({quantity})'
                        values={{
                            quantity: savedCarts.length
                        }}
                    />
                </h3>
                {table}
            </div>
        );
    }
}

