import _ from 'lodash';

import {action, observable, reaction, when} from 'mobx';

class PCDealerSelectorStore {
    @observable _initialized = false;

    constructor(baseStore, routerStore) {
        this.changeDealer = this.changeDealer.bind(this);
        this.setDialogMessageCode = this.setDialogMessageCode.bind(this);
        this.attach = this.attach.bind(this);
        this.detach = this.detach.bind(this);

        // read the dealer location ID off the route, if it is available
        let initialDealerId = _.get(routerStore.router.getState(), 'params.dealer-id');
        const currentUserId = _.get(baseStore.userSession.currentUser, 'id');

        if (currentUserId) {
            // We will be getting the dealer details via the profile dealers call and
            // sending them to the dealer selector, no need to have the dealer selector
            // also get the selected dealer.
            initialDealerId = undefined;
        }

        // create the host element for the dealer selector
        this._selectorElement = document.createElement('div');
        this._selectorElement.addEventListener(
            'dealerSelected',
            action((event) => {
                baseStore.dealer = event.detail;

                // write the new dealer ID onto the route
                const params = _.clone(routerStore.route.params) || {};

                params['dealer-id'] = event.detail.dealerId;
                routerStore.router.navigate(
                    routerStore.route.name,
                    params,
                    {
                        replace: true
                    }
                );
            })
        );
        this._selectorElement.addEventListener(
            'dealerSelectorDialogOpen',
            action((event) => {
                baseStore.dealerSelectorOpen = event.detail;
            })
        );

        // start up the dealer selector when ready
        when(
            () => baseStore.locale,
            () => {
                document.addEventListener('dealerSelectorReady', () => {
                    dealerSelectorUi.init(
                        this._selectorElement,
                        'header',
                        baseStore.locale,
                        {
                            dealerId: initialDealerId
                        },
                        action(() => {
                            this._initialized = true;
                            dealerSelectorUi.useProfileDealers(currentUserId !== undefined);
                        }),
                        false
                    );
                });
            }
        );

        // attach the dealer selector script
        const headElement = document.getElementsByTagName('head')[0];
        const scriptElement = document.createElement('script');

        scriptElement.src = DEALER_SELECTOR_UI_URL;
        headElement.appendChild(scriptElement);

        // set the profile dealers when they become available
        reaction(
            () => {
                return {
                    initialized: this._initialized,
                    cleanProfileDealers: _.get(baseStore.profileDealers, 'cleanProfileDealers')
                };
            },
            (data) => {
                if (!data.initialized || !data.cleanProfileDealers) {
                    return;
                }

                dealerSelectorUi.setProfileDealers(data.cleanProfileDealers);
            }
        );
    }

    attach(element) {
        element.appendChild(this._selectorElement);
    }

    detach() {
        if (!this._selectorElement.parentNode) {
            return;
        }

        this._selectorElement.parentNode.removeChild(this._selectorElement);
    }

    changeDealer() {
        dealerSelectorUi.changeDealer();
    }

    setDialogMessageCode(messageCode) {
        dealerSelectorUi.setDialogMessageCode(messageCode);
    }

    setProfileDealers(profileDealers) {
        dealerSelectorUi.setProfileDealers(profileDealers);
    }

    get initialized() {
        return this._initialized;
    }
}

export default PCDealerSelectorStore;
