import _ from 'lodash';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import PCContentModal from '../../shared/components/content-modal/content-modal.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import PCDealerPoliciesStore from './pc-dealer-policy.store';

const START_HEADER = 3;

@inject('baseStore')
@observer
class PCDealerPolicies extends React.Component {
    constructor(props) {
        super(props);

        this.mapSections = this.mapSections.bind(this);
        this.mapParagraphs = this.mapParagraphs.bind(this);
        this.mapParagraphElements = this.mapParagraphElements.bind(this);
    }

    locationId;

    componentWillUpdate(){
         if(this.props.baseStore.dealer.locationId!==this.locationId){
             this.locationId=this.props.baseStore.dealer.locationId;
            this._dealerPoliciesStore = new PCDealerPoliciesStore(
                this.props.baseStore
            );
         }
    }

    componentWillUnmount() {
        this._dealerPoliciesStore.destroy();
        this.locationId=null;
    }


    mapSections(sections, level) {
        if (!sections) {
            return null;
        }
        let dealerPoliciesByDealer = this._dealerPoliciesStore.dealerPolicies;
        return _.map(sections, (section, index) => {
            if (section.countryCodes &&
                _.indexOf(section.countryCodes, this.props.baseStore.countryCode) === -1) {
                // this section is not valid for the active country
                return null;
            }

            const HeaderTag = `h${level}`;
            //this section will check for dealer policy set by dealer else by default policy will be displayed
            let policyText;
            if (dealerPoliciesByDealer && level === 3) {
                if (index === 0) {
                    policyText = dealerPoliciesByDealer.paymentAndFinancePolicyText;
                } else if (index === 1) {
                    policyText = dealerPoliciesByDealer.shippingAndFreightPolicyText;
                } else if (index === 2) {
                    policyText = dealerPoliciesByDealer.warrantyAndReturnPolicyText;
                } else if (index === 3) {
                    policyText = dealerPoliciesByDealer.orderPackageQuantityPolicyText;
                }
            }
            return (
                <React.Fragment key={index}>
                    {_.isEmpty(policyText) ?
                        <div>
                            <HeaderTag>
                                {section.title}
                            </HeaderTag>
                            {this.mapParagraphs(section.paragraphs)}
                            {this.mapSections(section.sections, level + 1)}
                        </div>
                        :
                        <div>
                            <HeaderTag>
                                {section.title}
                            </HeaderTag>
                            {policyText}
                        </div>}
                </React.Fragment>
            );
        });
    }

    mapParagraphs(paragraphs) {
        if (!paragraphs) {
            return null;
        }

        return _.map(paragraphs, (paragraph, index) => {
            return (
                <p key={index}>
                    {this.mapParagraphElements(paragraph)}
                </p>
            );
        });
    }

    mapParagraphElements(paragraphElements) {
        if (_.isString(paragraphElements)) {
            return paragraphElements;
        } else if (paragraphElements.href && paragraphElements.text) {
            return (
                <a
                    href={paragraphElements.href}
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    {paragraphElements.text}
                </a>
            );
        }

        return _.map(paragraphElements, (paragraphElement, index) => {
            return (
                <span key={index}>
                    {' '}
                    {this.mapParagraphElements(paragraphElement)}
                    {' '}
                </span>
            );
        });
    }

    render() {
        const dealerPolicies = this.props.baseStore.dealerPolicies;

        if (!dealerPolicies.open) {
            return null;
        }

        let modalBody;

        if (dealerPolicies.data) {
            modalBody = (
                <React.Fragment>
                    {this.mapSections(dealerPolicies.data, START_HEADER)}
                </React.Fragment>
            );
        } else if (dealerPolicies.data === null) {
            modalBody = (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to load the dealer policies.'
                        id='pc-dealer-policies.error'
                    />
                </div>
            );
        } else {
            modalBody = (
                <PCPageLoading />
            );
        }

        return (
            <PCContentModal
                isOpen={true}
                onRequestClose={dealerPolicies.hide}
                title={(
                    <FormattedMessage
                        defaultMessage='Dealer Policies'
                        id='pc.dealer-policies'
                    />
                )}
            >
                <div className='pc-dealer-policies'>
                    {modalBody}
                </div>
            </PCContentModal>
        );
    }
}

export default PCDealerPolicies;
