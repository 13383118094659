import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from './../fetch-json.util';

class ShippingChargeService {
    static getShippingCharge(cartId, shippingMethodId,locale) {
        return fetchJsonWithAuth(
            buildUrl(SHIPPING_CHARGE_URL, {
                queryParams: {
                    'cart-id': cartId,
                    'shipping-method-id': shippingMethodId,
                    'locale-cd': locale
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default ShippingChargeService;
