import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

@inject('checkoutStore')
@observer
class PCCheckoutSummaryShipping extends React.Component {
    render() {
        // must have a shipping method filled in
        const shippingMethod = this.props.checkoutStore.shippingMethod;

        if (!shippingMethod) {
            return null;
        }

        // if we are collecting shipping then the shipping info step must be visited and filled
        if (shippingMethod.collectShipping &&
            (!this.props.checkoutStore.shippingInfoVisited ||
            !this.props.checkoutStore.shippingInfoComplete)) {
            return null;
        }
        // if we are not collecting shipping then the shipping method step must be visited
        if (!shippingMethod.collectShipping &&
            !this.props.checkoutStore.shippingMethodVisited) {
            return null;
        }

        let body;

        if (!shippingMethod.collectShipping) {
            // when not collecting shipping show just the shipping method itself
            body = (
                <React.Fragment>
                    {shippingMethod.shippingMethodText}
                </React.Fragment>
            );
        } else {
            // when collecting shipping show the shipping info
            const groupedByRow = _.groupBy(this.props.checkoutStore.addressElements, 'rowIndex');

            body = _.chain(groupedByRow)
                .keys()
                .map((rowIndex) => parseInt(rowIndex, 10))
                .sortBy()
                .map((rowIndex) => {
                    const columnElements = _.sortBy(groupedByRow[rowIndex], 'columnIndex');
                    const columns = _.chain(columnElements)
                        .map((addressElement) => {
                            return this.props.checkoutStore.shippingInfo[addressElement.addressElementId];
                        })
                        .filter()
                        .join(' ')
                        .value();

                    return (
                        <div key={rowIndex}>
                            {columns}
                        </div>
                    );
                })
                .flatten()
                .value();
        }

        return (
            <div className='checkout-summary-shipping shaded-block info-block'>
                <h5>
                    <FormattedMessage
                        defaultMessage='Ship To'
                        id='pc.ship-to'
                    />
                </h5>
                <div>
                    {body}
                </div>
            </div>
        );
    }
}

export default PCCheckoutSummaryShipping;
