import {inject, observer, Provider} from 'mobx-react';
import React, {Component} from 'react';

import PCOrderDetails from './pc-order-details/pc-order-details.component'; // eslint-disable-line
import PCOrdersHistory from './pc-order-history/pc-order-history.component';
import PCOrdersStore from './pc-orders-store';

@inject('baseStore', 'routerStore')
@observer
class PCOrders extends Component {
    constructor(props) {
        super(props);

        this._ordersStore = new PCOrdersStore(props.baseStore, props.routerStore);
    }

    componentWillUnmount() {
        this._ordersStore.destroy();
    }

    render() {
        return (
            <Provider ordersStore={this._ordersStore}>
                {
                    this._ordersStore.detailedOrderId ? (<PCOrderDetails/>) : (<PCOrdersHistory/>)
                }
            </Provider>);
    }
}
export default PCOrders;
