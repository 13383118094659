import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {observer, inject, PropTypes as MobxPropTypes} from 'mobx-react';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';
import PCQuantitySpinner from '../../shared/components/quantity-spinner/quantity-spinner.component';

import './pc-saved-cart-details-line-item.component.scss';

@inject('savedStore', 'baseStore')
@observer
export default class PCSavedCartDetailsLineItem extends React.Component {
    handleRemoveItemFromSavedCart = () => {
        this.props.savedStore.removeCartItem(
            this.props.savedStore.detailedCart,
            this.props.lineItem
        ).catch(() => {
            this.props.baseStore.showFailAlert(
                <FormattedMessage
                    id='pc.cart-update-failed'
                    defaultMessage='Unable to update the cart.'
                />
            );
        });
    };

    handleUpdateItemQuantity = (newQuantity) => {
        this.props.savedStore.updateCartLineItemQuantity(
            this.props.savedStore.detailedCart,
            this.props.lineItem,
            newQuantity
        ).catch(() => {
            this.props.baseStore.showFailAlert(
                <FormattedMessage
                    id='pc.cart-update-failed'
                    defaultMessage='Unable to update the cart.'
                />
            );
        });
    };

    handleAddItemToCart = () => {
        this.props.baseStore.currentCart.addPartByIdToCart(
            this.props.lineItem.partNumber,
            this.props.lineItem.quantityOrdered,
            'Saved Cart'
        );
    };

    render() {
        const {priceUnit: currency} = this.props.savedStore.detailedCart;

        const addRemoveButtons = (
            <React.Fragment>
                <button
                    className='delete-button'
                    onClick={this.handleRemoveItemFromSavedCart}
                />
                <button
                    className='btn btn-primary'
                    onClick={this.handleAddItemToCart}
                >
                    <FormattedMessage
                        id='pc.add-to-cart'
                        defaultMessage='Add to Cart'
                    />
                </button>
            </React.Fragment>
        );

        return (
            <div className='row saved-cart-details-line-item'>
                <div className='col-md-10 item-cell description-add-remove'>
                    <div className='visible-xs visible-sm pull-right price-small-block'>
                        <div className='price-container'>
                            <div className='title-aside'>
                                <FormattedMessage
                                    defaultMessage='Unit Price'
                                    id='pc.unit-price'
                                />
                            </div>
                            <PCPriceDisplay
                                currency={currency}
                                value={this.props.lineItem.unitPrice}
                                inline={true}
                            />
                        </div>

                        <div className='price-container total'>
                            <div className='title-aside'>
                                <FormattedMessage
                                    defaultMessage='Total Price'
                                    id='pc.total-price'
                                />
                            </div>
                            <PCPriceDisplay
                                currency={currency}
                                value={this.props.lineItem.unitPrice * this.props.lineItem.quantityOrdered}
                                inline={true}
                            />
                        </div>
                    </div>

                    <h4>
                        {this.props.lineItem.partDescription || this.props.lineItem.partNumber}
                    </h4>
                    <div>
                        <FormattedMessage
                            defaultMessage='Part Number'
                            id='pc.part-number'
                        />
                        {' '}
                        <span>{this.props.lineItem.partNumber}</span>
                    </div>

                    <div className='hidden-xs hidden-sm part-add-remove-inline'>
                        {addRemoveButtons}
                    </div>
                </div>
                <div className='col-md-6 quantity item-cell'>
                    <PCQuantitySpinner
                        onChange={this.handleUpdateItemQuantity}
                        quantity={this.props.lineItem.quantityOrdered}
                    />
                </div>
                <div className='col-md-4 hidden-xs hidden-sm item-cell text-right'>
                    <PCPriceDisplay
                        currency={currency}
                        value={this.props.lineItem.unitPrice}
                    />
                </div>
                <div className='col-md-4 hidden-xs hidden-sm item-cell text-right'>
                    <PCPriceDisplay
                        currency={currency}
                        value={this.props.lineItem.unitPrice * this.props.lineItem.quantityOrdered}
                    />
                </div>
                <div className='col-xs-24 hidden-md hidden-lg part-add-cell'>
                    {addRemoveButtons}
                </div>
            </div>
        );
    }
}

PCSavedCartDetailsLineItem.propTypes = {
    lineItem: MobxPropTypes.observableObject,
    index: PropTypes.number.isRequired
};
