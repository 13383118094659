import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithTempToken, makeAppIdConfig} from './../fetch-json.util';

class FooterInfoService {
    static getFooterInfo(countryCd, localeCd) {
        return fetchJsonWithTempToken(
            buildUrl(FOOTER_INFO_URL, {
                queryParams: {
                    'iso2-country-code': countryCd,
                    'locale-cd': localeCd
                }
            }),
            makeAppIdConfig()
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default FooterInfoService;
