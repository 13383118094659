
export default [
    {
        name: 'saved',
        path: '/saved',
        registeredUserRequired: true,
        dealerSelectorVisible: true,
        cartVisible: true,
        newPartSearchVisible: true,
        secureCheckoutVisible: false,
        children: [
            {
                name: 'cart-details',
                path: '/carts/:savedCartId'
            }
        ]
    }
];
