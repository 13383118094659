import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCCheckoutAddressForm from '../../pc-checkout-address-form/pc-checkout-address-form.component';
import PCPageLoading from '../../../shared/components/page-loading/page-loading.component';

@inject('checkoutStore')
@observer
class PCCheckoutStepShippingInfo extends React.Component {
    render() {
        const {
            addressElements,
            shippingInfo
        } = this.props.checkoutStore;

        if (addressElements === undefined) {
            return (
                <PCPageLoading/>
            );
        }

        if (addressElements === null) {
            return (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to load the shipping information.'
                        id='pc-checkout-shipping-info.error'
                    />
                </div>
            );
        }

        return (
            <div className='checkout-step-shipping-info container-fluid'>
                <div className='row limit-width'>
                    <PCCheckoutAddressForm
                        address={shippingInfo}
                        addressElements={addressElements}
                    />
                </div>

                <div className='row limit-width'>
                    <button
                        className='btn btn-primary btn-continue'
                        disabled={!this.props.checkoutStore.shippingInfoComplete}
                        onClick={this.props.checkoutStore.activateNextStep}
                    >
                        <FormattedMessage
                            defaultMessage='Continue'
                            id='pc.continue'
                        />
                    </button>
                </div>
            </div>
        );
    }
}

export default PCCheckoutStepShippingInfo;
