import React from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';

import PCPriceDisplay from '../../shared/components/price-display/price-display.component';

@inject('checkoutStore')
@observer
class PCCheckoutEstimatedShippingPriceDisplay extends React.Component {
    render() {
        if (this.props.checkoutStore.estimatedShippingUpdateInProgress) {
            return (<span className='price-display'>{'--'}</span>);
        }

        return (
            <PCPriceDisplay
                currency={this.props.checkoutStore.currencyCode}
                inline={true}
                value={this.props.value}
            />
        );
    }
}

PCCheckoutEstimatedShippingPriceDisplay.propTypes = {
    value: PropTypes.number
};

export default PCCheckoutEstimatedShippingPriceDisplay;
