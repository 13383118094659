import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCCheckoutBillingForm from './pc-checkout-billing-form.component';
import PCCheckoutAddressForm from '../../pc-checkout-address-form/pc-checkout-address-form.component';
import PCPageLoading from '../../../shared/components/page-loading/page-loading.component';

import './pc-checkout-step-payment-info.component.scss';

@inject('checkoutStore')
@observer
class PCCheckoutStepPaymentInfo extends React.Component {
    constructor(props) {
        super(props);

        this.onContinue = this.onContinue.bind(this);
        this.onSyncChange = this.onSyncChange.bind(this);
    }

    onContinue(event) {
        event.currentTarget.blur();
        this.props.checkoutStore.generatePaymentTokenAndContinue();
    }

    onSyncChange(event) {
        this.props.checkoutStore.syncShippingAndBilling = event.target.checked;
    }

    render() {
        const {
            addressElements,
            merchantInfo,
            merchantInfoLoaded,
            shippingInfo,
            billingInfo,
            paymentMethod,
            paymentTokenError,
            syncShippingAndBilling,
            microform: {
                state: microformState,
                states: microformStates
            }
        } = this.props.checkoutStore;

        if (paymentMethod.collectPayment && microformState === microformStates.LOADING ||
            addressElements === undefined ||
            !merchantInfoLoaded) {
            return (
                <PCPageLoading/>
            );
        }

        if (addressElements === null ||
            merchantInfo === null ||
            microformState === microformStates.ERROR) {
            return (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to load the payment information.'
                        id='pc-checkout-payment-info.error'
                    />
                </div>
            );
        }

        // We need the payment form to be rendered into the DOM so that the microform can be attached
        // but we need to wait until the attach is complete before showing the form.
        // While the microform is loading the form will be hidden via style instead of if statement.
        let formDisplay = 'block';
        let microformSpinner;

        if (paymentMethod.collectPayment &&
            microformState !== microformStates.ATTACHED) {
            formDisplay = 'none';
            microformSpinner = (
                <PCPageLoading/>
            );
        }

        // show an error message if the payment token could not be generated
        let paymentTokenErrorMessage;

        if (paymentTokenError) {
            paymentTokenErrorMessage = (
                <div className='row limit-width'>
                    <div className='alert alert-warning'>
                        <FormattedMessage
                            defaultMessage='Unable to process your card.'
                            id='pc-checkout-billing-form.error'
                        />
                        <p className='alert-description'>
                            <FormattedMessage
                                defaultMessage='Please check your card details and try again.'
                                id='pc-checkout-billing-form.error-retry'
                            />
                        </p>
                    </div>
                </div>
            );
        }

        // only show the sync checkbox if shipping info was collected
        let syncShippingAndBillingCheckbox;

        if (shippingInfo) {
            syncShippingAndBillingCheckbox = (
                <div className='checkbox sync-shipping-and-billing'>
                    <label>
                        <input
                            type='checkbox'
                            checked={syncShippingAndBilling}
                            onChange={this.onSyncChange}
                        />
                        <FormattedMessage
                            defaultMessage='Same as Shipping Address'
                            id='pc-checkout-payment-info.same-as-shipping'
                        />
                    </label>
                </div>
            );
        }

        let billingForm;

        if (paymentMethod.collectPayment) {
            billingForm = (
                <div className='row limit-width billing-payment'>
                    <PCCheckoutBillingForm/>
                </div>
            );
        }

        return (
            <React.Fragment>
                {microformSpinner}
                <div
                    className='checkout-step-payment-info container-fluid'
                    style={{
                        display: formDisplay
                    }}
                >
                    {billingForm}

                    <div className='row limit-width billing-address'>
                        <h5>
                            <FormattedMessage
                                defaultMessage='Billing Address'
                                id='pc-checkout-payment-info.billing-address'
                            />
                        </h5>

                        {syncShippingAndBillingCheckbox}
                        <PCCheckoutAddressForm
                            address={billingInfo}
                            addressElements={addressElements}
                            disabled={shippingInfo && syncShippingAndBilling}
                        />
                    </div>

                    {paymentTokenErrorMessage}

                    <div className='row limit-width'>
                        <button
                            className='btn btn-primary btn-continue'
                            disabled={!this.props.checkoutStore.paymentInfoTransactionReady}
                            onClick={this.onContinue}
                        >
                            <FormattedMessage
                                defaultMessage='Continue'
                                id='pc.continue'
                            />
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PCCheckoutStepPaymentInfo;
