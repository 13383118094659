import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

const MASKED_PAN_SLICE = -4;

@inject('checkoutStore')
@observer
class PCCheckoutSummaryPayment extends React.Component {
    render() {
        // must have a payment method filled in
        const paymentMethod = this.props.checkoutStore.paymentMethod;

        if (!paymentMethod) {
            return null;
        }

        // if we are collecting payment then the payment info step must be visited and filled
        if (paymentMethod.collectPayment &&
            (!this.props.checkoutStore.paymentInfoVisited ||
            !this.props.checkoutStore.paymentInfoComplete)) {
            return null;
        }
        // if we are not collecting payment then the payment method step must be visited
        if (!paymentMethod.collectPayment &&
            !this.props.checkoutStore.paymentMethodVisited) {
            return null;
        }

        let body;

        if (!paymentMethod.collectPayment) {
            // when not collecting payment show just the payment method itself
            body = (
                <React.Fragment>
                    {paymentMethod.paymentMethodText}
                </React.Fragment>
            );
        } else {
            // when collecting payment show the card type and masked pan
            const selectedCardType = _.find(
                this.props.checkoutStore.merchantInfo.cardType,
                {
                    value: this.props.checkoutStore.transactionInfo.cardType
                }
            );

            body = (
                <FormattedMessage
                    defaultMessage='{cardType} ending in {lastFour}'
                    id='pc-checkout-summary-payment.payment-method'
                    values={{
                        cardType: selectedCardType.displayValue,
                        lastFour: this.props.checkoutStore.transactionInfo.paymentToken.maskedPan.slice(MASKED_PAN_SLICE)
                    }}
                />
            );
        }

        return (
            <div className='checkout-summary-payment shaded-block info-block'>
                <h5>
                    <FormattedMessage
                        defaultMessage='Payment'
                        id='pc.payment'
                    />
                </h5>
                <div>
                    {body}
                </div>
            </div>
        );
    }
}

export default PCCheckoutSummaryPayment;
