import React from 'react';
import {FormattedMessage} from 'react-intl';
import './pc-order-history-header.component.scss';

const PCOrderHistoryHeader = () => {
    return (
        <div className='row hidden-sm hidden-xs pc-order-history-header'>

            <div className='col-md-4'>
                <FormattedMessage
                    defaultMessage='Quote Number'
                    id='pc.quote-number'
                />
            </div>

            <div className='col-md-4'>
                <FormattedMessage
                    defaultMessage='Date/Time of Quote'
                    id='pc-orders.table.date-placed'
                />
            </div>

            <div className='col-md-4'>
                <FormattedMessage
                    defaultMessage='Number of Parts'
                    id='pc.number-of-parts'
                />
            </div>

            <div className='col-md-4'>
                <FormattedMessage
                    defaultMessage='Total'
                    id='pc.total'
                />
            </div>
            <div className='col-md-4'>
                <FormattedMessage
                    defaultMessage='Order Status'
                    id='pc.order-status'
                />
            </div>
            <div className='col-md-4'>
                
            </div>
        </div>
    );
};

export default PCOrderHistoryHeader;
