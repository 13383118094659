import PropTypes from 'prop-types';
import React from 'react';
import {inject} from 'mobx-react';
import {FormattedDate, FormattedTime, FormattedMessage} from 'react-intl';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';
import PCRouteLink from '../../shared/components/route-link/route-link.component';
import './pc-order-history-item.component.scss';

@inject('baseStore')
class PCOrderRow extends React.Component {
    constructor(props) {
        super(props);

        this.onAddOrderToCart = this.onAddOrderToCart.bind(this);
    }

    onAddOrderToCart() {
        this.props.baseStore.currentCart.addOrderByIdToCart(this.props.order.orderId);
    }

    render() {
        const order = this.props.order;

        return (
            <div className='row pc-order-history-item'>

                <div className='col-lg-4 col-xs-12'>
                    <div className='visible-sm visible-xs label-small'>
                        <FormattedMessage
                            defaultMessage='Quote Number'
                            id='pc.quote-number'
                        />
                    </div>
                    <PCRouteLink
                        params={{
                            orderId: order.orderId
                        }}
                        route={'orders.order-details'}
                    >
                        {order.orderId}
                    </PCRouteLink>
                </div>

                <div className='col-lg-4 col-xs-12 '>
                    <div className='visible-sm visible-xs label-small'>
                        <FormattedMessage
                            defaultMessage='Date/Time Of Quote'
                            id='pc-orders.table.date-placed'
                        />
                    </div>
                    <div className='time-part'>
                        <FormattedDate value={order.datePlaced}/>
                    </div>
                    {' '}
                    <div className='time-part'>
                        <FormattedTime value={order.datePlaced}/>
                    </div>
                </div>

                <div className='col-lg-4 col-xs-10'>
                    <div className='visible-sm visible-xs label-small'>
                        <FormattedMessage
                            defaultMessage='Number of Parts'
                            id='pc.number-of-parts'
                        />
                    </div>
                    <div>
                        {order.lineCount}
                    </div>
                </div>

                <div className='col-lg-4 col-xs-17'>
                    <div className='visible-sm visible-xs label-small'>
                        <FormattedMessage
                            defaultMessage='Total'
                            id='pc.total'
                        />
                    </div>
                    <span className='hidden-xs hidden-sm'>
                        <PCPriceDisplay
                            currency={order.priceUnit}
                            inline={false}
                            value={order.totalOrderValue}
                        />
                    </span>
                    <span className='visible-xs visible-sm'>
                        <PCPriceDisplay
                            currency={order.priceUnit}
                            inline={true}
                            value={order.totalOrderValue}
                        />
                    </span>
                </div>
                <div className='col-lg-4 col-xs-8'>
                    
                    <div>
                        {order.orderStatus!==undefined ? order.orderStatus :
                        <FormattedMessage
                        defaultMessage='Not Available'
                        id='pc.NA'
                    />
                        }
                    </div>
                </div>

                <div className='col-lg-4 col-xs-24 button-cell'>
                    <button
                        className='btn btn-hollow'
                        onClick={this.onAddOrderToCart}
                    >
                        <FormattedMessage
                            defaultMessage='Add Parts to Cart'
                            id='pc.add-parts-to-cart'
                        />
                    </button>
                </div>
            </div>
        );
    }
}

PCOrderRow.propTypes = {
    order: PropTypes.shape({
        orderId: PropTypes.number.isRequired
    }).isRequired
};

export default PCOrderRow;
