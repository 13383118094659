import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';

import PCContentModal from '../../../shared/components/content-modal/content-modal.component';
import RouteLink from '../../../shared/components/route-link/route-link.component';

@inject('baseStore', 'checkoutStore')
@observer
class PCCheckoutSaveOrderLine extends React.Component {
    constructor(props) {
        super(props);
    }

    @observable _saveResponse = false;

    @action.bound
    onSaveToWishlist() {
        const overwrite = this._saveResponse !== false;

        this._saveResponse = false;

        this.props.checkoutStore.saveOrderLine(
            this.props.orderLine,
            overwrite
        ).then(action(
            (response) => {
                if (_.isObject(response)) {
                    if (response.exists) {
                        this.props.baseStore.showSuccessAlert(
                            <FormattedMessage
                                defaultMessage='The part has already been saved.'
                                id='pc-checkout-save-order-line.exists'
                            />
                        );
                        return;
                    }

                    this._saveResponse = response;
                    return;
                }

                this.props.baseStore.showSuccessAlert(
                    <FormattedMessage
                        defaultMessage='The part has been saved successfully.'
                        id='pc-checkout-save-order-line.save-success'
                    />
                );
            }
        )).catch(() => {
            this.props.baseStore.showFailAlert(
                <FormattedMessage
                    defaultMessage='Unable to save the part.'
                    id='pc-checkout-save-order-line.save-part-error'
                />
            );
        });
    }

    @action.bound
    onCancel() {
        this._saveResponse = false;
    }

    render() {
        let saveModal;

        // Most of the time the save button will either never be clicked
        // or the save will be successful.
        // Rendering the modal in an if block instead of using isOpen to
        // prevent the modal from being run unless it will be used.
        if (this._saveResponse !== false) {
            saveModal = (
                <PCContentModal
                    isOpen={true}
                    onRequestClose={this.onCancel}
                    title={(
                        <FormattedMessage
                            defaultMessage='Save Part'
                            id='pc-checkout-save-order-line.save-part'
                        />
                    )}
                    buttons={(
                        <React.Fragment>
                            <button
                                className='btn btn-hollow'
                                onClick={this.onCancel}
                            >
                                <FormattedMessage
                                    defaultMessage='Cancel'
                                    id='pc.cancel'
                                />
                            </button>
                            <button
                                className='btn btn-primary'
                                onClick={this.onSaveToWishlist}
                            >
                                <FormattedMessage
                                    defaultMessage='Save'
                                    id='pc.save'
                                />
                            </button>
                        </React.Fragment>
                    )}
                >
                    <div className='checkout-save-order-line'>
                        <div className='alert alert-danger'>
                            <FormattedMessage
                                defaultMessage='You have reached the limit of {maxWishlistItem} saved parts.  Saving this part will erase your oldest saved part.'
                                id='pc-checkout-save-order-line.too-many-error'
                                values={{
                                    maxWishlistItem: this._saveResponse && this._saveResponse.maxWishlistItem
                                }}
                            />
                        </div>
                        <div className='dialog-message-link-block'>
                            <FormattedMessage
                                defaultMessage='If you would like to choose which part is deleted, please visit your {linkListSavedParts}.'
                                id='pc-checkout-save-order-line.too-many-choose'
                                values={{
                                    linkListSavedParts: (
                                        <RouteLink
                                            route='saved'
                                            onClick={this.onCancel}
                                        >
                                            <FormattedMessage
                                                defaultMessage='list of saved parts'
                                                id='pc-checkout-save-order-line.too-many-link-list-saved-parts'
                                            />
                                        </RouteLink>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </PCContentModal>
            );
        }

        return (
            <React.Fragment>
                <button
                    className='btn btn-hollow'
                    onClick={this.onSaveToWishlist}
                >
                    <FormattedMessage
                        defaultMessage='Save for Later'
                        id='pc-checkout-save-order-line.save-for-later'
                    />
                </button>
                {saveModal}
            </React.Fragment>
        );
    }
}

PCCheckoutSaveOrderLine.propTypes = {
    orderLine: PropTypes.shape({
        partNumber: PropTypes.string.isRequired,
        quantity: PropTypes.number.isRequired
    }).isRequired
};

export default PCCheckoutSaveOrderLine;
