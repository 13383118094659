import {action} from 'mobx';
import jwtDecode from 'jwt-decode';

let authorizationToken;
let tempAuthorizationToken;
let logoutFunction;
const HTTP_UNAUTHORIZED = 401;

/**
 * Make the configuration required for requests that send the bearer token authorization.
 * @param {Object} [init] Add the configuration to this object.
 * @returns {Object} The fetch configuration object with authorization headers populated.
 */
function makeBearerConfig(init = {}) {
    init.credentials = 'include';
    init.headers = init.headers || {};
    if(authorizationToken!==undefined && authorizationToken!==null){
        init.headers.Authorization = `Bearer ${authorizationToken}`;
    }
    else if(tempAuthorizationToken!==undefined && tempAuthorizationToken!==null){
        init.headers.Authorization = `Bearer ${tempAuthorizationToken}`;
    }else{
        tempAuthorizationToken = window.sessionStorage.getItem('tempAuthorizationToken');
        init.headers.Authorization = `Bearer ${tempAuthorizationToken}`;
    }
    return init;
}

/**
 * Only used by pc-user-session.class
 * @param {String} token The bearer authorization token.
 * @returns {undefined}
 */
export function setAuthorizationToken(token) {
    authorizationToken = token;
}

export function setTempAuthorizationToken(token) {
    tempAuthorizationToken = token;
    window.sessionStorage.setItem('tempAuthorizationToken',token);
}

/**
 * Only used by pc-user-session.class
 * @param {Function} logout The function to force a logout.
 * @returns {undefined}
 */
export function setLogoutFunction(logout) {
    logoutFunction = logout;
}

/**
 * Only used by pc-user-session.class and the services.
 * @returns {String} The bearer authorization token.
 */
export function getAuthorizationToken() {
    return authorizationToken;
}

/**
 * Calls fetch and converts the response to JSON.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} resolved with the JSON for a 200, rejected otherwise
 */
export function fetchJson(input, init) {
    return fetch(input, init).then(
        (response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject();
        }
    );
}

/**
 * Calls fetch with the bearer token authorization.
 * Logs the user out if the server sends a 401.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} See fetch API for details.  If a 401 is sent the promise will be rejected.
 */
export function fetchWithAuth(input, init) {
    const initWithBearer = makeBearerConfig(init);

    return fetch(input, initWithBearer).then(
        (response) => {
            if (response.status === HTTP_UNAUTHORIZED) {
                if (logoutFunction) {
                    logoutFunction();
                }
                return Promise.reject();
            }

            return response;
        }
    );
}

/**
 * Calls fetchWithAuth and converts the response to JSON.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} resolved with the JSON for a 200, rejected otherwise
 */
export function fetchJsonWithAuth(input, init) {
    return fetchWithAuth(input, init).then(
        (response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject();
        }
    );
}

/**
 * Make the configuration required for requests that send the app ID authorization.
 * @param {Object} [init] Add the configuration to this object.
 * @returns {Object} The fetch configuration object with authorization headers populated.
 */
export function makeAppIdConfig(init = {}) {
    init.credentials = 'include';
    init.headers = init.headers || {};
    if (tempAuthorizationToken !== undefined && tempAuthorizationToken !== null) {
        init.headers.Authorization = `Bearer ${tempAuthorizationToken}`;
    } else {
        tempAuthorizationToken = window.sessionStorage.getItem('tempAuthorizationToken');
        init.headers.Authorization = `Bearer ${tempAuthorizationToken}`;
    }

    return init;
}

export function generateTempAuthToken() {
    return fetch(AUTH_TEMP_TOKEN_URL).then((response) => {
        if (response.ok) {
            return response.text();
        }
        return Promise.reject();
    }).catch(action((response) => {
        console.log('Access token request failed:', response);
    }));
}

/**
 * Validate temp authorization token expiry then make network call.
 * @param {Object} [init] Add the configuration to this object.
 * @returns {Object} The fetch configuration object with authorization headers populated.
 */
export function fetchJsonWithTempToken(input, init) {
    const tempToken = window.sessionStorage.getItem('tempAuthorizationToken');
    const decodedIdToken = jwtDecode(tempToken);
    if (Date.now() <= decodedIdToken.exp * 1000) {
        return fetchJson(input, init);
    } else {
        return generateTempAuthToken().then(
            action((token) => {
                setTempAuthorizationToken(token);
                return fetchJson(input, init);
            })
        );
    }
}

/**
 * Calls fetch and return the text response.
 * @param {*} input See fetch API for details.
 * @param  {*} init See fetch API for details.
 * @returns {Promise} resolved with the JSON for a 200, rejected otherwise
 */
export function fetchText(input, init) {
    return fetch(input, init).then(
        (response) => {
            if (response.ok) {
                return response.text();
            }
            return Promise.reject();
        }
    );
}

/**
 * Validate temp authorization token expiry then make network call.
 * @param {Object} [init] Add the configuration to this object.
 * @returns {Object} The fetch configuration object with authorization headers populated.
 */
export function fetchTextWithTempToken(input, init) {
    const tempToken = window.sessionStorage.getItem('tempAuthorizationToken');
    const decodedIdToken = jwtDecode(tempToken);
    if (Date.now() <= decodedIdToken.exp * 1000) {
        return fetchText(input, init);
    } else {
        return generateTempAuthToken().then(
            action((token) => {
                setTempAuthorizationToken(token);
                return fetchText(input, init);
            })
        );
    }
}
