import React from 'react';
import {FormattedMessage} from 'react-intl';
import PCRouteLink from '../../shared/components/route-link/route-link.component';
import {PropTypes as MobxPropTypes, inject} from 'mobx-react';

import './pc-saved-carts-row.component.scss';

@inject('savedStore', 'baseStore')
export default class PCSavedCartsRow extends React.PureComponent {
    handleDeleteCart = () => {
        this.props.savedStore.deleteSavedCart(
            this.props.cart
        ).catch(() => {
            this.props.baseStore.showFailAlert(
                <FormattedMessage
                    id='pc-saved.failed-delete-cart'
                    defaultMessage='Unable to Delete "{cartName}"'
                    values={this.props.cart}
                />
            );
        });
    };

    handleAddAllToCart = () => {
        this.props.baseStore.currentCart.addCartByIdToCart(this.props.cart.cartId);
    };

    render() {
        const {cart} = this.props;

        return (
            <div className='row saved-carts-row'>
                <div className='col-lg-10 carts-cell cart-name-cell'>
                    <div>
                        <PCRouteLink
                            route={'saved.cart-details'}
                            params={{
                                savedCartId: cart.cartId
                            }}
                        >
                            {cart.cartName}
                        </PCRouteLink>
                    </div>
                </div>

                <div className='col-lg-6 col-xs-10 carts-cell'>
                    <div className='visible-lg'>
                        {cart.lineCount}
                    </div>

                    <div className='hidden-lg'>
                        <FormattedMessage
                            id='pc-saved.parts'
                            defaultMessage='{lineCount} parts'
                            values={cart}
                        />
                    </div>
                </div>

                <div className='col-lg-8 col-xs-24 carts-cell cart-add-cell text-right'>
                    <button
                        className='delete-button'
                        onClick={this.handleDeleteCart}
                    />
                    <button
                        className='btn btn-primary'
                        onClick={this.handleAddAllToCart}
                    >
                        <FormattedMessage
                            id='pc.add-all-to-cart'
                            defaultMessage='Add All to Cart'
                        />
                    </button>
                </div>

            </div>
        );
    }
}

PCSavedCartsRow.propTypes = {
    cart: MobxPropTypes.observableObject
};
