
import React from 'react';
import PropTypes from 'prop-types';
import {inject} from 'mobx-react';

@inject('baseStore', 'routerStore')
class RouteLink extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(event) {
        event.preventDefault();
        if (this.props.onClick) {
            this.props.onClick(event);
        }
        const params = this.props.baseStore.extendWithBaseRouteParameters(this.props.params);

        this.props.routerStore.router.navigate(this.props.route, params);
    }

    render() {
        const params = this.props.baseStore.extendWithBaseRouteParameters(this.props.params);

        return (
            <a
                className={this.props.className}
                href={this.props.routerStore.router.buildPath(this.props.route, params)}
                onClick={this.onClick}
            >
                {this.props.children}
            </a>
        );
    }
}

RouteLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    params: PropTypes.object,
    route: PropTypes.string.isRequired
};

export default RouteLink;
