import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';

import PCPriceDisplay from '../../../shared/components/price-display/price-display.component';
import PCCheckoutStepError from '../pc-checkout-step-error.component';
import PCCheckoutEstimatedShippingPriceDisplay from '../../pc-checkout-estimated-shipping-price-display/pc-checkout-estimated-shipping-price-display.component';

import './pc-checkout-step-confirm.component.scss';

@inject('baseStore', 'checkoutStore')
@observer
class PCCheckoutStepConfirm extends React.Component {
    constructor(props) {
        super(props);

        this.submitOrder = this.submitOrder.bind(this);
    }

    submitOrder(event) {
        event.currentTarget.blur();
        this.props.checkoutStore.submitOrder();
    }

    render() {
        if (!this.props.checkoutStore.currencyCode) {
            return null;
        }

        if (!this.props.checkoutStore.orderComplete) {
            return (
                <PCCheckoutStepError />
            );
        }

        // legal compliance messages for certain countries
        const countryCode = this.props.baseStore.countryCode;
        let prop65Message;
        let gstMessage;

        if (countryCode === 'US' || countryCode === 'CA') {
            // this is a fixed message to be displayed only in these languages, even the English only one below
            prop65Message = (
                <div className='row limit-width prop-65-message'>
                    <div className='title'>
                        <div className='icon' />
                        {'WARNING/ADVERTENCIA/ADVERTISSEMENT'}
                    </div>
                    <div>
                        {'Cancer and Reproductive Harm/Cáncer y Daño Reproductivo/Cancer et Troubles de l’appareil reproducteur'}
                    </div>
                    <div>
                        <a
                            href='https://www.p65warnings.ca.gov'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            {'www.P65Warnings.ca.gov'}
                        </a>
                    </div>
                    <div>
                        {'This warning applies to all listed products.'}
                    </div>
                </div>
            );
        }
        if (countryCode === 'AU' || countryCode === 'NZ') {
            // this is a fixed message to be displayed only in English
            gstMessage = (
                <div className='row limit-width gst-message'>
                    {'All prices include GST.'}
                </div>
            );
        }

        let orderSubmitError;

        if (this.props.checkoutStore.orderSubmitState === this.props.checkoutStore.orderSubmitStates.ERROR) {
            orderSubmitError = (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to submit your order.'
                        id='pc-checkout-step-confirm.order-error'
                    />
                </div>
            );
        }

        let estimatedShipping;

        let taxTerm;
        let taxLabel;
        let onlyTaxTerm;
        let taxTermText = this.props.checkoutStore.taxTermText;
        let subTotalLabel;
        const showTaxPerLineitem = this.props.checkoutStore.baseStore._cart.cart.showTaxPerLineitem;
        const taxPercentage = this.props.checkoutStore.baseStore._cart.cart.taxPercentage;

        if(showTaxPerLineitem && taxTermText!==null && taxTermText !== undefined){
            taxTerm = taxTermText.slice(taxTermText.length - 5);
            if(taxTerm.indexOf('(')>-1){
                onlyTaxTerm = taxTerm.slice(1,4);
            }else{
                taxTerm = '';
                onlyTaxTerm = (
                    <FormattedMessage
                        defaultMessage='Tax'
                        id='pc.tax'
                    />);
            }
            taxLabel = (
                <FormattedMessage
                    defaultMessage='Tax on shipping{taxTerm}'
                    id='pc.tax-on-shipping'
                    values={{
                        taxTerm:  taxTerm
                    }}
                />
            );
        }else{
            taxLabel = taxTermText;
        }

        subTotalLabel =  (
            <FormattedMessage
                defaultMessage='Total (Net)'
                id='pc.subtotal'
            />
        );

        if (this.props.checkoutStore.estimatedShippingUpdateInProgress ||
            this.props.checkoutStore.estimatedShipping !== null) {
            estimatedShipping = (
                <div className='row limit-width'>
                    <div className='pull-right'>
                        <PCCheckoutEstimatedShippingPriceDisplay
                            value={this.props.checkoutStore.estimatedShipping}
                        />
                    </div>
                    <div className='secondary-info'>
                        <FormattedMessage
                            defaultMessage='Shipping (Net)'
                            id='pc.shipping'
                        />
                    </div>
                </div>
            );
        }

        if (this.props.checkoutStore.shippingMethodId === '-1') {
            estimatedShipping = undefined;
        }

        let estimatedSalesTax;
        let showSalesTax = true;
        if(showTaxPerLineitem){
            if(!this.props.checkoutStore.taxOnShippingFlag || this.props.checkoutStore.shippingMethodId === '-1'){
                showSalesTax = false;
            }
        }

        let estimatedTotalTax;
        let partPlusShippingTax = 0;

        if (showTaxPerLineitem) {
            partPlusShippingTax
                = this.props.checkoutStore.estimatedSubtotal - this.props.checkoutStore.estimatedSubtotalWithoutTax;

            if (this.props.checkoutStore.estimatedSalesTax !== null) {
                partPlusShippingTax = partPlusShippingTax + this.props.checkoutStore.estimatedSalesTax;
            }

            estimatedTotalTax = (
                <div className='row limit-width'>
                    <div className='pull-right'>
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            inline={true}
                            value={partPlusShippingTax}
                        />
                    </div>
                    <div className='secondary-info'>
                        {onlyTaxTerm} ({taxPercentage}%)
                    </div>
                </div>
            );
        }

        return (
            <div className='checkout-step-confirm container-fluid'>
                <h5 className='row'>
                    <FormattedMessage
                        defaultMessage='Order Totals'
                        id='pc.order-totals'
                    />
                </h5>
                <div className='row limit-width'>
                    <div className='pull-right'>
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            inline={true}
                            value={this.props.checkoutStore.estimatedSubtotalWithoutTax}
                        />
                    </div>
                    <div className='secondary-info'>
                        {subTotalLabel}
                    </div>
                </div>
                {estimatedShipping}
                {estimatedTotalTax}
                <div className='row limit-width totals'>
                    <div className='pull-right'>
                        <PCCheckoutEstimatedShippingPriceDisplay
                            value={this.props.checkoutStore.estimatedTotal}
                        />
                    </div>
                    <div>
                        <FormattedMessage
                            defaultMessage='Total'
                            id='pc.estimated-total'
                        />
                    </div>
                </div>
                {gstMessage}
                <div className='row limit-width'>
                    {orderSubmitError}
                    <button
                        className='btn btn-primary btn-continue'
                        onClick={this.submitOrder}
                    >
                        <FormattedMessage
                            defaultMessage='Submit Order'
                            id='pc.submit-order'
                        />
                    </button>
                    <div className='dealer-message'>
                        <FormattedMessage
                            defaultMessage='Your order will be submitted to your dealer to fulfill.'
                            id='pc-checkout-step-confirm.dealer-message'
                        />
                    </div>
                </div>
                {prop65Message}
            </div>
        );
    }
}

export default PCCheckoutStepConfirm;
