import React from 'react';
import {FormattedMessage} from 'react-intl';

import {inject, observer} from 'mobx-react';

import {makePartsCatalogUrl} from '../../shared/parts-catalog-url-builder.util';

import './pc-checkout-leaving.component.scss';

@inject('baseStore', 'checkoutStore')
@observer
class PCCheckoutLeaving extends React.Component {
    constructor(props) {
        super(props);

        this.onContinueCheckout = this.onContinueCheckout.bind(this);
    }

    onContinueCheckout() {
        this.props.checkoutStore.activeCheckoutStep = 'review';
    }

    render() {
        return (
            <div className='checkout-leaving large-message-screen'>
                <div className='message-line-1'>
                    <FormattedMessage
                        defaultMessage='You are about to leave the checkout process.  Your progress will be lost.'
                        id='pc-checkout-leaving.message-line-1'
                    />
                </div>
                <div className='message-line-2'>
                    <FormattedMessage
                        defaultMessage='Need to add another part?  Continue Shopping to return to the John Deere Parts Catalog.'
                        id='pc-checkout-leaving.message-line-2'
                    />
                </div>

                <div className='row'>
                    <div className='col-sm-offset-2 col-sm-10 col-md-offset-4 col-md-8 col-lg-offset-6 col-lg-6'>
                        <a
                            className='btn btn-hollow'
                            href={makePartsCatalogUrl(this.props.baseStore)}
                        >
                            <FormattedMessage
                                defaultMessage='Continue Shopping'
                                id='pc.continue-shopping'
                            />
                        </a>
                    </div>

                    <div className='col-sm-10 col-md-8 col-lg-6'>
                        <button
                            className='btn btn-hollow'
                            onClick={this.onContinueCheckout}
                        >
                            <FormattedMessage
                                defaultMessage='Continue Checkout'
                                id='pc-checkout-leaving.continue-checkout'
                            />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PCCheckoutLeaving;
