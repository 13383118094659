
export default [
    {
        name: 'account-created',
        path: '/account-created',
        registeredUserRequired: true,
        dealerSelectorVisible: false,
        cartVisible: true,
        newPartSearchVisible: true,
        secureCheckoutVisible: false
    }
];
