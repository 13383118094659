import _ from 'lodash';

import {action, computed, observable, reaction, when} from 'mobx';

import routes from '../../routes';
import PCCart from './pc-cart.class';
import PCDealerPolicies from './pc-dealer-policies.class';
import PCProfileDealers from './pc-profile-dealers.class';
import PCUserSession from './pc-user-session.class';
import AnalyticsService from '../shared/services/analytics.service';
import FooterService from '../shared/services/footer-info.service';
import {fetchJson, generateTempAuthToken, setTempAuthorizationToken} from '../shared/fetch-json.util';

const flashAlertTimeout = 3000;

class PCBaseStore {
    @observable _launchRouteBaseName;
    @observable _locale;
    @observable _countryCode;
    @observable _messages;
    @observable _dealer;
    @observable _dealerSelectorOpen = false;
    @observable _sidebarMenuVisible = false;
    @observable _footerInfo;
    @observable _criticalError = false;
    @observable _loadingOverlayCounter = 0;
    @observable _tempAuthToken;
    _flashAlerts = observable([], {
        deep: false
    });
    _filesList;
    _internalNavigation = false;

    constructor(launchHash, routerStore) {
        let setTempAuthToken = () => {
            generateTempAuthToken().then(
                action((token) => {
                    setTempAuthorizationToken(token);
                    this._tempAuthToken = token;
                })
            );
        }
        setTempAuthToken();
        setInterval(setTempAuthToken,3000000);
        this._routerStore = routerStore;
        this._userSession = new PCUserSession(launchHash, this, routerStore);
        this._cart = new PCCart(this, routerStore);
        this._dealerPolicies = new PCDealerPolicies(this);

        this._showAlert = this._showAlert.bind(this);
        this.getFilesList = this.getFilesList.bind(this);
        this.findAppJsonFileByPrefix = this.findAppJsonFileByPrefix.bind(this);
        this.extendWithBaseRouteParameters = this.extendWithBaseRouteParameters.bind(this);
        this.setDealerSelectorStore = this.setDealerSelectorStore.bind(this);

        // extract parameters from the route
        reaction(
            () => _.get(routerStore, 'route.params.locale-cd'),
            (locale) => {
                this._locale = locale;
            },
            {
                fireImmediately: true
            }
        );

        // save the first base route we land on
        when(
            () => this.routeBaseName !== undefined,
            () => {
                this._launchRouteBaseName = this.routeBaseName;
            }
        );

        // the country code from the user takes precedence over the one provided in the route
        reaction(
            () => {
                return {
                    route: _.get(routerStore, 'route.params.country-cd'),
                    user: _.get(this._userSession, 'currentUser.countryCode')
                };
            },
            ({
                route, user
            }) => {
                this._countryCode = user || route;
            },
            {
                fireImmediately: true
            }
        );

        // load the messages
        reaction(
            () => this._locale,
            (locale) => {
                if (!locale || locale === 'en-US') {
                    this._messages = null;
                } else {
                    // will need to load the messages
                    this.findAppJsonFileByPrefix(`messages-${locale}`).then((messagesFile) => {
                        return fetchJson(WEBPACK_ROOT_URL + messagesFile);
                    }).then(action((messages) => {
                        this._messages = messages;
                    })).catch(action(() => {
                        // just default to English
                        this._messages = null;
                    }));
                }
            },
            {
                fireImmediately: true
            }
        );

        // get the footer data when it becomes possible to do so
        reaction(
            () => {
                return {
                    tempToken : this._tempAuthToken
                };
            },
            (params) => {
                if (!params.tempToken) {
                    return;
                }
                FooterService.getFooterInfo(
                    this._countryCode,
                    this._locale
                ).then(
                    action((footerInfo) => {
                        this._footerInfo = footerInfo;
                    })
                ).catch(() => {
                    // the footer links will just be missing, no need to alert the user
                });
            },
            {
                fireImmediately: true
            }
        );

        // when the route changes scroll to the top
        reaction(
            () => routerStore.route,
            () => {
                window.scrollTo(0, 0);
            }
        );

        // when the dealer selector opens add a class
        reaction(
            () => this.dealerSelectorOpen,
            () => {
                if (this.dealerSelectorOpen) {
                    document.body.classList.add('dealer-selector-open');
                } else {
                    document.body.classList.remove('dealer-selector-open');
                }
            }
        );

        // if we don't have a registered user and the current route requires one redirect to the login
        when(
            () => this.routeBaseInfo.registeredUserRequired &&
                  !_.get(this.userSession.currentUser, 'id') &&
                  !this._userSession.loggingIn,
            () => {
                this.userSession.loginAsRegistered();
            }
        );

        // send analytics message when the route changes
        reaction(
            () => {
                return {
                    locale: this._locale,
                    countryCode: this._countryCode,
                    route: routerStore.route
                };
            },
            (params) => {
                // do not send event if application is triggering the navigation internally
                if (this._internalNavigation) {
                    return;
                }

                const splitName = params.route.name.split('.');

                AnalyticsService.writePageNavigationEvent(
                    params.locale,
                    params.countryCode,
                    splitName[0],
                    splitName[1] || 'root'
                );
            }
        );
    }

    /**
     * Adds the set of query parameters that are read by the base store for all pages
     * to all of the provided routes.
     * @param {array} routesToUpdate The application routes.
     * @returns {array} The routes with the added parameters.
     */
    static addBaseRouteParameters(routesToUpdate) {
        _.each(routesToUpdate, (route) => {
            let path = route.path;

            if (_.includes(path, '&')) {
                path += '&';
            } else {
                path += '?';
            }
            path += 'locale-cd&country-cd&cart-id&dealer-id';

            route.path = path;
        });

        return routesToUpdate;
    }

    /**
     * Copies the set of query parameters that are read by the base store for all pages
     * onto the provided parameters.  This should be called whenever generating a link
     * to ensure that the site entry settings are maintained during navigation.
     * @param {object} [params] If provided this object is extended with the parameters.
     * @returns {object} The object extended with the parameters and falsey values removed.
     */
    extendWithBaseRouteParameters(params) {
        return _.extend(params || {}, {
            'locale-cd': _.get(this._routerStore, 'route.params.locale-cd'),
            'country-cd': _.get(this._routerStore, 'route.params.country-cd'),
            'cart-id': _.get(this._routerStore, 'route.params.cart-id'),
            'dealer-id': _.get(this._routerStore, 'route.params.dealer-id')
        });
    }

    getFilesList() {
        if (this._filesList) {
            return Promise.resolve(this._filesList);
        }

        return fetchJson(
            `${WEBPACK_ROOT_URL}parts-checkout-ui-files.json`
        ).then((filesList) => {
            this._filesList = filesList;

            return filesList;
        });
    }

    findAppJsonFileByPrefix(jsonFileName) {
        return this.getFilesList().then((files) => {
            // locate the hashed file in the list
            const fullFileName = _.find(files.json, (fileName) => {
                return fileName.indexOf(jsonFileName) !== -1;
            });

            if (!fullFileName) {
                return Promise.reject();
            }

            return fullFileName;
        });
    }

    @action.bound
    showCriticalError() {
        this._criticalError = true;
    }
    get criticalError() {
        return this._criticalError;
    }

    get userSession() {
        return this._userSession;
    }

    set dealer(dealer) {
        this._dealer = dealer;
    }
    get dealer() {
        return this._dealer;
    }
    set dealerSelectorOpen(dealerSelectorOpen) {
        this._dealerSelectorOpen = dealerSelectorOpen;
    }
    get dealerSelectorOpen() {
        return this._dealerSelectorOpen;
    }
    setDealerSelectorStore(dealerSelectorStore) {
        this._profileDealers = new PCProfileDealers(
            this,
            dealerSelectorStore,
            this._routerStore
        );
    }
    get profileDealers() {
        return this._profileDealers;
    }

    get countryCode() {
        return this._countryCode;
    }
    get locale() {
        return this._locale;
    }
    get messages() {
        return this._messages;
    }

    @computed
    get loadingOverlayVisible() {
        return this._loadingOverlayCounter > 0;
    }
    @action.bound
    showLoadingOverlay() {
        this._loadingOverlayCounter += 1;
    }
    @action.bound
    hideLoadingOverlay() {
        if (this._loadingOverlayCounter !== 0) {
            this._loadingOverlayCounter -= 1;
        }
    }

    get sidebarMenuVisible() {
        return this._sidebarMenuVisible;
    }
    @action.bound
    toggleSidebarMenu() {
        this._sidebarMenuVisible = !this._sidebarMenuVisible;
    }
    @action.bound
    showSidebarMenu() {
        this._sidebarMenuVisible = true;
    }
    @action.bound
    hideSidebarMenu() {
        this._sidebarMenuVisible = false;
    }

    get flashAlertTimeout() {
        return flashAlertTimeout;
    }
    get flashAlerts() {
        return this._flashAlerts;
    }
    @action.bound
    showFailAlert(message) {
        this._showAlert(message, 'fail');
    }
    @action.bound
    showSuccessAlert(message) {
        this._showAlert(message, 'success');
    }
    _showAlert(message, type) {
        const messageObj = {
            message: message,
            type: type
        };

        this._flashAlerts.push(messageObj);

        setTimeout(action(() => {
            this._flashAlerts.remove(messageObj);
        }), flashAlertTimeout);
    }

    get footerInfo() {
        return this._footerInfo;
    }

    get currentCart() {
        return this._cart;
    }

    get dealerPolicies() {
        return this._dealerPolicies;
    }

    @computed get routeBaseName() {
        let routeBase;

        if (this._routerStore.route) {
            routeBase = this._routerStore.route.name.split('.')[0];
        }

        return routeBase;
    }

    @computed get routeBaseInfo() {
        return _.find(routes, {
            name: this.routeBaseName
        }) || {
            cartVisible: true,
            newPartSearchVisible: true,
            notFound: true
        };
    }

    get launchRouteBaseName() {
        return this._launchRouteBaseName;
    }

    @computed get loginVisible() {
        if (this.routeBaseInfo.notFound) {
            return false;
        }

        const userId = _.get(this._userSession.currentUser, 'id');
        const userEmailId = _.get(this._userSession.currentUser, 'idEmail');
        const hasUser = userId || userEmailId;
        const hasRegisteredUser = userId !== undefined;

        return this.routeBaseInfo.registeredUserRequired && !hasRegisteredUser || !hasUser;
    }

    startInternalNavigation() {
        this._internalNavigation = true;
    }

    endInternalNavigation() {
        this._internalNavigation = false;
    }
}

export default PCBaseStore;
