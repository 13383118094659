import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber, FormattedMessage} from 'react-intl';

import currencyMap from './currency-map';
import './price-display.component.scss';

const PCPriceDisplay = (
    {
        currency,
        inline,
        value
    }
) => {
    let priceClassName = 'price-display';
    let priceDisplayBody;

    if (inline) {
        priceClassName += ' inline';
    }

    if (currency && _.isFinite(value) && value !== 0) {
        let digits;
        let symbol;
        const currencyDetails = currencyMap[currency];

        if (currencyDetails) {
            digits = currencyDetails.digits;
            symbol = currencyDetails.symbol;
        }

        priceDisplayBody = (
            <React.Fragment>
                <div className='price'>
                    {symbol}
                    <FormattedNumber
                        maximumFractionDigits={digits}
                        minimumFractionDigits={digits}
                        value={value}
                    />
                </div>
                <div className='code'>
                    {currency}
                </div>
            </React.Fragment>
        );
    } else {
        priceDisplayBody = (
            <FormattedMessage
                defaultMessage='Contact Your Dealer'
                id='pc.contact-your-dealer'
            />
        );
    }

    return (
        <div className={priceClassName}>
            {priceDisplayBody}
        </div>
    );
};

PCPriceDisplay.propTypes = {
    currency: PropTypes.string,
    inline: PropTypes.bool,
    value: PropTypes.number
};

export default PCPriceDisplay;
