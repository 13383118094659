import _ from 'lodash';

function _pushEvent(event) {
    window.appEventData = window.appEventData || [];
    window.appEventData.push(event);
}

class AnalyticsService {
    static writeUserSignedInEvent(userStatus) {
        _pushEvent({
            user: {
                userStatus: userStatus
            },
            event: 'User Signed IN'
        });
    }

    static writePageNavigationEvent(
        locale,
        countryCode,
        pageType,
        pageName
    ) {
        _pushEvent({
            page: {
                siteLanguage: locale,
                siteCountry: countryCode,
                pageType: pageType,
                pageName: pageName
            },
            event: 'Page Loaded'
        });
    }

    static writeAddToCartEvent(
        parts,
        type
    ) {
        if (_.isEmpty(parts)) {
            return;
        }

        _pushEvent({
            product: parts,
            eventDetails: {
                eventType: type
            },
            event: 'Product Added'
        });
    }

    static writeRemoveFromCartEvent(
        parts,
        type
    ) {
        if (_.isEmpty(parts)) {
            return;
        }

        _pushEvent({
            product: parts,
            eventDetails: {
                eventType: type
            },
            event: 'Product Removed'
        });
    }

    static writeCheckoutStepEncounteredEvent(
        checkoutStep,
        products
    ) {
        const eventObj = {
            eventDetails: {
                checkoutStep: checkoutStep
            },
            event: 'Checkout Step Encountered'
        };

        if (products) {
            eventObj.product = products;
        }

        _pushEvent(eventObj);
    }

    static writeOrderPlacedEvent(
        orderId,
        total,
        currencyCode,
        shippingMethod,
        paymentMethod,
        products,
        dealerId,
        userStatus
    ) {
        _pushEvent({
            transaction: {
                transactionId: orderId,
                total: total,
                currencyCode: currencyCode,
                shippingGroup: [{
                    shippingMethod: shippingMethod
                }],
                payment: [{
                    paymentMethod: paymentMethod
                }],
                item: products
            },
            user: {
                dealerId: dealerId,
                userStatus: userStatus
            },
            event: 'Order Placed'
        });
    }
}

export default AnalyticsService;
