import _ from 'lodash';
import {detect} from 'detect-browser';
import buildUrl from 'build-url';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import cryptoRandomString from 'crypto-random-string';

import {action, observable, runInAction, reaction, toJS} from 'mobx';

import {fetchJson, setAuthorizationToken, getAuthorizationToken, setLogoutFunction} from '../shared/fetch-json.util';
import AnalyticsService from '../shared/services/analytics.service';
import GuestUserService from '../shared/services/guest-user.service';

const SESSION_STORAGE_KEY = 'pc-user-session';
const NONCE_STORAGE_KEY = 'pc-auth-nonce';
const STATE_STORAGE_KEY = 'pc-auth-state';
const CRYPTO_LENGTH = 32;

class PCUserSession {
    @observable _loggingIn = false;
    @observable _loginFailed = false;
    @observable _guestIsRegistered = false;
    @observable _currentUser = null;
    _idToken;

    constructor(launchHash, baseStore, routerStore) {
        this.baseStore = baseStore;
        this.routerStore = routerStore;
        setLogoutFunction(this.logout);

        // read the login information from the session storage, if available
        const storedSession = window.sessionStorage.getItem(SESSION_STORAGE_KEY);

        if (storedSession) {
            runInAction(() => {
                try {
                    const storedSessionObj = JSON.parse(storedSession);

                    setAuthorizationToken(storedSessionObj.authorizationToken);
                    this._idToken = storedSessionObj.idToken;
                    this._currentUser = storedSessionObj.currentUser;
                } catch (err) {
                    // do nothing, since we can't read the authentication details they'll just be shown the sign-in page
                }
            });
        } else if (!launchHash) {
            // no stored session and no launch hash, check with okta to see if the user has an active session
            // (they will have a cookie set in the browser that lets okta identify this)
            runInAction(() => {
                this._loggingIn = true;

                fetchJson(
                    buildUrl(AUTH_API_URL, {
                        path: 'sessions/me'
                    }),
                    {
                        credentials: 'include'
                    }
                ).then(action(() => {
                    this.loginAsRegistered();
                })).catch(action(() => {
                    // no session, just go back to not logging in, login screen will be shown
                    this._loggingIn = false;
                }));
            });
        }

        // the launch hash contains the data passed from the auth provider, parse it out
        if (launchHash) {
            runInAction(() => {
                try {
                    // get the id token, it has the user information
                    const parsedLaunchHash = queryString.parse(launchHash);
                    const decodedIdToken = jwtDecode(parsedLaunchHash.id_token);
                    const decodedState = JSON.parse(atob(parsedLaunchHash.state));

                    if (decodedIdToken.nonce !== window.sessionStorage.getItem(NONCE_STORAGE_KEY) ||
                        decodedState.nonce !== window.sessionStorage.getItem(STATE_STORAGE_KEY)) {
                        // state or nonce mismatch suggests some sort of attack
                        baseStore.showCriticalError();
                        return;
                    }

                    // verification passed, clear the values
                    window.sessionStorage.removeItem(STATE_STORAGE_KEY);
                    window.sessionStorage.removeItem(NONCE_STORAGE_KEY);

                    const finish = () => {
                        // save the user details
                        this._idToken = parsedLaunchHash.id_token;
                        this._currentUser = {
                            id: decodedIdToken.userID,
                            userName: decodedIdToken.userID,
                            name: decodedIdToken.name,
                            email: decodedIdToken.emailID,
                            countryCode: decodedIdToken.country
                        };

                        AnalyticsService.writeUserSignedInEvent('registered');

                        // redirect to the page that we logged in from
                        routerStore.router.navigate(
                            decodedState.routeName,
                            decodedState.routeParams,
                            {
                                replace: true
                            }
                        );
                    };

                    if (parsedLaunchHash.access_token) {
                        // save the auth token
                        setAuthorizationToken(parsedLaunchHash.access_token);
                        finish();
                    } else {
                        // no auth token, get one from the guest service.
                        // This is temporary until the IE issues can be properly resolved.
                        this._loggingIn = true;
                        GuestUserService.getLoginToken(decodedIdToken.emailID).then(
                            action((token) => {
                                this._loggingIn = false;
                                setAuthorizationToken(token);
                                finish();
                            })
                        ).catch(
                            action(() => {
                                this._loggingIn = false;
                                baseStore.showCriticalError();
                            })
                        );
                    }
                } catch (err) {
                    // authentication was malformed
                    baseStore.showCriticalError();
                }
            });
        }

        // when the user is updated save to session storage
        reaction(
            () => this._currentUser,
            (currentUser) => {
                if (currentUser) {
                    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify({
                        currentUser: currentUser,
                        authorizationToken: getAuthorizationToken(),
                        idToken: this._idToken
                    }));
                } else {
                    window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
                }
            },
            {
                fireImmediately: true
            }
        );
    }

    @action.bound
    loginAsRegistered() {
        this._loggingIn = true;
        this._loginFailed = false;

        const browser = detect();
        const state = cryptoRandomString(CRYPTO_LENGTH);
        const nonce = cryptoRandomString(CRYPTO_LENGTH);
        const responseTypes = ['id_token'];

        window.sessionStorage.setItem(NONCE_STORAGE_KEY, nonce);
        window.sessionStorage.setItem(STATE_STORAGE_KEY, state);

        // Only request the auth token if we are not IE.
        // In IE the URL length is exceeded if we request both the auth and ID tokens together.
        // The postMessage approach didn't work due to IE blocking cross-domain postMessage unless
        // both sites are in Intranet or Trusted Sites.
        // Until this can be properly resolved we'll request the ID token here and get the auth token
        // from the guest login service after the redirect.
        if (browser.name !== 'ie') {
            responseTypes.push('token');
        }

        window.location.replace(buildUrl(AUTH_LOGIN_URL, {
            queryParams: {
                'client_id': AUTH_CLIENT_ID,
                'response_mode': 'fragment',
                'response_type': responseTypes.join(' '),
                'scope': 'openid customer_profile profile',
                'redirect_uri': encodeURIComponent(
                    buildUrl(
                        WEBPACK_ROOT_URL,
                        {
                            path: '/authenticate'
                        }
                    )
                ),
                'nonce': nonce,
                'state': btoa(JSON.stringify({
                    'nonce': state,
                    'routeName': this.routerStore.route.name,
                    'routeParams': this.routerStore.route.params
                }))
            }
        }));
    }

    @action.bound
    loginAsGuest(email, force) {
        this._loggingIn = true;
        this._loginFailed = false;

        const getLoginToken = () => {
            GuestUserService.getLoginToken(email).then(
                action((token) => {
                    this._loggingIn = false;
                    AnalyticsService.writeUserSignedInEvent('guest');
                    setAuthorizationToken(token);
                    this._currentUser = {
                        idEmail: email
                    };
                })
            ).catch(
                action(() => {
                    this._loggingIn = false;
                    this._loginFailed = true;
                })
            );
        };

        if (force) {
            getLoginToken();
            return;
        }

        GuestUserService.userExists(email).then(
            action((userExists) => {
                if (userExists) {
                    this._guestIsRegistered = true;
                    this._loggingIn = false;
                    return;
                }

                getLoginToken();
            })
        ).catch(
            action(() => {
                this._loggingIn = false;
                this._loginFailed = true;
            })
        );
    }

    @action.bound
    logout() {
        const userId = toJS(_.get(this._currentUser, 'id'));
        const idToken = this._idToken;

        // clear variables
        this._currentUser = null;
        this._loginFailed = false;
        this._guestIsRegistered = false;
        this._idToken = null;
        setAuthorizationToken(null);

        this._loggingIn = true;

        if (userId) {

            // logged in as a registered user need to logout with the authentication provider
            window.location.replace(buildUrl(AUTH_LOGOUT_URL, {
                queryParams: {
                    'id_token_hint': idToken,
                    // this must be static so is a direct link to the root instead of makePartsCatalogUrl
                    'post_logout_redirect_uri': PARTS_CATALOG_URL
                }
            }));

            //checkout from myjohndeere
            window.location.replace(buildUrl(MJD_LOGOUT_URL));

        } else {
            // logged in as a guest, redirect to the parts catalog
            window.location.href = PARTS_CATALOG_URL;
        }

    }

    get currentUser() {
        return this._currentUser;
    }
    get loggingIn() {
        return this._loggingIn;
    }
    get loginFailed() {
        return this._loginFailed;
    }
    @action.bound
    resetGuest() {
        this._guestIsRegistered = false;
    }
    get guestIsRegistered() {
        return this._guestIsRegistered;
    }
}

export default PCUserSession;
