export default {
    'AED': {
        'symbol': 'د.إ',
        'digits': 2
    },
    'AFN': {
        'symbol': '؋',
        'digits': 2
    },
    'ALL': {
        'symbol': 'L',
        'digits': 2
    },
    'AMD': {
        'symbol': '֏',
        'digits': 2
    },
    'ANG': {
        'symbol': 'ƒ',
        'digits': 2
    },
    'AOA': {
        'symbol': 'Kz',
        'digits': 2
    },
    'ARS': {
        'symbol': '$',
        'digits': 2
    },
    'AUD': {
        'symbol': '$',
        'digits': 2
    },
    'AWG': {
        'symbol': 'ƒ',
        'digits': 2
    },
    'AZN': {
        'symbol': '₼',
        'digits': 2
    },
    'BAM': {
        'symbol': 'KM',
        'digits': 2
    },
    'BBD': {
        'symbol': '$',
        'digits': 2
    },
    'BDT': {
        'symbol': '৳',
        'digits': 2
    },
    'BGN': {
        'symbol': 'лв',
        'digits': 2
    },
    'BHD': {
        'symbol': '.د.ب',
        'digits': 3
    },
    'BIF': {
        'symbol': 'FBu',
        'digits': 0
    },
    'BMD': {
        'symbol': '$',
        'digits': 2
    },
    'BND': {
        'symbol': '$',
        'digits': 2
    },
    'BOB': {
        'symbol': '$b',
        'digits': 2
    },
    'BOV': {
        'digits': 2
    },
    'BRL': {
        'symbol': 'R$',
        'digits': 2
    },
    'BSD': {
        'symbol': '$',
        'digits': 2
    },
    'BTN': {
        'symbol': 'Nu.',
        'digits': 2
    },
    'BWP': {
        'symbol': 'P',
        'digits': 2
    },
    'BYN': {
        'symbol': 'Br',
        'digits': 2
    },
    'BZD': {
        'symbol': 'BZ$',
        'digits': 2
    },
    'CAD': {
        'symbol': '$',
        'digits': 2
    },
    'CDF': {
        'symbol': 'FC',
        'digits': 2
    },
    'CHE': {
        'digits': 2
    },
    'CHF': {
        'symbol': 'CHF',
        'digits': 2
    },
    'CHW': {
        'digits': 2
    },
    'CLF': {
        'digits': 4
    },
    'CLP': {
        'symbol': '$',
        'digits': 0
    },
    'CNY': {
        'symbol': '¥',
        'digits': 2
    },
    'COP': {
        'symbol': '$',
        'digits': 2
    },
    'COU': {
        'digits': 2
    },
    'CRC': {
        'symbol': '₡',
        'digits': 2
    },
    'CUC': {
        'symbol': '$',
        'digits': 2
    },
    'CUP': {
        'symbol': '₱',
        'digits': 2
    },
    'CVE': {
        'symbol': '$',
        'digits': 2
    },
    'CZK': {
        'symbol': 'Kč',
        'digits': 2
    },
    'DJF': {
        'symbol': 'Fdj',
        'digits': 0
    },
    'DKK': {
        'symbol': 'kr',
        'digits': 2
    },
    'DOP': {
        'symbol': 'RD$',
        'digits': 2
    },
    'DZD': {
        'symbol': 'دج',
        'digits': 2
    },
    'EGP': {
        'symbol': '£',
        'digits': 2
    },
    'ERN': {
        'symbol': 'Nfk',
        'digits': 2
    },
    'ETB': {
        'symbol': 'Br',
        'digits': 2
    },
    'EUR': {
        'symbol': '€',
        'digits': 2
    },
    'FJD': {
        'symbol': '$',
        'digits': 2
    },
    'FKP': {
        'symbol': '£',
        'digits': 2
    },
    'GBP': {
        'symbol': '£',
        'digits': 2
    },
    'GEL': {
        'symbol': '₾',
        'digits': 2
    },
    'GHS': {
        'symbol': 'GH₵',
        'digits': 2
    },
    'GIP': {
        'symbol': '£',
        'digits': 2
    },
    'GMD': {
        'symbol': 'D',
        'digits': 2
    },
    'GNF': {
        'symbol': 'FG',
        'digits': 0
    },
    'GTQ': {
        'symbol': 'Q',
        'digits': 2
    },
    'GYD': {
        'symbol': '$',
        'digits': 2
    },
    'HKD': {
        'symbol': '$',
        'digits': 2
    },
    'HNL': {
        'symbol': 'L',
        'digits': 2
    },
    'HRK': {
        'symbol': 'kn',
        'digits': 2
    },
    'HTG': {
        'symbol': 'G',
        'digits': 2
    },
    'HUF': {
        'symbol': 'Ft',
        'digits': 2
    },
    'IDR': {
        'symbol': 'Rp',
        'digits': 2
    },
    'ILS': {
        'symbol': '₪',
        'digits': 2
    },
    'INR': {
        'symbol': '₹',
        'digits': 2
    },
    'IQD': {
        'symbol': 'ع.د',
        'digits': 3
    },
    'IRR': {
        'symbol': '﷼',
        'digits': 2
    },
    'ISK': {
        'symbol': 'kr',
        'digits': 0
    },
    'JMD': {
        'symbol': 'J$',
        'digits': 2
    },
    'JOD': {
        'symbol': 'JD',
        'digits': 3
    },
    'JPY': {
        'symbol': '¥',
        'digits': 0
    },
    'KES': {
        'symbol': 'KSh',
        'digits': 2
    },
    'KGS': {
        'symbol': 'лв',
        'digits': 2
    },
    'KHR': {
        'symbol': '៛',
        'digits': 2
    },
    'KMF': {
        'symbol': 'CF',
        'digits': 0
    },
    'KPW': {
        'symbol': '₩',
        'digits': 2
    },
    'KRW': {
        'symbol': '₩',
        'digits': 0
    },
    'KWD': {
        'symbol': 'KD',
        'digits': 3
    },
    'KYD': {
        'symbol': '$',
        'digits': 2
    },
    'KZT': {
        'symbol': 'лв',
        'digits': 2
    },
    'LAK': {
        'symbol': '₭',
        'digits': 2
    },
    'LBP': {
        'symbol': '£',
        'digits': 2
    },
    'LKR': {
        'symbol': '₨',
        'digits': 2
    },
    'LRD': {
        'symbol': '$',
        'digits': 2
    },
    'LSL': {
        'symbol': 'M',
        'digits': 2
    },
    'LYD': {
        'symbol': 'LD',
        'digits': 3
    },
    'MAD': {
        'symbol': 'MAD',
        'digits': 2
    },
    'MDL': {
        'symbol': 'lei',
        'digits': 2
    },
    'MGA': {
        'symbol': 'Ar',
        'digits': 2
    },
    'MKD': {
        'symbol': 'ден',
        'digits': 2
    },
    'MMK': {
        'symbol': 'K',
        'digits': 2
    },
    'MNT': {
        'symbol': '₮',
        'digits': 2
    },
    'MOP': {
        'symbol': 'MOP$',
        'digits': 2
    },
    'MRU': {
        'symbol': 'UM',
        'digits': 2
    },
    'MUR': {
        'symbol': '₨',
        'digits': 2
    },
    'MVR': {
        'symbol': 'Rf',
        'digits': 2
    },
    'MWK': {
        'symbol': 'MK',
        'digits': 2
    },
    'MXN': {
        'symbol': '$',
        'digits': 2
    },
    'MXV': {
        'digits': 2
    },
    'MYR': {
        'symbol': 'RM',
        'digits': 2
    },
    'MZN': {
        'symbol': 'MT',
        'digits': 2
    },
    'NAD': {
        'symbol': '$',
        'digits': 2
    },
    'NGN': {
        'symbol': '₦',
        'digits': 2
    },
    'NIO': {
        'symbol': 'C$',
        'digits': 2
    },
    'NOK': {
        'symbol': 'kr',
        'digits': 2
    },
    'NPR': {
        'symbol': '₨',
        'digits': 2
    },
    'NZD': {
        'symbol': '$',
        'digits': 2
    },
    'OMR': {
        'symbol': '﷼',
        'digits': 3
    },
    'PAB': {
        'symbol': 'B/.',
        'digits': 2
    },
    'PEN': {
        'symbol': 'S/.',
        'digits': 2
    },
    'PGK': {
        'symbol': 'K',
        'digits': 2
    },
    'PHP': {
        'symbol': '₱',
        'digits': 2
    },
    'PKR': {
        'symbol': '₨',
        'digits': 2
    },
    'PLN': {
        'symbol': 'zł',
        'digits': 2
    },
    'PYG': {
        'symbol': 'Gs',
        'digits': 0
    },
    'QAR': {
        'symbol': '﷼',
        'digits': 2
    },
    'RON': {
        'symbol': 'lei',
        'digits': 2
    },
    'RSD': {
        'symbol': 'Дин.',
        'digits': 2
    },
    'RUB': {
        'symbol': '₽',
        'digits': 2
    },
    'RWF': {
        'symbol': 'R₣',
        'digits': 0
    },
    'SAR': {
        'symbol': '﷼',
        'digits': 2
    },
    'SBD': {
        'symbol': '$',
        'digits': 2
    },
    'SCR': {
        'symbol': '₨',
        'digits': 2
    },
    'SDG': {
        'symbol': 'ج.س.',
        'digits': 2
    },
    'SEK': {
        'symbol': 'kr',
        'digits': 2
    },
    'SGD': {
        'symbol': '$',
        'digits': 2
    },
    'SHP': {
        'symbol': '£',
        'digits': 2
    },
    'SLL': {
        'symbol': 'Le',
        'digits': 2
    },
    'SOS': {
        'symbol': 'S',
        'digits': 2
    },
    'SRD': {
        'symbol': '$',
        'digits': 2
    },
    'SSP': {
        'symbol': '£',
        'digits': 2
    },
    'STN': {
        'symbol': 'Db',
        'digits': 2
    },
    'SVC': {
        'symbol': '$',
        'digits': 2
    },
    'SYP': {
        'symbol': '£',
        'digits': 2
    },
    'SZL': {
        'symbol': 'E',
        'digits': 2
    },
    'THB': {
        'symbol': '฿',
        'digits': 2
    },
    'TJS': {
        'symbol': 'SM',
        'digits': 2
    },
    'TMT': {
        'symbol': 'T',
        'digits': 2
    },
    'TND': {
        'symbol': 'د.ت',
        'digits': 3
    },
    'TOP': {
        'symbol': 'T$',
        'digits': 2
    },
    'TRY': {
        'symbol': '₺',
        'digits': 2
    },
    'TTD': {
        'symbol': 'TT$',
        'digits': 2
    },
    'TWD': {
        'symbol': 'NT$',
        'digits': 2
    },
    'TZS': {
        'symbol': 'TSh',
        'digits': 2
    },
    'UAH': {
        'symbol': '₴',
        'digits': 2
    },
    'UGX': {
        'symbol': 'USh',
        'digits': 0
    },
    'USD': {
        'symbol': '$',
        'digits': 2
    },
    'USN': {
        'digits': 2
    },
    'UYI': {
        'digits': 0
    },
    'UYU': {
        'symbol': '$U',
        'digits': 2
    },
    'UZS': {
        'symbol': 'лв',
        'digits': 2
    },
    'VEF': {
        'symbol': 'Bs',
        'digits': 2
    },
    'VND': {
        'symbol': '₫',
        'digits': 0
    },
    'VUV': {
        'symbol': 'VT',
        'digits': 0
    },
    'WST': {
        'symbol': 'WS$',
        'digits': 2
    },
    'XAF': {
        'symbol': 'FCFA',
        'digits': 0
    },
    'XAG': {
        'digits': 0
    },
    'XAU': {
        'digits': 0
    },
    'XBA': {
        'digits': 0
    },
    'XBB': {
        'digits': 0
    },
    'XBC': {
        'digits': 0
    },
    'XBD': {
        'digits': 0
    },
    'XCD': {
        'symbol': '$',
        'digits': 2
    },
    'XDR': {
        'digits': 0
    },
    'XOF': {
        'symbol': 'CFA',
        'digits': 0
    },
    'XPD': {
        'digits': 0
    },
    'XPF': {
        'symbol': '₣',
        'digits': 0
    },
    'XPT': {
        'digits': 0
    },
    'XSU': {
        'digits': 0
    },
    'XTS': {
        'digits': 0
    },
    'XUA': {
        'digits': 0
    },
    'XXX': {
        'digits': 0
    },
    'YER': {
        'symbol': '﷼',
        'digits': 2
    },
    'ZAR': {
        'symbol': 'R',
        'digits': 2
    },
    'ZMW': {
        'digits': 2
    },
    'ZWL': {
        'digits': 2
    }
};
