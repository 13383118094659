import {inject, observer, Provider} from 'mobx-react';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import PCSavedCarts from './pc-saved-carts/pc-saved-carts.component';
import PCSavedWishlist from './pc-saved-wishlist/pc-saved-wishlist.component';
import PCSavedCartDetails from './pc-saved-cart-details/pc-saved-cart-details.component';
import PCContentHeader from '../shared/components/content-header/content-header.component';
import PCSavedStore from './pc-saved.store';

import './pc-saved.component.scss';

@inject('baseStore', 'routerStore')
@observer
class PCSaved extends React.Component {
    constructor(props) {
        super(props);

        this._savedStore = new PCSavedStore(this.props.baseStore, this.props.routerStore);
    }

    componentWillUnmount() {
        this._savedStore.destroy();
    }

    render() {
        let page;

        if (this._savedStore.routeCartId) {
            page = (
                <PCSavedCartDetails/>
            );
        } else {
            page = (
                <div className='container page pc-saved'>
                    <div className='row'>
                        <PCContentHeader>
                            <FormattedMessage
                                defaultMessage='Saved For Later'
                                id='pc-saved.for-later'
                            />
                        </PCContentHeader>
                    </div>

                    <div className='row white-background section'>
                        <PCSavedCarts/>
                    </div>

                    <div className='row white-background section'>
                        <PCSavedWishlist/>
                    </div>
                </div>
            );
        }

        return (
            <Provider savedStore={this._savedStore} >
                {page}
            </Provider>
        );
    }
}

export default PCSaved;
