import _ from 'lodash';
import buildUrl from 'build-url';
import {fetchWithAuth, fetchJsonWithAuth} from './../fetch-json.util';
import camelCaseKeys from 'camelcase-keys-recursive';
import {aesUtil} from '../aes-util';

function mapAddressElements(addressElements) {
    return _.map(addressElements, (addressElementValue, addressElementId) => {
        return {
            'address-element-id': parseInt(addressElementId, 10),
            'address-element-value': addressElementValue
        };
    });
}

export default class OrdersService {
    /**
     * @param {string} [customerId] - id for a registered customer
     * @param {string} [guestEmail] - email for a guest
     * @param {string} localeCode - string locale
     * @param {string} [orderId] - id to get a single order
     * @param {string} [searchKey] - string to search orders by ID or part number
     * @param {Date} [startDate] - date to start at when requesting orders
     * @param {Date} [endDate] - date to end at when requesting orders
     * @return {*} order details if queryParams contain order-id,
     *  otherwise all abbreviated orders for customer
     */
    static getOrders(guestEmail, localeCode, orderId, searchKey, startDate, endDate) {
        return fetchJsonWithAuth(
            buildUrl(ORDERS_URL, {
                queryParams: {
                    'email': guestEmail,
                    'locale-cd': localeCode,
                    'order-id': aesUtil.aesEncrypt(orderId),
                    'search-key': searchKey,
                    'start-date': startDate ? startDate.toISOString() : undefined,
                    'end-date': endDate ? endDate.toISOString() : undefined
                }
            })
        ).then((data) => camelCaseKeys(data));
    }

    static postOrder(
        cartId,
        guestEmail,
        dealerId,
        localeCode,
        order,
        publicKey
    ) {
        const orderIp = {
            'cart-id': cartId,
            'purchase-order-number': order.purchaseOrderNumber,
            'special-instruction': order.specialInstructions,
            'coupon-code': order.couponCode,
            'shipping-method-id': order.shippingMethodId,
            'payment-method-id': order.paymentMethodId,
            'order-line': _.map(order.orderLine, (orderLine) => {
                return {
                    'part-number': orderLine.partNumber,
                    'quantity': orderLine.quantity,
                    'is-emergency-order-line': orderLine.isEmergencyOrderLine === true
                };
            }),
            'shipping-info': mapAddressElements(order.shippingInfo),
            'billing-info': mapAddressElements(order.billingInfo)
        };

        if (order.transactionInfo) {
            orderIp['transaction-info'] = {
                'public-key': publicKey,
                'payment-token': order.transactionInfo.paymentToken
            };
        }

        return fetchWithAuth(
            buildUrl(ORDERS_URL, {
                queryParams: {
                    'email': guestEmail,
                    'dealer-id': dealerId,
                    'locale-cd': localeCode
                }
            }),
            {
                method: 'POST',
                headers: {
                    'Content-Type': JD_JSON_CONTENT_TYPE_V1
                },
                body: JSON.stringify(orderIp)
            }
        ).then((response) => {
            if (response.ok) {
                return response;
            }

            return Promise.reject();
        });
    }

    static getTrackingInfo(localeCode, orderId) {
        return fetchJsonWithAuth(
            buildUrl(ORDER_STATUS_OPTIONS, {
                queryParams: {
                    'locale-cd': localeCode,
                    'order-id': orderId
                }
            })
        ).then((data) => camelCaseKeys(data));
    }
}
