import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber, FormattedMessage} from 'react-intl';
import './weight-display.component.scss';

const PCWeightDisplay = (
    {
        weight,
        inline,
        unit
    }
) => {
    let weightClassName = 'weight-display';
    let weightDisplayBody;

    if (inline) {
        weightClassName += ' inline';
    }

    if (!inline) {
        weightClassName += ' pull-right';
    }

    if (unit !== undefined && weight !== undefined && weight !== 0) {
        weightDisplayBody = (
            <React.Fragment>
                <div className='weight'>
                    <FormattedNumber
                        maximumFractionDigits={2}
                        minimumFractionDigits={2}
                        value={weight}
                    />
                </div>
                <div className='code text-right'>
                    {unit.toLocaleUpperCase()}
                </div>
            </React.Fragment>
        );
    } else {
        weightDisplayBody = (
            <FormattedMessage
                defaultMessage='Contact Your Dealer'
                id='pc.contact-your-dealer'
            />
        );
    }

    return (
        <div className={weightClassName}>
            {weightDisplayBody}
        </div>
    );
};

PCWeightDisplay.propTypes = {
    weight: PropTypes.number,
    inline: PropTypes.bool,
    unit: PropTypes.string
};

export default PCWeightDisplay;
