import React from 'react';
import PropTypes from 'prop-types';

const PCProfileDealer = ({
    profileDealer, buttonAction, buttonText
}) => {
    return (
        <React.Fragment>
            <div>
                {profileDealer.dealerName}
            </div>
            <div>
                {profileDealer.formattedAddressLine2 || profileDealer.formattedAddressLine1}
            </div>
            <button
                className='btn btn-hollow'
                onClick={buttonAction}
            >
                {buttonText}
            </button>
        </React.Fragment>
    );
};

PCProfileDealer.propTypes = {
    profileDealer: PropTypes.shape({
        dealerName: PropTypes.string.isRequired,
        formattedAddressLine1: PropTypes.string.isRequired,
        formattedAddressLine2: PropTypes.string
    }).isRequired,
    buttonAction: PropTypes.func,
    buttonText: PropTypes.node
};

export default PCProfileDealer;
