
import React from 'react';
import PropTypes from 'prop-types';

const PCPageLoading = ({standalone}) => {
    return (
        <div className={`progress-uxf${standalone ? ' standalone' : ''}`}>
            <div className='progress-circles-uxf progress-uxf-lg active'>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
};

PCPageLoading.propTypes = {
    standalone: PropTypes.bool
};

export default PCPageLoading;
