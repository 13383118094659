
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCCheckoutSummaryCart from './pc-checkout-summary-cart/pc-checkout-summary-cart.component';
import PCCheckoutSummaryShipping from './pc-checkout-summary-shipping/pc-checkout-summary-shipping.component';
import PCCheckoutSummaryPayment from './pc-checkout-summary-payment/pc-checkout-summary-payment.component';
import PCCheckoutSummaryTotals from './pc-checkout-summary-totals/pc-checkout-summary-totals.component';

import './pc-checkout-summary.component.scss';

@inject('checkoutStore', 'routerStore')
@observer
class PCCheckoutSummary extends React.Component {
    render() {
        return (
            <div className='checkout-summary'>
                <div className='header'>
                    <h3>
                        <FormattedMessage
                            defaultMessage='Order Summary'
                            id='pc-checkout.order-summary'
                        />
                    </h3>
                    <span className='item-count'>
                        <FormattedMessage
                            defaultMessage='({itemCount} Items)'
                            id='pc-checkout.item-count'
                            values={{
                                itemCount: this.props.checkoutStore.itemCount
                            }}
                        />
                    </span>
                </div>
                <PCCheckoutSummaryShipping/>
                <PCCheckoutSummaryCart/>
                <PCCheckoutSummaryPayment/>
                <PCCheckoutSummaryTotals/>
            </div>
        );
    }
}

export default PCCheckoutSummary;
