import _ from 'lodash';

import React from 'react';
import {action} from 'mobx';
import {inject, observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

import PCSearchInput from '../../shared/components/search-input/search-input.component';

import './pc-order-history-filter.component.scss';

@inject('ordersStore')
@observer
class PCOrderHistoryFilter extends React.Component {
    @action.bound
    onDateRangeChange(event) {
        this.props.ordersStore.dateRangeIndex = event.target.value;
    }

    @action.bound
    onSearch(searchKey) {
        this.props.ordersStore.searchKey = searchKey;
    }

    render() {
        const {
            dateRangeIndex,
            dateRanges,
            searchKey
        } = this.props.ordersStore;

        const dateRangesLastIndex = dateRanges.length - 1;

        return (
            <div className='pc-order-history-filter row'>
                <div className='col-md-12'>
                    <FormattedMessage
                        defaultMessage='Order or Part Number'
                        id='pc-order-history-filter.search-placeholder'
                    >
                        {
                            (message) =>
                                (<PCSearchInput
                                    placeholder={message}
                                    onSearch={this.onSearch}
                                    searchTerm={searchKey}
                                />)
                        }
                    </FormattedMessage>

                </div>
                <div className='col-md-12'>
                    <select
                        className='form-control'
                        name='dateRange'
                        onChange={this.onDateRangeChange}
                        value={dateRangeIndex}
                    >
                        {
                            _.map(dateRanges, (value, dateRangeDisplayIndex) => {
                                switch (dateRangeDisplayIndex) {
                                case 0:
                                    return (
                                        <FormattedMessage
                                            key={dateRangeDisplayIndex}
                                            defaultMessage='Last 30 Days'
                                            id='pc-order-history-filter.30-days'
                                        >
                                            {(message) => <option value={dateRangeDisplayIndex}>{message}</option>}
                                        </FormattedMessage>
                                    );
                                case 1:
                                    return (
                                        <FormattedMessage
                                            key={dateRangeDisplayIndex}
                                            defaultMessage='Last 6 Months'
                                            id='pc-order-history-filter.6-months'
                                        >
                                            {(message) => <option value={1}>{message}</option>}
                                        </FormattedMessage>
                                    );
                                case dateRangesLastIndex:
                                    return (
                                        <FormattedMessage
                                            key={dateRangeDisplayIndex}
                                            defaultMessage='Last 2 Years'
                                            id='pc-order-history-filter.2-years'
                                        >
                                            {(message) => <option value={dateRangesLastIndex}>{message}</option>}
                                        </FormattedMessage>
                                    );
                                default:
                                    return (
                                        <option
                                            key={dateRangeDisplayIndex}
                                            value={dateRangeDisplayIndex}
                                        >
                                            {dateRanges[dateRangeDisplayIndex].start.getFullYear()}
                                        </option>
                                    );
                                }
                            })
                        }
                    </select>
                </div>
            </div>
        );
    }
}

export default PCOrderHistoryFilter;
