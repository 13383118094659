import React from 'react';
import PCSavedWishlistTable from './pc-saved-wishlist-table.component';

import './pc-saved-wishlist.component.scss';

const PCSavedWishlist = () => {
    return (
        <PCSavedWishlistTable/>
    );
};

export default PCSavedWishlist;
