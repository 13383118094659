import _ from 'lodash';

import AnalyticsService from '../shared/services/analytics.service';
import OrdersService from '../shared/services/orders.service';

import {action, observable, reaction} from 'mobx';

class PCOrderCompleteStore {
    @observable _order;

    constructor(baseStore, routerStore) {
        this._baseStore = baseStore;
        this._routerStore = routerStore;
        this._reactions = [];

        this._reactions.push(reaction(
            () => {
                return {
                    guestEmail: _.get(this._baseStore.userSession, 'currentUser.idEmail'),
                    locale: this._baseStore.locale,
                    orderId: _.get(this._routerStore, 'route.params.orderId')
                };
            },
            ({
                guestEmail, locale, orderId
            }) => {
                if (!locale || !orderId ||
                    _.toString(orderId) === _.toString(_.get(this._order, 'orderId'))) {
                    return;
                }

                OrdersService.getOrders(
                    guestEmail,
                    locale,
                    orderId
                ).then(action((orders) => {
                    this._order = orders[0];
                })).catch(action(() => {
                    this._order = null;
                }));
            },
            {
                fireImmediately: true
            }
        ));

        this._reactions.push(reaction(
            () => this._order,
            (order) => {
                if (!order) {
                    return;
                }

                AnalyticsService.writeOrderPlacedEvent(
                    order.orderId,
                    order.totalOrderValue,
                    order.priceUnit,
                    order.shippingMethod,
                    order.paymentMethod,
                    _.map(order.orderLine, (orderLine) => {
                        return {
                            productInfo: {
                                baseCode: '',
                                partNumber: orderLine.partNumber,
                                price: orderLine.unitPrice,
                                quantity: orderLine.quantity
                            }
                        };
                    }),
                    order.dealerInfo.locationId,
                    _.get(this._baseStore.userSession, 'currentUser.id') ? 'registered' : 'guest'
                );
            }
        ));
    }

    destroy() {
        _.each(this._reactions, (disposeReaction) => {
            disposeReaction();
        });
    }

    get order() {
        return this._order;
    }
}

export default PCOrderCompleteStore;
