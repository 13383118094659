import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from './../fetch-json.util';

class PaymentMethodsService {
    static getPaymentMethods(
        countryCd,
        localeCd,
        dealerId,
        guestUser,
        shippingMethodId
    ) {
        return fetchJsonWithAuth(
            buildUrl(PAYMENT_METHODS_URL, {
                queryParams: {
                    'iso2-country-code': countryCd,
                    'locale-cd': localeCd,
                    'dealer-id': dealerId,
                    'guest-user': guestUser,
                    'shipping-method-id': shippingMethodId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default PaymentMethodsService;
