import _ from 'lodash';

import base from './modules/pc-base/pc-base.routes';
import checkout from './modules/pc-checkout/pc-checkout.routes';
import orders from './modules/pc-orders/pc-orders.routes';
import saved from './modules/pc-saved/pc-saved.routes';
import accountCreated from './modules/pc-account-created/pc-account-created.routes';
import orderComplete from './modules/pc-order-complete/pc-order-complete.routes';

export default _.concat(
    base,
    checkout,
    orders,
    saved,
    accountCreated,
    orderComplete
);
