
import React from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {inject} from 'mobx-react';

import LocaleMapperService from '../../shared/services/locale-mapper.service';

@inject('baseStore')
class PCIntlProvider extends React.Component {
    render() {
        const intlLocale = LocaleMapperService.reactIntl(this.props.baseStore.locale);

        return (
            <IntlProvider
                defaultLocale='en-US'
                locale={intlLocale}
                messages={this.props.baseStore.messages}
            >
                {this.props.children}
            </IntlProvider>
        );
    }
}

PCIntlProvider.propTypes = {
    children: PropTypes.element
};

export default PCIntlProvider;
