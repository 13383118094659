
export default [
    {
        children: [
            {
                name: 'leaving',
                path: '/leaving'
            },
            {
                name: 'review',
                path: '/review'
            },
            {
                name: 'shipping-method',
                path: '/shipping-method'
            },
            {
                name: 'shipping-info',
                path: '/shipping-info'
            },
            {
                name: 'payment-method',
                path: '/payment-method'
            },
            {
                name: 'payment-info',
                path: '/payment-info'
            },
            {
                name: 'confirm',
                path: '/confirm'
            }
        ],
        name: 'checkout',
        path: '/checkout',
        forwardTo: 'checkout.review',
        registeredUserRequired: false,
        dealerSelectorVisible: true,
        cartVisible: false,
        newPartSearchVisible: false,
        secureCheckoutVisible: true
    }
];
