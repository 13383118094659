
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import './large-error.component.scss';

const PCLargeError = ({children}) => {
    return (
        <div className='large-error'>
            <div className='error-icon'/>
            <div className='sorry-message'>
                <FormattedMessage
                    defaultMessage="We're sorry but the operation could not be completed."
                    id='pc.error-sorry'
                />
            </div>
            <div className='detail-message'>
                {children}
            </div>
        </div>
    );
};

PCLargeError.propTypes = {
    children: PropTypes.node.isRequired
};

export default PCLargeError;
