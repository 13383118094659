import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCPriceDisplay from '../../shared/components/price-display/price-display.component';

import './pc-new-parts-list.componet.scss';

@inject('baseStore')
@observer
class PCNewPartsList extends React.Component {
    render() {
        const currentCart = this.props.baseStore.currentCart;
        const currency = _.get(currentCart.cart, 'priceUnit');

        if (!currency || _.isEmpty(currentCart.cartNewParts)) {
            return null;
        }

        const parts = _.map(
            currentCart.cartNewParts,
            (cartLine) => {
                return (
                    <div
                        className='new-part'
                        key={cartLine.partNumber}
                    >
                        <div className='pull-right'>
                            <PCPriceDisplay
                                currency={currency}
                                value={cartLine.unitPrice * cartLine.quantityOrdered}
                                inline={true}
                            />
                        </div>
                        <h5>
                            {cartLine.partDescription || cartLine.partNumber}
                        </h5>
                        <div>
                            <FormattedMessage
                                defaultMessage='Part Number'
                                id='pc.part-number'
                            />
                            {`: ${cartLine.partNumber}`}
                        </div>
                        <div>
                            <FormattedMessage
                                defaultMessage='Quantity'
                                id='pc.quantity'
                            />
                            {`: ${cartLine.quantityOrdered}`}
                        </div>
                    </div>
                );
            }
        );

        return (
            <div className='new-parts-list'>
                {parts}
            </div>
        );
    }
}

export default PCNewPartsList;
