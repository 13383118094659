import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject} from 'mobx-react';

@inject('checkoutStore')
class PCCheckoutStepError extends React.Component {
    constructor(props) {
        super(props);

        this.goReview = this.goReview.bind(this);
    }

    goReview() {
        this.props.checkoutStore.activeCheckoutStep = 'review';
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className='row limit-width'>
                    <FormattedMessage
                        defaultMessage='Your order is incomplete.  Please review your order and provide all required fields.'
                        id='pc-checkout-step-confirm.missing-message'
                    />
                    <button
                        className='btn btn-primary btn-continue'
                        onClick={this.goReview}
                    >
                        <FormattedMessage
                            defaultMessage='Review Order'
                            id='pc-checkout-step-confirm.missing-button'
                        />
                    </button>
                </div>
            </div>
        );
    }
}

export default PCCheckoutStepError;
