const uxfCols = 24;

/**
 * Evenly distributes the number of columns over the number of items.
 * @param {Number} numItems Number of items to distribute the columns amongst, must be less than or equal to numCols.
 * @param {Number} [numCols = 24] How many columns we are working with, default 24 (number of cols in bootstrap)
 * @returns {Array | undefined} Array of length numItems with the columns per item or undefined if numItems > numCols.
 */
export function columnDistributor(numItems, numCols) {
    const numColsCalc = numCols !== undefined ? numCols : uxfCols;
    const output = [];

    if (numItems > numColsCalc) {
        return output;
    }

    let sum = 0;
    let amortized = 0;
    let i = 0;

    function u() {
        const real = numColsCalc / numItems + amortized;
        const natural = Math.floor(real);

        amortized = real - natural;

        return natural;
    }

    for (; i < numItems - 1; i++) {
        output[i] = u();
        sum += output[i];
    }

    output[i] = numColsCalc - sum;

    return output;
}
