import _ from 'lodash';

import {action, observable} from 'mobx';

import loadMicroform from '../shared/microform-loader.util';

class PCMicroform {
    constructor() {
        this.createToken = this.createToken.bind(this);
    }

    states = {
        LOADING: 'loading',
        READY: 'ready',
        ATTACHING: 'attaching',
        ATTACHED: 'attached',
        ERROR: 'error'
    };
    @observable _state = this.states.LOADING;
    @observable _isValid = false;

    @action.bound
    load() {
        this._state = this.states.LOADING;
        loadMicroform().then(
            action(() => {
                this._state = this.states.READY;
            }),
            action(() => {
                this._state = this.states.ERROR;
            })
        );
    }

    @action.bound
    attach(
        refInput,
        labelSelector,
        containerSelector,
        jwk
    ) {
        if (!refInput || this._microformInstance) {
            // already attached
            return;
        }

        this._state = this.states.ATTACHING;

        const refInputStyle = window.getComputedStyle(refInput);

        FLEX.microform(
            {
                keyId: jwk.kid,
                keystore: jwk,
                container: containerSelector,
                label: labelSelector,
                styles: {
                    input: {
                        'font-size': refInputStyle.fontSize,
                        'font-family': refInputStyle.fontFamily,
                        'line-height': refInputStyle.lineHeight,
                        'color': refInputStyle.color
                    }
                }
            },
            action((setupError, instance) => {
                if (setupError) {
                    this._state = this.states.ERROR;
                    return;
                }

                instance.on('validationChange', action((data) => {
                    this._isValid = data.valid;
                }));

                this._state = this.states.ATTACHED;
                this._microformInstance = instance;
            })
        );
    }

    @action.bound
    destroy() {
        if (this._microformInstance) {
            this._microformInstance.teardown(_.noop);
            this._microformInstance = undefined;
            this._state = this.states.READY;
            this._isValid = false;
        }
    }

    createToken(
        cardType,
        cardExpirationMonth,
        cardExpirationYear
    ) {
        if (!this._microformInstance) {
            return Promise.reject();
        }

        return new Promise((resolve, reject) => {
            this._microformInstance.createToken(
                {
                    cardType: cardType,
                    cardExpirationMonth: cardExpirationMonth,
                    cardExpirationYear: cardExpirationYear
                },
                (error, response) => {
                    if (error) {
                        reject();
                        return;
                    }

                    resolve(response);
                }
            );
        });
    }

    get state() {
        return this._state;
    }
    get isValid() {
        return this._isValid;
    }
}

export default PCMicroform;
