import {observable, action, reaction, computed, toJS} from 'mobx';
import _ from 'lodash';

import PCWishlist from './pc-wishlist.class';
import CartsService from '../shared/services/carts.service';

export default class PCSavedStore {
    @observable savedCarts;
    @observable detailedCart;

    constructor(baseStore, routerStore) {
        this._baseStore = baseStore;
        this._routerStore = routerStore;
        this._wishlist = new PCWishlist(baseStore, routerStore);

        this._reactions = [
            // load the cart or carts when it becomes possible to do so
            reaction(() => {
                return {
                    customerId: _.get(this._baseStore, 'userSession.currentUser.id'),
                    dealerId: _.get(this._baseStore, 'dealer.dealerId'),
                    locale: this._baseStore.locale,
                    cartId: this.routeCartId
                };
            }, ({
                customerId, dealerId, locale, cartId
            }) => {
                if (!(customerId && dealerId && locale)) {
                    return;
                }

                if (cartId) {
                    CartsService.getCarts(locale, cartId, dealerId)
                        .then(action((carts) => {
                            this.detailedCart = carts[0];
                        }))
                        .catch(action(() => {
                            this.detailedCart = null;
                        }));
                } else if (!cartId && _.isEmpty(this.savedCarts)) {
                    CartsService.getCarts(locale, undefined, dealerId)
                        .then(action((carts) => {
                            this.savedCarts = carts;
                        }))
                        .catch(action(() => {
                            this.savedCarts = null;
                        }));
                }
            }, {
                fireImmediately: true
            }),
            // delete stale detailedCart
            reaction(
                () => this.routeCartId,
                (cartId) => {
                    if (cartId) {
                        this.detailedCart = undefined;
                    }
                }
            ),
            // when the dealer ID changes delete the saved carts so they can be reloaded
            reaction(
                () => _.get(this._baseStore, 'dealer.dealerId'),
                () => {
                    this.savedCarts = undefined;
                }
            )
        ];
    }

    @action.bound
    deleteSavedCart(cart) {
        const originalSavedCarts = toJS(this.savedCarts);

        this.savedCarts.remove(cart);

        return CartsService.deleteCart(cart.cartId)
            .catch(action(() => {
                this.savedCarts = originalSavedCarts;
                return Promise.reject();
            }));
    }

    @action.bound
    updateCartLineItemQuantity(cart, lineItem, quantity) {
        const originalQuantity = lineItem.quantityOrdered;

        lineItem.quantityOrdered = quantity;

        return CartsService.updateCartLine(
            cart.cartId,
            _.get(this._baseStore.dealer, 'dealerId'),
            cart.cartName,
            lineItem
        ).catch(action((response) => {
            // failed, restore
            lineItem.quantityOrdered = originalQuantity;

            throw response;
        }));
    }

    @action.bound
    removeCartItem(cart, item) {
        const savedCart = _.find(this.savedCarts, {
            cartId: cart.cartId
        });
        const cartLineIndex = cart.cartLine.findIndex(_.matches(item));

        _.pullAt(cart.cartLine, cartLineIndex);
        cart.lineCount = cart.cartLine.length;

        if (savedCart) {
            savedCart.lineCount = cart.lineCount;
        }

        return CartsService.deleteCartLine(
            cart.cartId,
            _.get(this._baseStore.dealer, 'dealerId'),
            cart.cartName,
            item
        ).catch(action((response) => {
            // failed, restore
            cart.cartLine.splice(cartLineIndex, 0, item);
            cart.lineCount = cart.cartLine.length;

            if (savedCart) {
                savedCart.lineCount = cart.lineCount;
            }

            throw response;
        }));
    }

    @computed get routeCartId() {
        return this._routerStore.route.params.savedCartId;
    }

    get wishlist() {
        return this._wishlist;
    }

    destroy() {
        this._reactions.forEach((destroyReaction) => destroyReaction());
        this._wishlist.destroy();
    }
}
