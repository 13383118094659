import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCContentModal from '../../shared/components/content-modal/content-modal.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import RouteLink from '../../shared/components/route-link/route-link.component';
import PCNewPartsList from './pc-new-parts-list.component';
import PCNewPartsSubtotal from './pc-new-parts-subtotal.component';

@inject('baseStore')
@observer
class PCNewParts extends React.Component {
    render() {
        let title;
        let type;
        let errorMessage;
        let loadingIndicator;
        const currentCart = this.props.baseStore.currentCart;
        const buttonsDisabled = currentCart.addingToCart === true;

        if (!currentCart.addingToCart &&
            _.isEmpty(currentCart.cartNewParts)) {
            return null;
        }

        if (currentCart.addingToCart === true) {
            title = (
                <FormattedMessage
                    defaultMessage='Adding Parts to Your Cart'
                    id='pc-new-parts.title-loading'
                />
            );
            loadingIndicator = (
                <PCPageLoading/>
            );
        } else if (currentCart.addingToCart === false) {
            title = (
                <FormattedMessage
                    defaultMessage='{count, plural, one {{count} Part Added to Your Cart} other {{count} Parts Added to Your Cart}}'
                    id='pc-new-parts.title-success'
                    values={{
                        count: _.size(currentCart.cartNewParts)
                    }}
                />
            );
            type = 'success';
        } else {
            errorMessage = (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Parts were not added to your cart because you have reached our limit of {maxCartLine} lines of parts per order.  Please create a new order if you still wish to order this part.'
                        id='pc-new-parts.too-many-parts'
                        values={currentCart.addingToCart}
                    />
                </div>
            );
            title = (
                <FormattedMessage
                    defaultMessage='Parts Not Added to Your Cart'
                    id='pc-new-parts.title-fail'
                />
            );
            type = 'fail';
        }

        return (
            <PCContentModal
                type={type}
                isOpen={true}
                onRequestClose={currentCart.resetCartNewParts}
                buttons={buttonsDisabled ? null : (
                    <React.Fragment>
                        <button
                            className='btn btn-hollow'
                            onClick={currentCart.resetCartNewParts}
                        >
                            <FormattedMessage
                                defaultMessage='Continue Shopping'
                                id='pc-new-parts.continue-shopping'
                            />
                        </button>
                        <RouteLink
                            className='btn btn-primary'
                            onClick={currentCart.resetCartNewParts}
                            route='checkout'
                        >
                            <FormattedMessage
                                defaultMessage='Checkout Now'
                                id='pc-new-parts.checkout-now'
                            />
                        </RouteLink>
                    </React.Fragment>
                )}
                title={title}
            >
                <div className='new-parts'>
                    {loadingIndicator}
                    {errorMessage}
                    <PCNewPartsList/>
                    <PCNewPartsSubtotal/>
                </div>
            </PCContentModal>
        );
    }
}

export default PCNewParts;
