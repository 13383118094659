import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCPageLoading from '../../../shared/components/page-loading/page-loading.component';

@inject('checkoutStore')
@observer
class PCCheckoutStepShippingMethod extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.checkoutStore.shippingMethodId = event.target.value;
    }

    render() {
        const shippingMethods = this.props.checkoutStore.shippingMethods;

        if (shippingMethods === undefined) {
            return (
                <PCPageLoading/>
            );
        }

        if (shippingMethods === null) {
            return (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to load the shipping methods.'
                        id='pc-checkout-shipping-method.error'
                    />
                </div>
            );
        }

        const shippingMethodRows = _.map(
            shippingMethods,
            (shippingMethod) => {
                let shippingSpeed;

                if (shippingMethod.shippingMethodSpeed) {
                    shippingSpeed = (
                        <div className='secondary-info'>
                            <FormattedMessage
                                defaultMessage='{min} to {max} Business Days'
                                id='pc-checkout-shipping-method.speed'
                                values={shippingMethod.shippingMethodSpeed}
                            />
                        </div>
                    );
                }

                return (
                    <div
                        className='radio'
                        key={shippingMethod.shippingMethodId}
                    >
                        <label>
                            <input
                                checked={
                                    shippingMethod.shippingMethodId == this.props.checkoutStore.shippingMethodId // eslint-disable-line eqeqeq
                                }
                                name='shipping-method'
                                onChange={this.onChange}
                                type='radio'
                                value={shippingMethod.shippingMethodId}
                            />
                            <span/>
                            {shippingMethod.shippingMethodText}
                            {shippingSpeed}
                        </label>
                    </div>
                );
            }
        );

        return (
            <div className='checkout-step-shipping-method container-fluid'>
                <h5 className='row'>
                    <FormattedMessage
                        defaultMessage='Choose your shipping method'
                        id='pc.checkout-shipping-method.choose'
                    />
                </h5>
                <div className='row'>
                    {shippingMethodRows}
                </div>
                <div className='row limit-width'>
                    <button
                        className='btn btn-primary btn-continue'
                        disabled={this.props.checkoutStore.shippingMethodId === null}
                        onClick={this.props.checkoutStore.activateNextStep}
                    >
                        <FormattedMessage
                            defaultMessage='Continue'
                            id='pc.continue'
                        />
                    </button>
                </div>
            </div>
        );
    }
}

export default PCCheckoutStepShippingMethod;
