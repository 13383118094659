import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';
import PCRouteLink from '../../shared/components/route-link/route-link.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import PCLargeError from '../../shared/components/large-error/large-error.component';
import PCSavedCartDetailsLineItem from './pc-saved-cart-details-line-item.component';

import './pc-saved-cart-details.component.scss';
@inject('savedStore', 'baseStore')
@observer
export default class PCSaveCartDetails extends React.Component {
    handleAddAllToCart = () => {
        this.props.baseStore.currentCart.addCartByIdToCart(this.props.savedStore.detailedCart.cartId);
    };

    render() {
        const {detailedCart: cart} = this.props.savedStore;

        if (this.props.savedStore.detailedCart === undefined) {
            return (<PCPageLoading standalone={true}/>);
        }

        if (this.props.savedStore.detailedCart === null) {
            return (
                <PCLargeError>
                    <FormattedMessage
                        id='pc.saved-detailed-cart.cart-load-error'
                        defaultMessage='Unable to load the cart details.'
                    />
                </PCLargeError>
            );
        }

        return (
            <div className='container page pc-saved-cart-details'>

                <div className='row navigation'>
                    <PCRouteLink route={'saved'} >
                        <FormattedMessage
                            id='pc-saved-cart-details.back-to-saved'
                            defaultMessage='Back to Saved for Later'
                        />
                    </PCRouteLink>
                </div>

                <div className='row cart-title-row'>
                    <div className='col-md-12 cart-name'>
                        <h2>{`${cart.cartName} (${cart.cartLine.length})`}</h2>
                    </div>

                    <div className='col-md-12 cart-total'>
                        <FormattedMessage
                            id='pc-saved.header-total'
                            defaultMessage='Total {price}'
                            values={{
                                price: (<PCPriceDisplay
                                    currency={cart.priceUnit}
                                    value={cart.cartValue}
                                    inline={true}
                                />)
                            }}
                        />

                        <button
                            className='btn btn-primary'
                            onClick={this.handleAddAllToCart}
                        >
                            <FormattedMessage
                                id='pc.add-all-to-cart'
                                defaultMessage='Add All to Cart'
                            />
                        </button>
                    </div>
                </div>

                <div className='row white-background line-items'>

                    <div className='row hidden-xs hidden-sm line-items-headers'>

                        <div className='col-md-10'>
                            <FormattedMessage
                                id='pc.parts'
                                defaultMessage='Parts'
                            />
                        </div>

                        <div className='col-md-6 text-center'>
                            <FormattedMessage
                                id='pc.quantity'
                                defaultMessage='Quantity'
                            />
                        </div>

                        <div className='col-md-4 text-right'>
                            <FormattedMessage
                                id='pc.unit-price'
                                defaultMessage='Unit Price'
                            />
                        </div>

                        <div className='col-md-4 text-right'>
                            <FormattedMessage
                                id='pc.total-price'
                                defaultMessage='Total Price'
                            />
                        </div>
                    </div>

                    {
                        cart.cartLine.map((item, index) => (
                            <PCSavedCartDetailsLineItem
                                key={item.partNumber}
                                lineItem={item}
                                index={index + 1}
                            />)
                        )
                    }

                </div>
            </div>
        );
    }
}
