import React from 'react';
import PropTypes from 'prop-types';

const PCOrderDetailsInformationItem = ({
    messageComponent, dataOrderedArray
}) => {
    const data = mapArrayToDivs(dataOrderedArray);

    return (
        <div className='information-item'>
            <h5>{messageComponent}</h5>
            <div className='data'>{data}</div>
        </div>
    );
};

function mapArrayToDivs(elementsArray) {
    return _.map(elementsArray, (line, index) => {
        return (
            <div key={index}>{line}</div>
        );
    });
}

PCOrderDetailsInformationItem.propTypes = {
    messageComponent: PropTypes.element.isRequired,
    dataOrderedArray: PropTypes.array
};

export default PCOrderDetailsInformationItem;

