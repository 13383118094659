import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';
import PCQuantitySpinner from '../../shared/components/quantity-spinner/quantity-spinner.component';

@inject('savedStore', 'baseStore')
@observer
export default class PCSavedWishlistPart extends React.Component {
    handleAddToPartToCart = () => {
        const {part} = this.props;

        this.props.baseStore.currentCart.addPartByIdToCart(
            part.partNumber,
            part.quantity,
            'Saved Part'
        );
    };

    handleDeleteWishlistPart = () => {
        this.props.savedStore.wishlist.deleteWishlistPart(this.props.part)
            .catch(() => {
                this.props.baseStore.showFailAlert(
                    <FormattedMessage
                        id='pc-saved-wishlist.failed-delete'
                        defaultMessage='Unable to Delete "{partDescription}"'
                        values={this.props.part}
                    />
                );
            });
    };

    render() {
        const {part} = this.props;

        return (
            <div className='row part-row'>
                <div className='col-lg-4 visible-lg part-cell'>
                    {part.partNumber}
                </div>

                <div className='col-lg-4 part-cell part-name-cell'>
                    <div className='hidden-lg pull-right price-small-block'>
                        <div className='price-container'>
                            <div className='title-aside'>
                                <FormattedMessage
                                    defaultMessage='Unit Price'
                                    id='pc.unit-price'
                                />
                            </div>
                            <PCPriceDisplay
                                currency={part.priceUnit}
                                value={part.unitPrice}
                                inline={true}
                            />
                        </div>

                        <div className='price-container total'>
                            <div className='title-aside'>
                                <FormattedMessage
                                    defaultMessage='Total Price'
                                    id='pc.total-price'
                                />
                            </div>
                            <PCPriceDisplay
                                currency={part.priceUnit}
                                value={part.unitPrice * part.quantity}
                                inline={true}
                            />
                        </div>
                    </div>

                    <div className='description'>
                        {part.partDescription || part.partNumber}
                    </div>

                    <div className='hidden-lg'>
                        <FormattedMessage
                            defaultMessage='Part Number'
                            id='pc.part-number'
                        />
                        {' '}
                        <span>{part.partNumber}</span>
                    </div>
                </div>

                <div className='col-lg-4 part-cell quantity'>
                    <PCQuantitySpinner
                        onChange={_.partial(this.props.savedStore.wishlist.updateWishlistPartQuantity, part)}
                        quantity={part.quantity}
                    />
                </div>

                <div className='col-lg-3 visible-lg part-cell money-cell'>
                    <PCPriceDisplay
                        currency={part.priceUnit}
                        value={part.unitPrice}
                    />
                </div>

                <div className='col-lg-3 visible-lg part-cell money-cell total'>
                    <PCPriceDisplay
                        currency={part.priceUnit}
                        value={part.unitPrice * part.quantity}
                    />
                </div>

                <div className='col-lg-6 part-cell part-add-cell text-right'>
                    <button
                        className='delete-button'
                        onClick={this.handleDeleteWishlistPart}
                    />
                    <button
                        className='btn btn-primary'
                        onClick={this.handleAddToPartToCart}
                    >
                        <FormattedMessage
                            id='pc.add-to-cart'
                            defaultMessage='Add to Cart'
                        />
                    </button>
                </div>
            </div>
        );
    }
}

PCSavedWishlistPart.propTypes = {
    part: PropTypes.shape({
        partDescription: PropTypes.string,
        partNumber: PropTypes.string.isRequired,
        unitPrice: PropTypes.number,
        priceUnit: PropTypes.string,
        quantity: PropTypes.number.isRequired
    })
};
