import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {action, observable, reaction, toJS} from 'mobx';
import {observer} from 'mobx-react';

import {columnDistributor} from '../../shared/column-distributor.util';

const knownLabels = {
    '1001': (
        <FormattedMessage
            defaultMessage='First Name'
            id='pc-checkout-address-form.first-name'
        />
    ),
    '1002': (
        <FormattedMessage
            defaultMessage='Last Name'
            id='pc-checkout-address-form.last-name'
        />
    ),
    '1003': (
        <FormattedMessage
            defaultMessage='Country'
            id='pc-checkout-address-form.country'
        />
    )
};

const typeMap = {
    'telephone': 'tel',
    'email': 'email',
    'textbox': 'text'
};

@observer
class PCCheckoutAddressForm extends React.Component {
    constructor(props) {
        super(props);

        this._reactions = [];
        this._internalChange = false;

        this._reactions.push(reaction(
            // need to access all values so that we get updates if they are updated in-place
            () => _.map(this.props.address, (addressValue) => {
                return addressValue;
            }),
            () => {
                if (this._internalChange) {
                    this._internalChange = false;
                    return;
                }

                this._address = toJS(this.props.address);
            },
            {
                fireImmediately: true
            }
        ));
    }

    @observable _address = {};

    @action.bound
    onChange(event) {
        this._internalChange = true;
        this._address[event.target.name] = event.target.value;

        if (event.target.checkValidity()) {
            this.props.address[event.target.name] = event.target.value;
        } else {
            this.props.address[event.target.name] = '';
        }
    }

    render() {
        const groupedByRow = _.groupBy(this.props.addressElements, 'rowIndex');
        const addressInputs = _.chain(groupedByRow)
            .keys()
            .map((rowIndex) => parseInt(rowIndex, 10))
            .sortBy()
            .map((rowIndex) => {
                const columnElements = _.sortBy(groupedByRow[rowIndex], 'columnIndex');
                const columnWidths = columnDistributor(_.size(columnElements));
                const columns = _.map(columnElements, (addressElement, colIndex) => {
                    let optionalIndicator;

                    if (!addressElement.mandatoryCode) {
                        optionalIndicator = (
                            <span>
                                {' ('}
                                <FormattedMessage
                                    defaultMessage='Optional'
                                    id='pc.optional'
                                />
                                {')'}
                            </span>
                        );
                    }

                    let addressElementLabel = knownLabels[addressElement.addressElementId];

                    if (!addressElementLabel) {
                        addressElementLabel = addressElement.addressElementLabel;
                    }

                    let addressElementInput;

                    switch (addressElement.addressElementDisplayType) {
                    case 'readonly':
                        addressElementInput = (
                            <div className='read-only-form-control'>
                                {this.props.address[addressElement.addressElementId]}
                            </div>
                        );
                        break;
                    case 'email':
                    case 'telephone':
                    case 'textbox':
                        addressElementInput = (
                            <input
                                name={addressElement.addressElementId}
                                value={this._address[addressElement.addressElementId]}
                                onChange={this.onChange}
                                className='form-control'
                                type={typeMap[addressElement.addressElementDisplayType]}
                                disabled={this.props.disabled === true}
                            />
                        );
                        break;
                    case 'dropdown':
                        addressElementInput = (
                            <select
                                name={addressElement.addressElementId}
                                value={this._address[addressElement.addressElementId]}
                                onChange={this.onChange}
                                className='form-control'
                                disabled={this.props.disabled === true}
                            >
                                <option value=''/>
                                {
                                    _.map(addressElement.addressElementList, (listItem, index) => {
                                        return (
                                            <option
                                                key={index}
                                                value={listItem}
                                            >
                                                {listItem}
                                            </option>
                                        );
                                    })
                                }
                            </select>
                        );
                        break;
                    default:
                        // unknown type
                        addressElementInput = null;
                    }

                    return (
                        <div
                            className={`form-group col-md-${columnWidths[colIndex]}`}
                            key={colIndex}
                        >
                            <label>
                                {addressElementLabel} {optionalIndicator}
                                {addressElementInput}
                            </label>
                        </div>
                    );
                });

                return (
                    <div
                        className='row'
                        key={rowIndex}
                    >
                        {columns}
                    </div>
                );
            })
            .flatten()
            .value();

        return (
            <div className='checkout-address-form'>
                {addressInputs}
            </div>
        );
    }
}

PCCheckoutAddressForm.propTypes = {
    address: PropTypes.object.isRequired,
    addressElements: PropTypes.object.isRequired,
    disabled: PropTypes.bool
};

export default PCCheckoutAddressForm;
