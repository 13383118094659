import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCPriceDisplay from '../../shared/components/price-display/price-display.component';

import './pc-new-parts-subtotal.component.scss';

@inject('baseStore')
@observer
class PCNewPartsSubtotal extends React.Component {
    render() {
        const currentCart = this.props.baseStore.currentCart;
        const currency = _.get(currentCart.cart, 'priceUnit');

        if (!currency || currentCart.addingToCart) {
            return null;
        }

        const totalPrice = _.reduce(
            _.get(currentCart.cart, 'cartLine'),
            (total, cartLine) => {
                if (_.isFinite(cartLine.unitPrice)) {
                    return total + cartLine.quantityOrdered * cartLine.unitPrice;
                }

                return total;
            },
            0
        );

        return (
            <div className='new-parts-subtotal'>
                <div className='subtotal'>
                    <FormattedMessage
                        defaultMessage='Cart Subtotal'
                        id='pc-new-parts.cart-subtotal'
                    />
                    {`(${_.size(currentCart.cart.cartLine)}): `}
                    <PCPriceDisplay
                        currency={currency}
                        value={totalPrice}
                        inline={true}
                    />
                </div>
                <div className='dealer-message'>
                    <FormattedMessage
                        defaultMessage='Pricing and availability will be confirmed by your dealer.'
                        id='pc-new-parts.dealer-message'
                    />
                </div>
            </div>
        );
    }
}

export default PCNewPartsSubtotal;
