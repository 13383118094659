import _ from 'lodash';
import buildUrl from 'build-url';

export function makePartsCatalogUrl(baseStore) {
    return buildUrl(PARTS_CATALOG_URL, {
        queryParams: {
            'locale': baseStore.locale,
            'country-id': baseStore.countryCode,
            'dealer-id': _.get(baseStore, 'dealer.dealerId'),
            'cart-id': _.get(baseStore, 'currentCart.cart.cartId')
        }
    });
}
