const CryptoJS = require('crypto-js');

export class AESUtil {

    constructor() {
        this._keySize = 256;
        this._iterationCount = 1989;
        this._passPhraseKey = AES_SECRET_KEY;
        this._IV = "7CC6DA52EA04643DFD1DCBBB675208AF";
    }

    get keySize() {
        return this._keySize;
    }

    set keySize(value) {
        this._keySize = value;
    }

    get iterationCount() {
        return this._iterationCount;
    }

    set iterationCount(value) {
        this._iterationCount = value;
    }

    generateKey(salt, passPhrase) {
        return CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
            keySize: this.keySize / 32,
            iterations: this._iterationCount
        })
    }

    encryptWithIvSalt(salt, passPhrase, plainText) {
        let key = this.generateKey(salt, passPhrase);
        let encrypted = CryptoJS.AES.encrypt(plainText, key, {
            iv: CryptoJS.enc.Hex.parse(this._IV)
        });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64url);
    }

    decryptWithIvSalt(salt, passPhrase, cipherText) {
        let key = this.generateKey(salt, passPhrase);
        let cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64url.parse(cipherText)
        });
        let decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
            iv: CryptoJS.enc.Hex.parse(this._IV)
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    encrypt(passPhrase, plainText) {
        let salt = CryptoJS.lib.WordArray.random(this.keySize / 16).toString(CryptoJS.enc.Hex);
        let ciphertext = this.encryptWithIvSalt(salt, passPhrase, plainText);
        return salt + ciphertext;
    }

    decrypt(passPhrase, cipherText) {
        let salt = cipherText.substr(0, 32);
        let encrypted = cipherText.substring(32);
        return this.decryptWithIvSalt(salt, passPhrase, encrypted);
    }

    aesEncrypt(plainText) {
        if ( plainText === undefined || plainText === '') {
            return plainText;
        }else if( typeof plainText !== 'string'){
            plainText=plainText.toString();
        }
        return this.encrypt(this._passPhraseKey, plainText);
    }

    aesDecrypt(cipherText) {
        if ( cipherText === undefined || cipherText === '') {
            return cipherText;
        }
        return this.decrypt(this._passPhraseKey, cipherText);
    }
}

export const aesUtil = new AESUtil();
