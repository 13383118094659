let loadedMicroform = false;

export default function loadMicroform() {
    if (loadedMicroform) {
        return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
        const scriptElement = document.createElement('script');

        scriptElement.addEventListener('load', () => {
            loadedMicroform = true;
            resolve();
        });
        scriptElement.addEventListener('error', reject);
        scriptElement.src = MICROFORM_SRC_URL;
        scriptElement.async = true;

        document.head.appendChild(scriptElement);
    });
}
