import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCFooterItem from './pc-footer-item.component';

import './pc-footer.component.scss';

@inject('baseStore','checkoutStore')
@observer
class PCFooter extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        let footerRows;
        let dealerDisclaimer;

        if (this.props.baseStore.footerInfo) {
            const footerInfo = _.chain(this.props.baseStore.footerInfo)
                .groupBy('section')
                .mapValues((groupedSection) => {
                    return groupedSection[0];
                })
                .value();

            footerRows = (
                <React.Fragment>
                    <div className='footer-links'>
                        <PCFooterItem item={footerInfo.home}/>
                        <PCFooterItem item={footerInfo.contactus}/>
                        <PCFooterItem item={footerInfo.cookie}/>
                        <PCFooterItem item={footerInfo.privacy}/>
                        <PCFooterItem item={footerInfo.legal}/>
                    </div>
                    <div className='footer-legal'>
                        <PCFooterItem item={footerInfo.copyright1}/>
                        {' '}
                        <PCFooterItem item={footerInfo.copyright2}/>
                    </div>
                </React.Fragment>
            );
        }

        if (!this.props.baseStore.loginVisible) {
            let disclaimerMessage=(
                <FormattedMessage id="pc.default-disclaimer"
                                  defaultMessage="Prices are subject to change. Taxes and freight charges are not included." />
            );
            if(this.props.checkoutStore._currentOrder.estimatedShippingCharge!=null
                && this.props.checkoutStore._currentOrder.estimatedSalesTax!=null){
                disclaimerMessage=(
                    <FormattedMessage id="pc.price-sub-2-change"
                                      defaultMessage="Prices are subject to change." />
                );
            }else if(this.props.checkoutStore._currentOrder.estimatedShippingCharge!=null){
                disclaimerMessage=(
                    <FormattedMessage id="pc.taxes-not-included"
                                      defaultMessage="Prices subject to change. Taxes are not included." />
                );
            }else if(this.props.checkoutStore._currentOrder.estimatedSalesTax!=null){
                disclaimerMessage=(
                    <FormattedMessage id="pc.shipping-not-included"
                                      defaultMessage="Prices subject to change. Freight charges are not included." />
                );
            }
            dealerDisclaimer = (
                <div className='dealer-disclaimer'>
                    <div className='container page'>
                        <div className='content'>

                            <div className='message'>
                            </div>
                            <div className='link'>
                                <button
                                    className='btn btn-hollow'
                                    onClick={this.props.baseStore.dealerPolicies.show}
                                >
                                    <FormattedMessage
                                        defaultMessage='Dealer Policies'
                                        id='pc.dealer-policies'
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <footer className='pc-footer footer-uxf'>
                {dealerDisclaimer}
                <div className='pc-footer-content container'>
                    {footerRows}
                </div>
            </footer>
        );
    }
}

export default PCFooter;
