import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {action, reaction, observable} from 'mobx';
import {inject, observer} from 'mobx-react';

import PCContentModal from '../../shared/components/content-modal/content-modal.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import RouteLink from '../../shared/components/route-link/route-link.component';

import './pc-checkout-save-cart.component.scss';

@inject('baseStore', 'checkoutStore')
@observer
class PCCheckoutSaveCart extends React.Component {
    constructor(props) {
        super(props);
        this._reactions = [];

        // when the dialog becomes visible populate the cart name
        this._reactions.push(reaction(
            () => this.props.checkoutStore.saveDialogVisible,
            (saveDialogVisible) => {
                if (saveDialogVisible) {
                    this._saveFailed = false;
                    this._cartName = this.props.baseStore.currentCart.cart.cartName;
                }
            }
        ));
    }

    componentWillUnmount() {
        _.each(this._reactions, (disposeReaction) => {
            disposeReaction();
        });
    }

    @observable _saveFailed = false;
    @observable _saving = false;
    @observable _cartName = '';

    @action.bound
    onNameChange(event) {
        this._cartName = event.target.value;
    }

    @action.bound
    saveCart() {
        this._saving = true;
        this.props.checkoutStore.saveCart(
            this._cartName,
            this._saveFailed !== false
        ).then(action((response) => {
            this._saveFailed = false;
            this._saving = false;

            if (response.maxCart) {
                this._saveFailed = response;
            } else {
                this.props.baseStore.showSuccessAlert((
                    <FormattedMessage
                        defaultMessage='Your cart has been saved successfully!'
                        id='pc-checkout-save.save-success'
                    />
                ));
                this.props.checkoutStore.hideSaveDialog();
            }
        })).catch(action(() => {
            this._saveFailed = true;
            this._saving = false;
        }));
    }

    render() {
        let modalBody;
        let cancelButton;

        if (this._saving) {
            modalBody = (
                <PCPageLoading/>
            );
        } else {
            let statusMessage;

            if (this._saveFailed === true) {
                statusMessage = (
                    <div className='alert alert-warning'>
                        <FormattedMessage
                            defaultMessage='Unable to save the cart.'
                            id='pc-checkout-save-cart.save-failed'
                        />
                    </div>
                );
            } else if (this._saveFailed !== false) {
                statusMessage = (
                    <React.Fragment>
                        <div className='alert alert-danger'>
                            <FormattedMessage
                                defaultMessage='You have reached the limit of {maxCart} saved carts.  Saving this cart will erase your oldest saved cart.'
                                id='pc-checkout-save-cart.too-many-error'
                                values={this._saveFailed}
                            />
                        </div>
                        <div className='dialog-message-link-block'>
                            <FormattedMessage
                                defaultMessage='If you would like to choose which cart is deleted, please visit your {linkListSavedCarts}.'
                                id='pc-checkout-save-cart.too-many-choose'
                                values={{
                                    linkListSavedCarts: (
                                        <RouteLink
                                            route='saved'
                                            onClick={this.props.checkoutStore.hideSaveDialog}
                                        >
                                            <FormattedMessage
                                                defaultMessage='list of saved carts'
                                                id='pc-checkout-save-cart.too-many-link-list-saved-carts'
                                            />
                                        </RouteLink>
                                    )
                                }}
                            />
                        </div>
                    </React.Fragment>
                );

                cancelButton = (
                    <button
                        className='btn btn-hollow'
                        onClick={this.props.checkoutStore.hideSaveDialog}
                    >
                        <FormattedMessage
                            defaultMessage='Cancel'
                            id='pc.cancel'
                        />
                    </button>
                );
            }

            modalBody = (
                <React.Fragment>
                    {statusMessage}
                    <div className='form-group'>
                        <label>
                            <FormattedMessage
                                defaultMessage='Cart Name'
                                id='pc.cart-name'
                            />
                            <input
                                className='form-control'
                                type='text'
                                value={this._cartName}
                                onChange={this.onNameChange}
                            />
                        </label>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <button
                    className='btn btn-hollow'
                    onClick={this.props.checkoutStore.showSaveDialog}
                >
                    <FormattedMessage
                        defaultMessage='Save Cart'
                        id='pc-checkout-save-cart.save-cart'
                    />
                </button>
                <PCContentModal
                    isOpen={this.props.checkoutStore.saveDialogVisible}
                    onRequestClose={this.props.checkoutStore.hideSaveDialog}
                    title={(
                        <FormattedMessage
                            defaultMessage='Save Cart'
                            id='pc-checkout-save-cart.save-cart'
                        />
                    )}
                    buttons={(
                        <React.Fragment>
                            {cancelButton}
                            <button
                                className='btn btn-primary'
                                disabled={_.isEmpty(this._cartName) || this._saving}
                                onClick={this.saveCart}
                            >
                                <FormattedMessage
                                    defaultMessage='Save'
                                    id='pc.save'
                                />
                            </button>
                        </React.Fragment>
                    )}
                >
                    <div className='checkout-save-cart'>
                        {modalBody}
                    </div>
                </PCContentModal>
            </React.Fragment>
        );
    }
}

export default PCCheckoutSaveCart;
