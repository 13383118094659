import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

const ItemsHeader = ({lineCount = 0, taxType, showTaxPerLineitem = false, taxPercentage }) => {
    return (
        <thead>
            <tr className='border-bottom'>
                <th>
                    <FormattedMessage
                        defaultMessage='Items ({count})'
                        id='pc.order-details.title-items'
                        values={{
                            count: lineCount
                        }}
                    />
                </th>
                <th style={{textAlign: 'right'}}>
                    <FormattedMessage
                        defaultMessage='Unit Weight'
                        id='pc.weight'
                    />
                </th>
                <th style={{textAlign: 'center'}}>
                    <FormattedMessage
                        defaultMessage='Quantity'
                        id='pc.quantity'
                    />
                </th>
                <th style={{textAlign: 'center'}}>
                    <FormattedMessage
                        defaultMessage='Order Type'
                        id='pc.order-type'
                    />
                </th>
                <th style={{textAlign: 'right'}}>
                    <FormattedMessage
                        defaultMessage='Unit Net Price'
                        id='pc.unit-net-price'
                    />
                </th>
                <th style={{textAlign: 'right'}}>
                    <FormattedMessage
                        defaultMessage='Total Net Price'
                        id='pc.total-net-price'
                    />
                </th>
                <th style={{textAlign: 'right', display: showTaxPerLineitem ? 'block' : 'none'}}>
                    <FormattedMessage
                        defaultMessage='Total incl. {taxType} ({taxPercentage}%)'
                        id='pc.total-incl-tax'
                        values={{
                            taxType: taxType,
                            taxPercentage: taxPercentage
                        }}
                    />
                </th>
            </tr>

        </thead>

    );
};

ItemsHeader.propTypes = {
    lineCount: PropTypes.number,
    taxType: PropTypes.string,
    showTaxPerLineitem: PropTypes.bool,
    taxPercentage: PropTypes.number
};

export default ItemsHeader;
