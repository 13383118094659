import buildUrl from 'build-url';
import {fetchTextWithTempToken, makeAppIdConfig} from './../fetch-json.util';

class GuestUserService {
    static userExists(email) {
        return fetchTextWithTempToken(
            buildUrl(CUSTOMER_VALIDATION_URL, {
                queryParams: {
                    'email-id': email
                }
            }),
            makeAppIdConfig()
        ).then((responseText) => {
            return responseText === email;
        });
    }

    static getLoginToken(email) {
        return fetchTextWithTempToken(
            buildUrl(LOGIN_TOKEN_URL, {
                queryParams: {
                    'email-id': email
                }
            }),
            makeAppIdConfig()
        );
    }
}

export default GuestUserService;
