import _ from 'lodash';

import {action, comparer, computed, observable, reaction, toJS} from 'mobx';

import CartsService from '../shared/services/carts.service';
import AnalyticsService from '../shared/services/analytics.service';

class PCCart {
    @observable _loadingCart = false;
    @observable _addingToCart = false;
    @observable _cart;
    @observable _cartNewParts;
    @observable _cartReloaded = false;

    constructor(baseStore, routerStore) {
        this._baseStore = baseStore;
        this._routerStore = routerStore;

        this.addCartByIdToCart = this.addCartByIdToCart.bind(this);
        this.addOrderByIdToCart = this.addOrderByIdToCart.bind(this);
        this.addPartByIdToCart = this.addPartByIdToCart.bind(this);
        this._makeAnalyticsAddPartFromCartLine = this._makeAnalyticsAddPartFromCartLine.bind(this);
        this._makeAnalyticsRemovePartFromCartLine = this._makeAnalyticsRemovePartFromCartLine.bind(this);

        // get the cart when it becomes possible to do so
        reaction(
            () => {
                return {
                    locale: baseStore.locale,
                    cartId: _.get(routerStore, 'route.params.cart-id'),
                    dealerId: _.get(this._baseStore.dealer, 'dealerId'),
                    customerId: _.get(this._baseStore.userSession.currentUser, 'id'),
                    hasCustomer: _.get(this._baseStore.userSession.currentUser, 'id') || _.get(this._baseStore.userSession.currentUser, 'idEmail')
                };
            },
            (cartParameters) => {
                if (!(cartParameters.locale &&
                    cartParameters.cartId &&
                    cartParameters.dealerId &&
                    cartParameters.hasCustomer) ||
                    this._loadingCart) {
                    return;
                }
                this._loadingCart = true;
                CartsService.getCarts(
                    cartParameters.locale,
                    cartParameters.cartId,
                    cartParameters.dealerId
                ).then(
                    action((carts) => {
                        if (carts && _.size(carts) === 1) {
                            this._cart = carts[0];
                        }
                        this._cartReloaded = true;
                        this._loadingCart = false;
                    })
                ).catch(
                    action(() => {
                        this._baseStore.showCriticalError();
                        this._cart = null;
                        this._loadingCart = false;
                        this._cartReloaded = false;
                    })
                );
            },
            {
                fireImmediately: true,
                equals: comparer.structural
            }
        );
    }

    get loadingCart() {
        return this._loadingCart;
    }
    get addingToCart() {
        return this._addingToCart;
    }
    get cart() {
        return this._cart;
    }
    get cartReloaded() {
        return this._cartReloaded;
    }
    @computed get itemCount() {
        return _.size(_.get(this._cart, 'cartLine'));
    }
    @action.bound
    newCart(navigate) {
        this.resetCartNewParts();
        this._loadingCart = false;
        this._cart = undefined;

        if (navigate) {
            this._routerStore.router.navigate(
                this._routerStore.route.name,
                _.omit(this._routerStore.route.params, ['cart-id'])
            );
        }
    }
    get cartNewParts() {
        return this._cartNewParts;
    }
    @action.bound
    resetCartNewParts() {
        this._addingToCart = false;
        if (this._cartNewParts) {
            this._cartNewParts.clear();
        }
    }

    @action.bound
    saveCartWithCheckoutOrder(order, name, update) {
        let dirty = false;

        if (name) {
            dirty = true;
            this._cart.cartName = name;
        }

        if (this._cart.cartLine.length !== order.orderLine.length) {
            dirty = true;

            // only keep cart lines that are found in the order
            _.remove(this._cart.cartLine, (cartLine) => {
                return _.find(order.orderLine, {
                    partNumber: cartLine.partNumber
                }) === undefined;
            });
        }

        // copy the quantity from the order into the cart
        _.each(order.orderLine, (orderLine) => {
            const cartLine = _.find(this._cart.cartLine, {
                partNumber: orderLine.partNumber
            });

            if (cartLine.quantityOrdered !== orderLine.quantity) {
                dirty = true;
                cartLine.quantityOrdered = orderLine.quantity;
            }
        });

        if (!dirty) {
            // no changes
            return Promise.resolve();
        }

        // Intentionally not passing the current cart ID here to POST, unless update is specified.
        // Want to make the saved cart a copy of the current cart,
        // not convert the current cart into a saved cart.
        return CartsService.uploadCartLines(
            update ? this._cart.cartId : undefined,
            this._baseStore.dealer.dealerId,
            toJS(this._cart.cartName),
            toJS(this._cart.cartLine),
            undefined,
            undefined,
            this._baseStore.locale
        );
    }

    @action.bound
    updateCartLineWithOrderLine(orderLine) {
        const cartLine = _.find(this._cart.cartLine, {
            partNumber: orderLine.partNumber
        });
        const originalCartLine = toJS(cartLine);

        cartLine.quantityOrdered = orderLine.quantity;
        cartLine.isEmergencyOrderLine = orderLine.isEmergencyOrderLine;

        return CartsService.updateCartLine(
            this._cart.cartId,
            this._baseStore.dealer.dealerId,
            toJS(this._cart.cartName),
            toJS(cartLine)
        ).then((response) => {
            if (cartLine.quantityOrdered > originalCartLine.quantityOrdered) {
                AnalyticsService.writeAddToCartEvent(
                    [this._makeAnalyticsAddPartFromCartLine(cartLine, originalCartLine)],
                    'Quantity Change'
                );
            } else if (cartLine.quantityOrdered < originalCartLine.quantityOrdered) {
                AnalyticsService.writeRemoveFromCartEvent(
                    [this._makeAnalyticsRemovePartFromCartLine(cartLine, originalCartLine)],
                    'Quantity Change'
                );
            }

            return response;
        }).catch(action((response) => {
            // failed, restore original values
            cartLine.quantityOrdered = originalCartLine.quantityOrdered;
            cartLine.isEmergencyOrderLine = originalCartLine.isEmergencyOrderLine;

            throw response;
        }));
    }

    @action.bound
    removeCartLineWithOrderLine(orderLine) {
        const cartLineIndex = this._cart.cartLine.findIndex(_.matches(
            {
                partNumber: orderLine.partNumber
            }
        ));
        const cartLine = toJS(this._cart.cartLine[cartLineIndex]);

        _.pullAt(this._cart.cartLine, cartLineIndex);

        return CartsService.deleteCartLine(
            this._cart.cartId,
            this._baseStore.dealer.dealerId,
            toJS(this._cart.cartName),
            cartLine
        ).then((response) => {
            AnalyticsService.writeRemoveFromCartEvent(
                [this._makeAnalyticsRemovePartFromCartLine(cartLine)],
                'Delete'
            );

            return response;
        }).catch(action((response) => {
            // failed, restore
            this._cart.cartLine.splice(cartLineIndex, 0, cartLine);

            throw response;
        }));
    }

    addOrderByIdToCart(orderId) {
        this._lastAddCartOperation = 'Add Order';

        this._addAndReloadCurrentCart(
            orderId,
            undefined,
            undefined
        );
    }

    addCartByIdToCart(cartId) {
        this._lastAddCartOperation = 'Add Cart';

        this._addAndReloadCurrentCart(
            undefined,
            cartId,
            undefined
        );
    }

    addPartByIdToCart(partId, quantity, source) {
        this._lastAddCartOperation = `Add Part - ${source}`;

        // copy the existing cart lines
        const newCartLines = _.map(
            _.get(this._cart, 'cartLine'),
            (cartLine) => {
                return {
                    partNumber: cartLine.partNumber,
                    quantityOrdered: cartLine.quantityOrdered
                };
            }
        );

        const matchingNewCartLine = _.find(newCartLines, {
            partNumber: partId
        });

        if (matchingNewCartLine) {
            // the part is already in the cart, update the quantity
            matchingNewCartLine.quantityOrdered += quantity;
        } else {
            // push a new part
            newCartLines.push({
                partNumber: partId,
                quantityOrdered: quantity
            });
        }

        this._addAndReloadCurrentCart(
            undefined,
            undefined,
            newCartLines
        );
    }

    @action.bound
    _addAndReloadCurrentCart(mergeOrderId, mergeCartId, cartLines) {
        if (!this._baseStore.userSession.currentUser) {
            return;
        }
        this._loadingCart = true;
        this._addingToCart = true;

        CartsService.uploadCartLines(
            // if the entry point is not checkout we may not have a cart
            _.get(this._cart, 'cartId'),
            this._baseStore.dealer.dealerId,
            undefined,
            cartLines,
            mergeOrderId,
            mergeCartId,
            this._baseStore.locale
        ).then((uploadedCartId) => {
            // check for an "error" response
            if (_.isObject(uploadedCartId)) {
                return uploadedCartId;
            }

            // copy the new cart ID onto the route (should never change but it might be newly added)
            this._routerStore.router.navigate(
                this._routerStore.route.name,
                _.assign(
                    {},
                    this._routerStore.route.params,
                    {
                        'cart-id': uploadedCartId
                    }
                ),
                {
                    replace: true
                }
            );

            // load the updated cart
            return CartsService.getCarts(
                this._baseStore.locale,
                uploadedCartId,
                this._baseStore.dealer.dealerId
            );
        }).then(
            action((carts) => {
                if (carts.maxCartLine) {
                    this._loadingCart = false;
                    this._addingToCart = carts;
                    return;
                }

                // extract a copy of the current cart lines for comparison
                const oldCartLines = _.map(
                    _.get(this._cart, 'cartLine'),
                    (cartLine) => {
                        return {
                            partNumber: cartLine.partNumber,
                            quantityOrdered: cartLine.quantityOrdered
                        };
                    }
                );

                // set the updated cart
                if (carts && _.size(carts) === 1) {
                    this._cart = carts[0];
                }

                // figure out which parts have been newly added or have had their quantity changed
                const newParts = _.differenceWith(
                    this._cart.cartLine,
                    oldCartLines,
                    (cartLine, otherCartLine) => {
                        return cartLine.partNumber === otherCartLine.partNumber &&
                                cartLine.quantityOrdered === otherCartLine.quantityOrdered;
                    }
                );

                // for all parts, issue add analytics event for the new parts
                AnalyticsService.writeAddToCartEvent(
                    _.map(newParts, (cartLine) => {
                        return this._makeAnalyticsAddPartFromCartLine(
                            cartLine,
                            _.find(oldCartLines, {
                                partNumber: cartLine.partNumber
                            })
                        );
                    }),
                    this._lastAddCartOperation
                );

                // build list used by the new parts dialog
                this._cartNewParts = _.map(newParts, (cartLine) => {
                    // pick properties used by the new parts dialog
                    const newCartLine = _.pick(
                        cartLine,
                        [
                            'partNumber',
                            'quantityOrdered',
                            'unitPrice',
                            'partDescription'
                        ]
                    );

                    // want to show the quantity added, not the current total quantity, calculate it
                    const oldCartLine = _.find(oldCartLines, {
                        partNumber: newCartLine.partNumber
                    });

                    if (oldCartLine) {
                        newCartLine.quantityOrdered = newCartLine.quantityOrdered - oldCartLine.quantityOrdered;
                    }

                    return newCartLine;
                });

                this._loadingCart = false;
                this._addingToCart = false;
            })
        ).catch(
            action(() => {
                this._baseStore.showCriticalError();
                this._cart = null;
                this._loadingCart = false;
                this._addingToCart = false;
            })
        );
    }

    _makeAnalyticsAddPartFromCartLine(cartLine, originalCartLine) {
        let quantity = cartLine.quantityOrdered;

        if (originalCartLine) {
            quantity = quantity - originalCartLine.quantityOrdered;
        }

        return {
            productInfo: {
                baseCode: '',
                partNumber: cartLine.partNumber,
                quantity: quantity,
                price: cartLine.unitPrice
            }
        };
    }

    _makeAnalyticsRemovePartFromCartLine(cartLine, originalCartLine) {
        let quantity = cartLine.quantityOrdered;

        if (originalCartLine) {
            quantity = originalCartLine.quantityOrdered - quantity;
        }

        return {
            productInfo: {
                baseCode: '',
                partNumber: cartLine.partNumber,
                quantity: quantity
            }
        };
    }

    fetchCart() {
        if (this._cart !== null && this._cart !== undefined && this._cart.cartId !== null && this._cart.cartId !== undefined) {
            this._loadingCart = true;
            CartsService.getCarts(
                this._baseStore.locale,
                this._cart.cartId,
                this._baseStore.dealer.dealerId
            ).then(
                action((carts) => {
                    if (carts && _.size(carts) === 1) {
                        this._cart = carts[0];
                    }
                    this._cartReloaded = true;
                    this._loadingCart = false;
                })
            ).catch(
                action(() => {
                    this._baseStore.showCriticalError();
                    this._cart = null;
                    this._loadingCart = false;
                    this._cartReloaded = false;
                })
            );
        }
    }
}

export default PCCart;

