import _ from 'lodash';

import React from 'react';
import {inject, observer} from 'mobx-react';

import './pc-flash-alerts.component.scss';

@inject('baseStore')
@observer
class PCFlashAlerts extends React.Component {
    render() {
        if (_.isEmpty(this.props.baseStore.flashAlerts)) {
            return null;
        }

        const flashAlert = _.head(this.props.baseStore.flashAlerts);

        return (
            <div className='flash-alerts'>
                <div className={`flash-alert ${flashAlert.type}`}>
                    <div className='content'>
                        {flashAlert.message}
                    </div>
                </div>
            </div>
        );
    }
}

export default PCFlashAlerts;
