import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import {action, observable, reaction} from 'mobx';
import {observer} from 'mobx-react';

import './search-input.component.scss';

const KEY_ENTER = 13;

@observer
class PCSearchInput extends React.Component {
    constructor(props) {
        super(props);

        this._reactions = [];

        this.onSearch = this.onSearch.bind(this);
        this.onSearchTermKeyDown = this.onSearchTermKeyDown.bind(this);

        this._reactions.push(reaction(
            () => this.props.searchTerm,
            (newSearchTerm) => {
                this._searchTerm = newSearchTerm;
            },
            {
                fireImmediately: true
            }
        ));
    }

    destroy() {
        _.each(this._reactions, (disposeReaction) => {
            disposeReaction();
        });
    }

    @observable _searchTerm = '';

    onSearch() {
        this.props.onSearch(this._searchTerm);
    }

    @action.bound
    onClear() {
        this._searchTerm = '';
        this.onSearch();
    }

    @action.bound
    onSearchTermChange(event) {
        this._searchTerm = event.target.value;
    }

    onSearchTermKeyDown(event) {
        if (event.keyCode === KEY_ENTER) {
            this.onSearch();
        }
    }

    render() {
        let hasSearchTermClass = '';

        if (!_.isEmpty(this._searchTerm)) {
            hasSearchTermClass = ' has-search-term';
        }

        return (
            <div className={`search-input${hasSearchTermClass}`}>
                <div className='input-group'>
                    <input
                        type='text'
                        className='form-control'
                        placeholder={this.props.placeholder}
                        value={this._searchTerm}
                        onChange={this.onSearchTermChange}
                        onKeyDown={this.onSearchTermKeyDown}
                    />
                    <span className='input-group-btn btn-clear'>
                        <button
                            type='button'
                            className='btn'
                            onClick={this.onClear}
                        >
                            &nbsp;
                        </button>
                    </span>
                    <span className='input-group-btn btn-search'>
                        <button
                            type='button'
                            className='btn'
                            onClick={this.onSearch}
                        >
                            &nbsp;
                        </button>
                    </span>
                </div>
            </div>
        );
    }
}

PCSearchInput.propTypes = {
    onSearch: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    placeholder: PropTypes.string
};

export default PCSearchInput;
