import _ from 'lodash';
import {action, reaction, observable, toJS} from 'mobx';
import DealerPolicyService from '../../shared/services/dealer-policy.service'

class PCDealerPoliciesStore {
@observable _dealerPolicies;

constructor(baseStore) {
    this._reactions = [];
    this._baseStore = baseStore;


    this._reactions.push(reaction(
        () => (  {
            dealerInfo: baseStore.dealer
        }),
        ({
            dealerInfo
        }) => {
            this._dealerPolicies = undefined;
            if(dealerInfo===undefined){
             return ;
            }
            DealerPolicyService.getDealerPolicies(
                dealerInfo.dealerId
            )
                .then(action((data) => {
                    this._dealerPolicies = data;
                }))
                .catch(action((e) => {
                    this._dealerPolicies = null;
                }));
        },
        {
            fireImmediately: true,

        }

    ));
}

get dealerPolicies() {
    return this._dealerPolicies;
}

destroy = () => {
    this._reactions.forEach((destroyReaction) => destroyReaction());
};

}
export default PCDealerPoliciesStore;
