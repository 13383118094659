import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCAccountCreatedStore from './pc-account-created.store';
import {makePartsCatalogUrl} from '../shared/parts-catalog-url-builder.util';

import './pc-account-created.component.scss';

@inject('baseStore', 'dealerSelectorStore')
@observer
class PCAccountCreated extends React.Component {
    constructor(props) {
        super(props);

        this._accountCreatedStore = new PCAccountCreatedStore(props.baseStore);

        this.redirectToPartsCatalog = this.redirectToPartsCatalog.bind(this);
        this.onSelectDealer = this.onSelectDealer.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onComplete = this.onComplete.bind(this);
    }

    redirectToPartsCatalog() {
        window.location.href = makePartsCatalogUrl(this.props.baseStore);
    }

    onSelectDealer() {
        this.props.dealerSelectorStore.changeDealer();
    }

    onCancel() {
        this.redirectToPartsCatalog();
    }

    onComplete(event) {
        event.currentTarget.blur();

        this._accountCreatedStore.createAccount().then(() => {
            this.props.baseStore.showSuccessAlert((
                <FormattedMessage
                    defaultMessage='Your account registration has been completed!'
                    id='pc-account-created.success'
                />
            ));
            setTimeout(() => {
                this.redirectToPartsCatalog();
            }, this.props.baseStore.flashAlertTimeout);

            return undefined;
        }).catch(_.noop);
    }

    render() {
        const dealer = this.props.baseStore.dealer;
        let dealerStatus;
        let dealerDetail;

        if (dealer) {
            dealerStatus = (
                <div className='col-sm-10 status complete'>
                    <FormattedMessage
                        defaultMessage='Selected'
                        id='pc-account-created.selected'
                    />
                </div>
            );
            dealerDetail = (
                <React.Fragment>
                    <div className='dealer'>
                        <div>
                            {dealer.dealerName}
                        </div>
                        <div className='dealer-details'>
                            <div>
                                {dealer.formattedAddressLine1}
                            </div>
                            {dealer.formattedAddressLine2}
                            <div>
                                {dealer.phone}
                            </div>
                        </div>

                        <div>
                            {'('}
                            <button
                                className='change-button'
                                onClick={this.onSelectDealer}
                            >
                                <FormattedMessage
                                    defaultMessage='Change'
                                    id='pc-account-created.change'
                                />
                            </button>
                            {')'}
                        </div>
                    </div>
                    <div className='info-message'>
                        <FormattedMessage
                            defaultMessage='You can change your preferred service dealer at any time through your JDParts profile.'
                            id='pc-account-created.dealer-message'
                        />
                    </div>
                </React.Fragment>
            );
        } else {
            dealerStatus = (
                <div className='col-sm-10 status incomplete'>
                    <FormattedMessage
                        defaultMessage='Not Selected'
                        id='pc-account-created.not-selected'
                    />
                </div>
            );
            dealerDetail = (
                <button
                    className='btn btn-primary'
                    onClick={this.onSelectDealer}
                >
                    <FormattedMessage
                        defaultMessage='Select Dealer'
                        id='pc-account-created.select-dealer'
                    />
                </button>
            );
        }

        let createAccountErrorMessage;

        if (this._accountCreatedStore.createAccountError) {
            createAccountErrorMessage = (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Unable to complete your account registration.'
                        id='pc-account-created.error'
                    />
                </div>
            );
        }

        return (
            <div className='pc-account-created white-background'>
                <div className='container page'>
                    <h2>
                        <FormattedMessage
                            defaultMessage='Complete your account registration'
                            id='pc-account-created.complete-message'
                        />
                    </h2>

                    <div className='created-step'>
                        <div className='row step-info'>
                            <h5 className='col-sm-12 step-title'>
                                <FormattedMessage
                                    defaultMessage='Email Address'
                                    id='pc-account-created.email-header'
                                />
                            </h5>
                            <div className='col-sm-10 status complete'>
                                <FormattedMessage
                                    defaultMessage='Confirmed'
                                    id='pc-account-created.confirmed'
                                />
                            </div>
                        </div>
                        <div>
                            {this.props.baseStore.userSession.currentUser.email}
                        </div>
                        <div className='info-message'>
                            <FormattedMessage
                                defaultMessage='Your email address can be updated from your account details.'
                                id='pc-account-created.email-message'
                            />
                        </div>
                    </div>

                    <div className='created-step'>
                        <div className='row step-info'>
                            <h5 className='col-sm-12 step-title'>
                                <FormattedMessage
                                    defaultMessage='Preferred Service Dealer'
                                    id='dealer-header'
                                />
                            </h5>
                            {dealerStatus}
                        </div>
                        {dealerDetail}
                    </div>

                    {createAccountErrorMessage}

                    <div className='action-buttons-row'>
                        <button
                            className='btn btn-hollow'
                            onClick={this.onCancel}
                        >
                            <FormattedMessage
                                defaultMessage='Cancel'
                                id='pc.cancel'
                            />
                        </button>
                        <button
                            className='btn btn-primary'
                            disabled={!this.props.baseStore.dealer}
                            onClick={this.onComplete}
                        >
                            <FormattedMessage
                                defaultMessage='Complete'
                                id='pc-account-created.complete'
                            />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PCAccountCreated;
