import React, { Component } from 'react';
import { PropTypes} from 'mobx-react';
import {FormattedMessage,FormattedDate, FormattedTime} from 'react-intl';

class CustomModal extends Component {
  closeCustomModal = (e) => {
    this.props.onCloseModal(e);
  }

  render() {
    const {
      items
       } = this.props;
      let foundCurentSelected = false;
    return (
    <div className="custommodal">
      <div id="myModal" className="modal-dialog">
        <div className="custommodal-content">

          <div className="modal-header">
            <span className="close" onClick={this.closeCustomModal}>&times;</span>
            <h4>
              <FormattedMessage
                defaultMessage='Tracking Info'
                id='pc.tracking-info'
              />
            </h4>
          </div>
          
          <div className="modal-body">
            <div className="wrapper">
              <ul className="StepProgress">
              
                {_.map(items, (term) => {
                   if(term.isSelected){
                    foundCurentSelected = true;
                  }
                  return (
                    term.previousSelected || term.isSelected ?
                      <li className="StepProgress-item is-done">
                        <p><strong>{term.orderStatusText} </strong><br />
                          <FormattedDate value={term.activityDate} />
                          {' '}
                          <FormattedTime value={term.activityDate} /> <br />
                          {term.orderStatusComment}
                        </p>
                      </li>
                      :
                      term.orderStatusCd !== 'C' && foundCurentSelected?
                        <li className="StepProgress-item"><strong>
                          <p>{term.orderStatusText}
                          </p></strong>
                        </li> : null

                  )
                })}
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
    );
  }
}
CustomModal.propTypes = {
    items: PropTypes.arrayOrObbservableArray
};


export default CustomModal;

