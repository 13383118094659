import React from 'react';
import {FormattedDate, FormattedTime, FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';
import PCContentHeader from '../../shared/components/content-header/content-header.component';
import PCLargeError from '../../shared/components/large-error/large-error.component';
import PCOrderDetailsBody from './pc-order-details-table-body.component';
import PCOrderDetailsHeader from './pc-order-details-table-header.component';
import PCOrderDetailsSummary from './pc-order-details-summary.component';
import PCOrderDetailsInformation from '../../shared/components/pc-order-details-information/pc-order-details-information.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import PCRouteLink from '../../shared/components/route-link/route-link.component';
import CustomModal from './CustomModal';
import './style.css';
import './pc-order-details.scss';

@inject('baseStore', 'ordersStore')
@observer
class PCOrderDetails extends React.Component {
    constructor(props) {
        super(props);

        this.addOrderToCart = this.addOrderToCart.bind(this);
        this.state = {
            showModal: false
        };
    }

    addOrderToCart() {
        this.props.baseStore.currentCart.addOrderByIdToCart(this.props.ordersStore.detailedOrder.orderId);
    }

  showModal = (e) => {
      this.setState((prevState) => ({
          showModal: !prevState.showModal
      }));
  }

  render() {
      const {detailedOrder: order} = this.props.ordersStore;
      const {orderStatus: orderStatusOptions} = this.props.ordersStore;
      let orderStatus;

      if (order === undefined) {
          return <PCPageLoading standalone={true}/>;
      }

      if (order === null) {
          return (
              <PCLargeError>
                  <FormattedMessage
                      defaultMessage='Unable to load the order details.'
                      id='pc-order-details.error'
                  />
              </PCLargeError>
          );
      }
      let taxTerm = '';
      let taxLabel;
      if(order.taxTermText!==null && order.taxTermText !== undefined){
          taxTerm = order.taxTermText.slice(order.taxTermText.length - 5);
          if(taxTerm.indexOf('(')===-1){
              taxTerm =  (
                  <FormattedMessage
                      defaultMessage='Tax'
                      id='pc.tax'
                  />);
          }

      }

      if(order.showTaxPerLineitem){
          taxLabel = (
              <FormattedMessage
                  defaultMessage='Tax on shipping{taxTerm}'
                  id='pc.tax-on-shipping'
                  values={{
                      taxTerm:  taxTerm
                  }}
              />
          );
      }else{
          taxLabel = order.taxTermText;
      }

      return (
          <div className='container page pc-order-details'>

              <div className='row navigation'>
                  <PCRouteLink route={'orders'} >
                      <FormattedMessage
                          id='pc-order-details.back-to-orders'
                          defaultMessage='Back to Order History'
                      />
                  </PCRouteLink>

              </div>

              <div className='row'>
                  <PCContentHeader
                      button={(
                          <button
                              className='btn btn-hollow pull-right'
                              onClick={this.addOrderToCart}
                          >
                              <FormattedMessage
                                  defaultMessage='Add All to Cart'
                                  id='pc.add-all-to-cart'
                              />
                          </button>
                      )}
                  >
                      <FormattedMessage
                          defaultMessage='Order'
                          id='pc-order-details.title'
                      />
                      {` #${order.orderId}`}
                  </PCContentHeader>

              </div>

              <div className='row'>
                  {this.props.baseStore.userSession.currentUser.id}
                  {' - '}
                  <FormattedDate value={order.datePlaced}/>
                  {' '}
                  <FormattedTime value={order.datePlaced}/>
              </div><br/>
              {order.orderStatus !== undefined
                  ? <div className='row'>
                      <h4>
                          <FormattedMessage
                              defaultMessage='Status: '
                              id='pc.order-status'
                          />
                          {order.orderStatus}
                      </h4>
                  </div> : null}
              <div className='button'>
                  {order.orderStatus !== undefined ? <button
                      className='btn btn-hollow tracking-btn-id'
                      onClick={this.showModal}
                  >
                      <FormattedMessage
                          defaultMessage='Tracking Info'
                          id='pc.tracking-info'
                      />
                  </button> : null}
              </div>
              <div>
                  {this.state.showModal &&
                  <CustomModal
                      onCloseModal={this.showModal}
                      items={this.props.ordersStore.orderStatus.orderStatusOptions}
                  />
                  }
              </div>
              <div className="row items-table-content">
                  <table className='items-table white-background'>
                      <PCOrderDetailsHeader
                          lineCount={order.lineCount}
                          taxType={taxTerm}
                          showTaxPerLineitem={order.showTaxPerLineitem}
                          taxPercentage={order.taxPercentage}
                      />
                      <tbody>
                          <PCOrderDetailsBody
                              currency={order.priceUnit}
                              items={order.orderLine}
                              showTaxPerLineitem={order.showTaxPerLineitem} />
                      </tbody>
                  </table>
              </div>

              <PCOrderDetailsSummary
                  currency={order.priceUnit}
                  shipping={order.shippingValue}
                  subTotal={order.subOrderValue}
                  tax={order.taxValue}
                  total={order.totalOrderValue}
                  taxTerm={taxLabel}
              />

              <PCOrderDetailsInformation {...order}/>

          </div>
      );
  }
}

export default PCOrderDetails;
