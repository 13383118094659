import React from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import PCOrderDetailsInformationItem from './pc-order-details-information-item.component';
import _ from 'lodash';

import './pc-order-details-information.component.scss';

function filterItemByColumnIndex(customerInfo, elementId) {
    return _.chain(customerInfo)
        .sortBy('rowIndex')
        .groupBy('rowIndex')
        .map((addressRow) => {
            return _.chain(addressRow)
                .sortBy('columnIndex')
                .filter((ele) => ele.addressElementId === elementId)
                .map((element) => element.addressElementValue)
                .value();
        })
        .value();
}

function makeAddressArray(customerInfo) {
    return _.chain(customerInfo)
        .sortBy('rowIndex')
        .groupBy('rowIndex')
        .map((addressRow) => {
            return _.chain(addressRow)
                .sortBy('columnIndex')
                .map((element) => element.addressElementValue)
                .join(' ')
                .value();
        })
        .value();
}

function makeDealerInfoArray({
    name,
    formattedAddressLine1,
    formattedAddressLine2,
    phone,
    email,
    website
}) {
    let gMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${formattedAddressLine1}`;

    if (formattedAddressLine2) {
        gMapsUrl += ` ${formattedAddressLine2}`;
    }

    const separator = ' | ';
    const dealerContacts = (
        <div className='dealer-links'>
            { email && <a
                href={`mailto:${email}`}
                target='_blank'
                rel='noopener noreferrer'
            >
                <FormattedMessage
                    defaultMessage='Email'
                    id='pc.email'
                />
            </a>
            }
            {email && separator}

            { website && <a
                href={website}
                target='_blank'
                rel='noopener noreferrer'
            >
                <FormattedMessage
                    defaultMessage='Website'
                    id='pc.website'
                />
            </a>}
            {website && separator}

            <a
                href={gMapsUrl}
                target='_blank'
                rel='noopener noreferrer'
            >
                <FormattedMessage
                    defaultMessage='Get Directions'
                    id='pc.directions'
                />
            </a>
        </div>
    );

    return [
        name,
        formattedAddressLine1,
        formattedAddressLine2,
        phone,
        dealerContacts
    ];
}

const PCOrderDetailsInformation = ({
    customerInfo,
    shippingInfo,
    shippingMethod,
    dealerInfo,
    paymentMethod,
    purchaseOrderNumber,
    specialInstruction
}) => {
    let shippingOrderedArray = [shippingMethod];

    if (shippingInfo) {
        shippingOrderedArray = _.concat(shippingOrderedArray, makeAddressArray(shippingInfo));
    }

    return (
        <div className='pc-order-details-information'>
            <div className='row information'>
                <h3>
                    <FormattedMessage
                        defaultMessage='Your Order Information'
                        id='pc-order-details.order-information'
                    />
                </h3>
            </div>

            {
                _.isEmpty(purchaseOrderNumber) ? null : (
                    <div className='row information'>
                        <h5>
                            <FormattedMessage
                                defaultMessage='Purchase Order Number'
                                id='pc-order-details.purchase-order-number'
                            />
                        </h5>
                        <div>{purchaseOrderNumber}</div>
                    </div>
                )
            }

            {
                _.isEmpty(specialInstruction) ? null : (
                    <div className='row information'>
                        <h5>
                            <FormattedMessage
                                defaultMessage='Special Instructions'
                                id='pc.special-instructions'
                            />
                        </h5>
                        <div>{specialInstruction}</div>
                    </div>
                )
            }

            <div className='row information'>
                <div className='col-md-6 information-column'>
                    <div className='information-item'>
                        <h5>
                            <FormattedMessage
                                defaultMessage='Name & Contact'
                                id='pc-order-details.contact'
                            />
                        </h5>
                        <div className='data'>
                            <div>{filterItemByColumnIndex(customerInfo,1001)} {filterItemByColumnIndex(customerInfo,1002)}</div>
                            <div>{filterItemByColumnIndex(customerInfo,1)}</div>
                            <div>{filterItemByColumnIndex(customerInfo,2)}</div>
                            <div>
                                {filterItemByColumnIndex(customerInfo,3)}, {filterItemByColumnIndex(customerInfo,4)}, {filterItemByColumnIndex(customerInfo,5)}
                            </div>
                            <div>{filterItemByColumnIndex(customerInfo,1005)}</div>
                            <div>{filterItemByColumnIndex(customerInfo,1004)}</div>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 information-column'>
                    <div className='information-item'>
                        <h5>
                            <FormattedMessage
                                defaultMessage='Shipping'
                                id='pc.shipping'
                            />
                        </h5>
                        <div className='data'>
                            <div>{filterItemByColumnIndex(shippingInfo,1001)} {filterItemByColumnIndex(shippingInfo,1002)}</div>
                            <div>{filterItemByColumnIndex(shippingInfo,1)}</div>
                            <div>{filterItemByColumnIndex(shippingInfo,2)}</div>
                            <div>
                                {filterItemByColumnIndex(shippingInfo,3)}, {filterItemByColumnIndex(shippingInfo,4)}, {filterItemByColumnIndex(shippingInfo,5)}
                            </div>
                            <div>{filterItemByColumnIndex(shippingInfo,1005)}</div>
                            <div>{filterItemByColumnIndex(shippingInfo,1004)}</div>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 information-column'>
                    <PCOrderDetailsInformationItem
                        dataOrderedArray={[paymentMethod]}
                        messageComponent={(
                            <FormattedMessage
                                defaultMessage='Payment'
                                id='pc.payment'
                            />
                        )}
                    />
                </div>

                <div className='col-md-6 information-column'>
                    <PCOrderDetailsInformationItem
                        dataOrderedArray={makeDealerInfoArray(dealerInfo)}
                        messageComponent={(
                            <FormattedMessage
                                defaultMessage='Sold and Shipped by:'
                                id='pc-order-details.your-dealer'
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

PCOrderDetailsInformation.propTypes = {
    dealerInfo: PropTypes.object,
    customerInfo: MobxPropTypes.observableArray,
    shippingInfo: MobxPropTypes.observableArray,
    shippingMethod: PropTypes.string,
    specialInstruction: PropTypes.string,
    paymentMethod: PropTypes.string,
    purchaseOrderNumber: PropTypes.string
};

export default PCOrderDetailsInformation;
