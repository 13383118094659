
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCPriceDisplay from '../../../shared/components/price-display/price-display.component';

@inject('checkoutStore')
@observer
class PCCheckoutSummaryCartLine extends React.Component {
    render() {
        let showTaxPerLineItem = this.props.checkoutStore.baseStore._cart.cart.showTaxPerLineitem;
        const taxPercentage = this.props.checkoutStore.baseStore._cart.cart.taxPercentage;
        let taxTermText = this.props.checkoutStore.baseStore._cart.cart.taxName;
        let taxTerm;
        if (taxTermText !== null && taxTermText !== undefined) {
            taxTerm = taxTermText.slice(taxTermText.length - 5);
            if (taxTerm.indexOf('(') === -1) {
                taxTerm = (
                    <FormattedMessage
                        defaultMessage='Tax'
                        id='pc.tax'
                    />);
            }
            else {
                taxTerm = taxTerm.slice(1, taxTerm.length - 1);
            }
        };
        let taxtermDisplay;
        if (showTaxPerLineItem) {
            taxtermDisplay = (
                <p style={{textAlign: 'left'}}>
                    <FormattedMessage
                        defaultMessage='Total incl. {taxType} ({taxPercentage}%)'
                        id='pc.total-incl-tax'
                        values={{
                            taxType:  taxTerm,
                            taxPercentage: taxPercentage
                        }}
                    />
                </p>
            );
        }
        let emergencyOrderDisplay;

        if (this.props.orderLine.isEmergencyOrderLine) {
            emergencyOrderDisplay = (
                <h5 className='emergency-order'>
                    <FormattedMessage
                        id='pc.emergency-order'
                        defaultMessage='Emergency Order'
                    />
                </h5>
            );
        }

        return (
            <div className='cart-line'>
                <div className='header'>
                    <div className='summary-price pull-right'>
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            inline={true}
                            value={(this.props.orderLine.unitPrice + this.props.orderLine.taxPrice) * this.props.orderLine.quantity}
                        />
                    </div>
                    <h5>
                        {`${this.props.orderLine.partDescription || this.props.orderLine.partNumber} (${this.props.orderLine.quantity})`}
                    </h5>
                    {taxtermDisplay}
                </div>
                {emergencyOrderDisplay}
            </div>
        );
    }
}

PCCheckoutSummaryCartLine.propTypes = {
    orderLine: PropTypes.shape({
        partDescription: PropTypes.string,
        partNumber: PropTypes.string.isRequired,
        unitPrice: PropTypes.number,
        taxPrice: PropTypes.number,
        quantity: PropTypes.number.isRequired,
        isEmergencyOrderLine: PropTypes.boolean
    }).isRequired
};

export default PCCheckoutSummaryCartLine;

