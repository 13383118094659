import React from 'react';
import { FormattedMessage } from 'react-intl';
import PCOrderDetailsSummaryItem from './pc-order-details-summary-item.component';
import PropTypes from 'prop-types';

const PCOrderSummary = ({
    currency, subTotal, shipping, tax, total, taxTerm
}) => {
    return (
        <div className='row summary'>
            <table className='pull-right'>
                <tbody>
                    <PCOrderDetailsSummaryItem
                        currency={currency}
                        messageComponent={(
                            <FormattedMessage
                                defaultMessage='Subtotal'
                                id='pc.subtotal'
                            />
                        )}
                        value={subTotal}
                    />
                    <PCOrderDetailsSummaryItem
                        currency={currency}
                        messageComponent={(
                            <FormattedMessage
                                defaultMessage='Shipping'
                                id='pc.shipping'
                            />
                        )}
                        value={shipping}
                    />
                    <PCOrderDetailsSummaryItem
                        currency={currency}
                        messageComponent={(
                            <FormattedMessage
                                id={taxTerm}
                            />
                        )}
                        value={tax}
                    />
                    <PCOrderDetailsSummaryItem
                        currency={currency}
                        messageComponent={(
                            <FormattedMessage
                                defaultMessage='Total'
                                id='pc.total'
                            />
                        )}
                        value={total}
                        className={'total'}
                    />
                </tbody>
            </table>

        </div>
    );
};

PCOrderSummary.propTypes = {
    currency: PropTypes.string,
    subTotal: PropTypes.number,
    total: PropTypes.number,
    tax: PropTypes.number,
    shipping: PropTypes.number
};

export default PCOrderSummary;
