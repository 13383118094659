import _ from 'lodash';

import {action, observable} from 'mobx';

import CustomerInfoService from '../shared/services/customer-info.service';

class PCAccountCreatedStore {
    @observable _createAccountError = false;

    constructor(baseStore) {
        this._baseStore = baseStore;
        // this._dealerSelectorStore = dealerSelectorStore;

        if (_.get(this._baseStore.userSession.currentUser, 'idEmail')) {
            // Guest user, log them out so that they are forced to log in
            // with their new account.
            this._baseStore.userSession.logout();
        }
    }

    @action.bound
    createAccount() {
        this._createAccountError = false;
        this._baseStore.showLoadingOverlay();

        return CustomerInfoService.postCustomerInfo(
            this._baseStore.dealer.dealerId
        ).then(
            action((response) => {
                this._baseStore.hideLoadingOverlay();

                if (!response.ok) {
                    this._createAccountError = true;
                    return Promise.reject();
                }

                return undefined;
            }),
            action(() => {
                this._createAccountError = true;
                this._baseStore.hideLoadingOverlay();

                return Promise.reject();
            })
        );
    }

    get createAccountError() {
        return this._createAccountError;
    }
}

export default PCAccountCreatedStore;
