import _ from 'lodash';

import {action, reaction, observable} from 'mobx';

import {fetchJson} from '../shared/fetch-json.util';

class PCDealerPolicies {
    @observable _open = false;
    @observable _data;

    constructor(baseStore) {
        // get the dealer policies when it becomes necessary/possible to do so
        reaction(
            () => {
                return {
                    locale: baseStore.locale,
                    dealerPoliciesOpen: this._open,
                    dataLoaded: baseStore.locale === this._dataLocale &&
                                !_.isEmpty(this._data)
                };
            },
            (params) => {
                if (!params.locale || !params.dealerPoliciesOpen || params.dataLoaded) {
                    return;
                }

                baseStore.findAppJsonFileByPrefix(`dealer-policies-${params.locale}`).then((policiesFile) => {
                    return fetchJson(WEBPACK_ROOT_URL + policiesFile);
                }).then(action((policiesData) => {
                    this._data = policiesData;
                })).catch(action(() => {
                    this._data = null;
                }));
            }
        );

        // clear the dealer policies if the locale changes
        reaction(
            () => baseStore.locale,
            () => {
                this._data = undefined;
            }
        );
    }

    @action.bound
    hide() {
        this._open = false;
    }
    @action.bound
    show() {
        this._open = true;
    }
    get open() {
        return this._open;
    }

    get data() {
        return this._data;
    }
}

export default PCDealerPolicies;
