import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from './../fetch-json.util';

class DealerPolicyService {
    static getDealerPolicies(dealerId) {
        return fetchJsonWithAuth(
            buildUrl(DEALER_POLICY, {
                queryParams: {
                    'dealer-id': dealerId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default DealerPolicyService;
