import React from 'react';
import {inject, observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

import PCOrderHistoryFilter from './pc-order-history-filter.component';
import PCOrdersHistoryHeader from './pc-order-history-header.component';
import PCOrderHistoryItem from './pc-order-history-item.component';
import PCLargeError from '../../shared/components/large-error/large-error.component';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import PCContentHeader from '../../shared/components/content-header/content-header.component';

import './pc-order-history.component.scss';

@inject('ordersStore')
@observer
class PCOrdersHistory extends React.Component {
    render() {
        const {orders} = this.props.ordersStore;

        let table;

        if (orders === undefined) {
            table = (
                <PCPageLoading standalone={true}/>
            );
        } else if (orders === null) {
            table = (
                <PCLargeError>
                    <FormattedMessage
                        defaultMessage='Unable to load the order history.'
                        id='pc-orders-history.error-loading'
                    />
                </PCLargeError>
            );
        } else if (orders.length === 0) {
            table = (
                <div className='no-content-message'>
                    <FormattedMessage
                        defaultMessage='No orders were found.'
                        id='pc-orders-history.empty'
                    />
                </div>
            );
        } else {
            table = (
                <React.Fragment>
                    <PCOrdersHistoryHeader/>
                    {
                        orders.map((order) =>
                            (<PCOrderHistoryItem
                                key={order.orderId}
                                order={order}
                            />)
                        )
                    }
                </React.Fragment>
            );
        }

        return (
            <div className='white-background pc-order-history'>
                <div className='container page'>
                    <div className='row'>
                        <PCContentHeader>
                            <FormattedMessage
                                defaultMessage='Order History'
                                id='pc-orders.table.table-name'
                            />
                        </PCContentHeader>
                    </div>
                    <PCOrderHistoryFilter/>
                    {table}
                </div>
            </div>
        );
    }
}

export default PCOrdersHistory;
