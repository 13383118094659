import React from 'react';
import {inject, observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import PCPageLoading from '../../shared/components/page-loading/page-loading.component';
import PCLargeError from '../../shared/components/large-error/large-error.component';
import PCSavedWishlistPart from './pc-saved-wishlist-part.component';

@inject('savedStore')
@observer
export default class PCSavedWishlistTable extends React.Component {
    render() {
        const {wishlist} = this.props.savedStore.wishlist;

        if (wishlist === undefined) {
            return <PCPageLoading standalone={true}/>;
        }

        if (wishlist === null) {
            return (
                <PCLargeError>
                    <FormattedMessage
                        id='pc-saved-wishlist-table.error-loading'
                        defaultMessage='Unable to Load the Saved Parts'
                    />
                </PCLargeError>
            );
        }

        let table;

        if (wishlist.length === 0) {
            table = (
                <div className='no-content-message'>
                    <FormattedMessage
                        id='pc-saved-wishlist-table.empty'
                        defaultMessage='You have not saved any parts.'
                    />
                </div>
            );
        } else {
            table = (
                <React.Fragment>
                    <div className='row visible-lg item-header'>
                        <div className='col-lg-4'>
                            <FormattedMessage
                                id='pc.part-number'
                                defaultMessage='Part Number'
                            />
                        </div>
                        <div className='col-lg-4'>
                            <FormattedMessage
                                id='pc.part-description'
                                defaultMessage='Part Description'
                            />
                        </div>
                        <div className='col-lg-4 text-center'>
                            <FormattedMessage
                                id='pc.quantity'
                                defaultMessage='Quantity'
                            />
                        </div>
                        <div className='col-lg-3 text-right'>
                            <FormattedMessage
                                id='pc.unit-price'
                                defaultMessage='Unit Price'
                            />
                        </div>
                        <div className='col-lg-3 text-right'>
                            <FormattedMessage
                                id='pc.total-price'
                                defaultMessage='Total Price'
                            />
                        </div>
                    </div>
                    {
                        wishlist.map((part) => (
                            <PCSavedWishlistPart
                                part={part}
                                key={part.partNumber}
                            />
                        ))
                    }
                </React.Fragment>
            );
        }

        return (
            <div className='pc-saved-wishlist'>
                <h3 className='section-header'>
                    <FormattedMessage
                        id='pc.parts'
                        defaultMessage='Parts ({quantity})'
                        values={{
                            quantity: wishlist.length
                        }}
                    />
                </h3>
                {table}
            </div>
        );
    }
}
