import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCProfileDealer from './pc-profile-dealer.component';
import PCContentModal from '../../shared/components/content-modal/content-modal.component';

@inject('baseStore')
@observer
class PCProfileDealersChooseDialog extends React.Component {
    render() {
        const profileDealers = this.props.baseStore.profileDealers;

        return (
            <PCContentModal
                isOpen={true}
                onRequestClose={_.noop}
            >
                <div className='profile-dealers choose-dialog'>
                    <div className='alert alert-warning'>
                        <p className='alert-description'>
                            <FormattedMessage
                                defaultMessage='Your local John Deere dealer will fulfill your order. Please select a dealer in order to checkout.'
                                id='pc-profile-dealers-switch-dialog.select'
                            />
                        </p>
                    </div>
                    <div className='row'>
                        {
                            _.map(profileDealers.chooseProfileDealers, (profileDealer) => {
                                return (
                                    <div
                                        className='col-sm-12 profile-dealer'
                                        key={profileDealer.dealerId}
                                    >
                                        <PCProfileDealer
                                            profileDealer={profileDealer}
                                            buttonAction={_.partial(profileDealers.selectProfileDealer, profileDealer)}
                                            buttonText={(
                                                <FormattedMessage
                                                    defaultMessage='Use Profile Dealer'
                                                    id='pc.use-profile-dealer'
                                                />
                                            )}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </PCContentModal>
        );
    }
}

export default PCProfileDealersChooseDialog;
