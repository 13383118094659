import {configure} from 'mobx';
configure({
    computedRequiresReaction: true,
    enforceActions: 'strict',
    isolateGlobalState: true
});

import React from 'react';
import ReactModal from 'react-modal';
import ReactDOM from 'react-dom';
import PCBase from './modules/pc-base/pc-base.component';

const rootElement = document.getElementById('root');

ReactModal.setAppElement(rootElement);
ReactDOM.render(<PCBase/>, rootElement);

