import _ from 'lodash';
import buildUrl from 'build-url';

function makeLanguageContinuationParams(baseStore) {
    return {
        Language: baseStore.locale.split('-')[0],
        Country: baseStore.countryCode
    };
}

export function makeEditProfileUrl(baseStore, routerStore) {
    return buildUrl(MY_JD_URL, {
        path: 'mypoc/myjd',
        queryParams: _.assign(
            makeLanguageContinuationParams(baseStore),
            {
                uri: 'deeplink:editProfile',
                userName: baseStore.userSession.currentUser.userName,
                TARGET: encodeURIComponent(
                    WEBPACK_ROOT_URL + routerStore.router.buildPath(
                        routerStore.route.name,
                        baseStore.extendWithBaseRouteParameters(routerStore.route.params)
                    )
                )
            }
        )
    });
}

export function makeNewProfileUrl(baseStore, routerStore, orderId) {
    return buildUrl(MY_JD_URL, {
        path: 'poc/myjd',
        queryParams: _.assign(
            makeLanguageContinuationParams(baseStore),
            {
                uri: 'deeplink:registration',
                TARGET: encodeURIComponent(
                    WEBPACK_ROOT_URL + routerStore.router.buildPath(
                        'account-created',
                        baseStore.extendWithBaseRouteParameters()
                    )
                ),
                SRC: encodeURIComponent(
                    WEBPACK_ROOT_URL + routerStore.router.buildPath(
                        'order-complete',
                        baseStore.extendWithBaseRouteParameters({
                            orderId: orderId
                        })
                    )
                )
            }
        )
    });
}
