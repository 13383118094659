import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from '../fetch-json.util';

class ProfileDealersService {
    static getProfileDealers(localeCd, customerId, dealerId) {
        return fetchJsonWithAuth(
            buildUrl(PROFILE_DEALERS_URL, {
                queryParams: {
                    'locale-cd': localeCd,
                    'customer-id': customerId,
                    'dealer-id': dealerId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default ProfileDealersService;
