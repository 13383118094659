import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {makePartsCatalogUrl} from '../../shared/parts-catalog-url-builder.util';

import './pc-checkout-empty.component.scss';

@inject('baseStore')
@observer
class PCCheckoutEmpty extends React.Component {
    render() {
        return (
            <div className='checkout-empty large-message-screen'>
                <div className='message-line-1'>
                    <FormattedMessage
                        defaultMessage='Your cart is empty.'
                        id='pc-checkout-empty.message-line-1'
                    />
                </div>
                <div className='message-line-2'>
                    <FormattedMessage
                        defaultMessage='Start a new parts search to find parts to add to your cart.'
                        id='pc-checkout-empty.message-line-2'
                    />
                </div>

                <div>
                    <a
                        className='new-parts-search'
                        href={makePartsCatalogUrl(this.props.baseStore)}
                    >
                        <FormattedMessage
                            defaultMessage='New Parts Search'
                            id='pc.new-parts-search'
                        />
                    </a>
                </div>
            </div>
        );
    }
}

export default PCCheckoutEmpty;
