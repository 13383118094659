import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';

import './quantity-spinner.component.scss';

const QUANTITY_MIN = 1;
const QUANTITY_MAX = 9999;

class PCQuantitySpinner extends React.Component {
    constructor(props) {
        super(props);

        this.add = this.add.bind(this);
        this.subtract = this.subtract.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    add() {
        if (this.props.quantity >= QUANTITY_MAX) {
            return;
        }

        this.props.onChange(this.props.quantity + 1);
    }

    subtract() {
        if (this.props.quantity <= QUANTITY_MIN) {
            return;
        }

        this.props.onChange(this.props.quantity - 1);
    }

    onChange(event) {
        let newValue = _.round(event.target.value);

        if (newValue < QUANTITY_MIN) {
            newValue = QUANTITY_MIN;
        }
        if (newValue > QUANTITY_MAX) {
            newValue = QUANTITY_MAX;
        }

        this.props.onChange(newValue);
    }

    render() {
        return (
            <div className='quantity-spinner'>
                <div className='input-group'>
                    <div
                        className='input-group-addon subtract'
                        onClick={this.subtract}
                    >
                        &nbsp;
                    </div>
                    <input
                        className='form-control'
                        onChange={this.onChange}
                        step='1'
                        type='number'
                        value={this.props.quantity}
                    />
                    <div
                        className='input-group-addon add'
                        onClick={this.add}
                    >
                        &nbsp;
                    </div>
                </div>
            </div>
        );
    }
}

PCQuantitySpinner.propTypes = {
    onChange: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired
};

export default PCQuantitySpinner;
