import _ from 'lodash';

import React from 'react';
import {inject, observer} from 'mobx-react';

import PCCheckoutSummaryCartLine from './pc-checkout-summary-cart-line.component';

import './pc-checkout-summary-cart.component.scss';

@inject('checkoutStore')
@observer
class PCCheckoutSummaryCart extends React.Component {
    render() {
        const orderLines = _.map(
            this.props.checkoutStore.orderLines,
            (orderLine) => {
                return (
                    <PCCheckoutSummaryCartLine
                        key={orderLine.partNumber}
                        orderLine={orderLine}
                    />
                );
            }
        );

        return (
            <div className='checkout-summary-cart'>
                {orderLines}
            </div>
        );
    }
}

export default PCCheckoutSummaryCart;
