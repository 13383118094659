import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCCheckoutStepReviewLine from './pc-checkout-step-review-line.component';
import PCPriceDisplay from '../../../shared/components/price-display/price-display.component';
import PCPageLoading from '../../../shared/components/page-loading/page-loading.component';

import './pc-checkout-step-review.component.scss';

@inject('checkoutStore')
@observer
class PCCheckoutStepReview extends React.Component {
    constructor(props) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(event) {
        this.props.checkoutStore[event.target.name] = event.target.value;
    }

    render() {
        if (!this.props.checkoutStore.currencyCode ||
            !this.props.checkoutStore.orderLines) {
            return (
                <PCPageLoading/>
            );
        }
        let showTaxPerLineItem = this.props.checkoutStore.baseStore._cart.cart.showTaxPerLineitem;
        let taxTermText = this.props.checkoutStore.baseStore._cart.cart.taxName;
        if (_.isEmpty(this.props.checkoutStore.orderLines)) {
            return (
                <div className='alert alert-warning'>
                    <FormattedMessage
                        defaultMessage='Your cart is empty.'
                        id='pc-checkout-step-review.cart-empty-error'
                    />
                    <p className='alert-description'>
                        <FormattedMessage
                            defaultMessage='Use the Menu to add parts to your cart.'
                            id='pc-checkout-step-review.cart-empty-description'
                        />
                    </p>
                </div>
            );
        }

        const orderLines = _.map(
            this.props.checkoutStore.orderLines,
            (orderLine) => {
                return (
                    <PCCheckoutStepReviewLine
                        key={orderLine.partNumber}
                        orderLine={orderLine}
                    />
                );
            }
        );

        let taxTerm;
        if(taxTermText!==null && taxTermText !== undefined){
            taxTerm = taxTermText.slice(taxTermText.length - 5);
            if(taxTerm.indexOf('(')===-1){
                taxTerm=(
                    <FormattedMessage
                        defaultMessage='Tax'
                        id='pc.tax'
                    />);
            }
            else
                taxTerm = taxTerm.slice(1, taxTerm.length - 1);
        }
        else{
            taxTerm=(
                <FormattedMessage
                    defaultMessage='Tax'
                    id='pc.tax'
                />);
        }
        const taxPercentage = this.props.checkoutStore.baseStore._cart.cart.taxPercentage;

        return (
            <div className='checkout-step-review container-fluid'>

                <table className='part-line-item-table'>
                    <thead>
                        <tr className='part-line-item'>
                            <th >
                                <FormattedMessage
                                    defaultMessage='Items'
                                    id='pc.items'
                                />
                                {` (${this.props.checkoutStore.itemCount})`}
                            </th>
                            <th>
                                <FormattedMessage
                                    defaultMessage='Availability'
                                    id='pc.availability'
                                />
                            </th>
                            <th style={{textAlign: 'center'}}>
                                <FormattedMessage
                                    defaultMessage='Quantity'
                                    id='pc.quantity'
                                />
                            </th>
                            <th style={{textAlign: 'right'}}>
                                <FormattedMessage
                                    defaultMessage='Unit Weight'
                                    id='pc.part-weight'
                                />
                            </th>
                            <th style={{textAlign: 'right'}}>
                                <FormattedMessage
                                    defaultMessage='Unit Net Price'
                                    id='pc.unit-net-price'
                                />
                            </th>
                            <th style={{textAlign: 'right'}}>
                                <FormattedMessage
                                    defaultMessage='Total Net Price'
                                    id='pc.total-net-price'
                                />
                            </th>
                            <th style={{textAlign: 'right' , display: showTaxPerLineItem?'':'none'}}>
                                <FormattedMessage
                                    defaultMessage='{taxType} ({taxPercentage}%)'
                                    id='pc.tax-with-type'
                                    values={{
                                        taxType: taxTerm,
                                        taxPercentage: taxPercentage
                                    }}
                                />
                            </th>
                            <th style={{textAlign: 'right' , display: showTaxPerLineItem?'':'none'}}>
                                <FormattedMessage
                                    defaultMessage='Total Price'
                                    id='pc.total-incl-tax'
                                    values={{
                                        taxType: taxTerm,
                                        taxPercentage: taxPercentage
                                    }}
                                />
                            </th>
                        </tr>
                    </thead>

                    {orderLines}

                </table>

                <div className='row cart-total'>
                    <span className='caption'>
                        <FormattedMessage
                            defaultMessage='Sub Total Net Price'
                            id='pc.sub-total-net-price'
                        />
                    </span>
                    <span className='caption' style={{display: showTaxPerLineItem?'':'none'}}>
                        <FormattedMessage
                            defaultMessage='Sub Total incl. {taxType}'
                            id='pc.sub-total-incl-tax-type'
                            values={{
                                taxType: taxTerm
                            }}
                        />
                    </span>
                </div>
                <div className='row cart-total'>
                    <div className='inline'>
                        <span className={showTaxPerLineItem ? 'margin-right-more' : 'margin-right'}>
                            <PCPriceDisplay
                                currency={this.props.checkoutStore.currencyCode}
                                inline={true}
                                value={this.props.checkoutStore.estimatedTotalWithoutTax}
                            />
                        </span>
                        <span className='margin-right' style={{display: showTaxPerLineItem?'':'none'}}>
                            <PCPriceDisplay
                                currency={this.props.checkoutStore.currencyCode}
                                inline={true}
                                value={this.props.checkoutStore.estimatedTotal}
                            />
                        </span>
                    </div>
                </div>
                <div className='row limit-width'>
                    <div className='form-group coupon-code'>
                        <label>
                            <FormattedMessage
                                defaultMessage='Coupon Code'
                                id='pc.coupon-code'
                            />
                            <input
                                className='form-control'
                                name='couponCode'
                                onChange={this.onInputChange}
                                type='text'
                                value={this.props.checkoutStore.couponCode}
                            />
                        </label>
                        <button className='btn btn-hollow'>
                            <FormattedMessage
                                defaultMessage='Apply'
                                id='pc.apply'
                            />
                        </button>
                    </div>
                    <div className='form-group'>
                        <label>
                            <FormattedMessage
                                defaultMessage='Purchase Order Number'
                                id='pc.po-number'
                            />
                            <input
                                className='form-control'
                                name='purchaseOrderNumber'
                                onChange={this.onInputChange}
                                type='text'
                                value={this.props.checkoutStore.purchaseOrderNumber}
                                maxLength='18'
                            />
                        </label>
                    </div>
                    <div className='form-group'>
                        <label>
                            <FormattedMessage
                                defaultMessage='Special Instructions'
                                id='pc.special-instructions'
                            />
                            <span className='help-block'>
                                <FormattedMessage
                                    defaultMessage='Add comments to help your dealer better fulfill your order!'
                                    id='pc.special-instructions-help'
                                />
                            </span>
                            <textarea
                                className='form-control'
                                name='specialInstructions'
                                onChange={this.onInputChange}
                                value={this.props.checkoutStore.specialInstructions}
                            />
                        </label>
                    </div>
                </div>
                <div className='row limit-width'>
                    <button
                        className='btn btn-primary btn-continue'
                        onClick={this.props.checkoutStore.activateNextStep}
                    >
                        <FormattedMessage
                            defaultMessage='Continue'
                            id='pc.continue'
                        />
                    </button>
                </div>
            </div>
        );
    }
}

export default PCCheckoutStepReview;
