import React from 'react';
import {FormattedMessage} from 'react-intl';
import {action, observable} from 'mobx';
import {inject, observer} from 'mobx-react';

import PCPageLoading from '../../shared/components/page-loading/page-loading.component';

import './pc-login.component.scss';

@inject('baseStore')
@observer
class PCLogin extends React.Component {
    constructor(props) {
        super(props);
        this._baseStore = props.baseStore;
        this._userSession = props.baseStore.userSession;

        this.guestCheckout = this.guestCheckout.bind(this);
    }

    @observable _email = '';
    @observable _emailValid = false;

    guestCheckout() {
        this._userSession.loginAsGuest(
            this._email,
            this._userSession.guestIsRegistered
        );
    }

    @action.bound
    onEmailChanged(event) {
        this._emailValid = event.target.checkValidity();
        this._email = event.target.value;
    }

    render() {
        if (this._userSession.loggingIn) {
            return (
                <PCPageLoading standalone={true}/>
            );
        }

        let guestSection;
        let loginError;

        if (this._userSession.loginFailed) {
            loginError = (
                <div className='alert alert-warning login-error'>
                    <FormattedMessage
                        defaultMessage='Unable to log in.'
                        id='pc-login.error'
                    />
                </div>
            );
        }

        if (!this._baseStore.routeBaseInfo.registeredUserRequired) {
            let guestForm;

            if (this._userSession.guestIsRegistered) {
                guestForm = (
                    <React.Fragment>
                        <div className='alert alert-warning'>
                            <FormattedMessage
                                defaultMessage='"{email}" is registered.'
                                id='pc-login.guest-is-registered'
                                values={{
                                    email: this._email
                                }}
                            />
                            <p className='alert-description'>
                                <FormattedMessage
                                    defaultMessage='Use "Sign In" above to checkout as a registered user or use "Proceed As Guest" below to continue your checkout as a guest.'
                                    id='pc-login.guest-is-registered-description'
                                />
                            </p>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <button
                                    className='btn btn-hollow'
                                    onClick={this._userSession.resetGuest}
                                >
                                    <FormattedMessage
                                        defaultMessage='Cancel'
                                        id='pc.cancel'
                                    />
                                </button>
                            </div>
                            <div className='col-sm-12'>
                                <button
                                    className='btn btn-primary'
                                    onClick={this.guestCheckout}
                                >
                                    <FormattedMessage
                                        defaultMessage='Proceed As Guest'
                                        id='pc-login.proceed-as-guest'
                                    />
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else {
                guestForm = (
                    <React.Fragment>
                        <div className='form-group'>
                            <label>
                                <FormattedMessage
                                    defaultMessage='Email Address'
                                    id='pc-login.email-address'
                                />
                                <input
                                    className='form-control'
                                    name='email'
                                    type='email'
                                    required={true}
                                    value={this._email}
                                    onChange={this.onEmailChanged}
                                />
                            </label>
                        </div>
                        <button
                            className='btn btn-primary'
                            disabled={!this._emailValid}
                            onClick={this.guestCheckout}
                        >
                            <FormattedMessage
                                defaultMessage='Guest Checkout'
                                id='pc-login.guest-checkout'
                            />
                        </button>
                    </React.Fragment>
                );
            }

            guestSection = (
                <React.Fragment>
                    <div className='method-separator'/>

                    <h2>
                        <FormattedMessage
                            defaultMessage='Guest Checkout'
                            id='pc-login.guest-checkout'
                        />
                    </h2>
                    <div className='title-aside'>
                        <FormattedMessage
                            defaultMessage='Email is for receipt and order tracking only'
                            id='pc-login.guest-sign-in-message'
                        />
                    </div>
                    {guestForm}
                </React.Fragment>
            );
        }

        return (
            <div className='login white-background'>
                <div className='container page limit-width'>
                    {loginError}
                    <h2>
                        <FormattedMessage
                            defaultMessage='Registered Users'
                            id='pc-login.registered'
                        />
                    </h2>
                    <div className='title-aside'>
                        <FormattedMessage
                            defaultMessage='Sign into your MyJohnDeere account'
                            id='pc-login.registered-sign-in-message'
                        />
                    </div>
                    <button
                        className='btn btn-primary'
                        onClick={this._userSession.loginAsRegistered}
                    >
                        <div className='sign-in-body'>
                            <FormattedMessage
                                defaultMessage='Sign In'
                                id='pc-login.sign-in'
                            />
                        </div>
                    </button>

                    {guestSection}
                </div>
            </div>
        );
    }
}

export default PCLogin;
