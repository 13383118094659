
// All locale maps for the application are collected here not because they are related,
// but because they are likely to be updated simultaneously.
// Please keep these lists in alphabetical order.

const reactIntlMap = {
    'ar-AR': 'ar',
    'cs-CZ': 'cs',
    'da-DK': 'da',
    'de-BE': 'de-BE',
    'de-DE': 'de-DE',
    'el-GR': 'el',
    'en-GB': 'en-GB',
    'en-US': 'en-US',
    'es-ES': 'es',
    'es-MX': 'es-MX',
    'fi-FI': 'fi',
    'fr-BE': 'fr-BE',
    'fr-CA': 'fr-CA',
    'fr-FR': 'fr-FR',
    'hi-IN': 'hi',
    'hu-HU': 'hu',
    'it-IT': 'it',
    'ja-JP': 'ja',
    'ko-KR': 'ko',
    'nl-BE': 'nl-BE',
    'nl-NL': 'nl-NL',
    'no-NO': 'no',
    'pl-PL': 'pl',
    'pt-BR': 'pt',
    'pt-PT': 'pt-PT',
    'ru-RU': 'ru',
    'sv-SE': 'sv',
    'th-TH': 'th',
    'tr-TR': 'tr',
    'vi-VN': 'vi',
    'zh-CN': 'zh',
    'zh-TW': 'zh'
};

const doMap = function (localeMap, locale) {
    if (localeMap[locale]) {
        return localeMap[locale];
    }

    return locale;
};

class LocaleMapperService {
    static reactIntl(locale) {
        return doMap(reactIntlMap, locale);
    }
}

export default LocaleMapperService;
