
import React from 'react';
import PropTypes from 'prop-types';

import './content-header.component.scss';

const PCContentHeader = ({
    children, button
}) => {
    return (
        <div className='pc-content-header'>
            <div className={button ? 'page-head-with-button' : 'page-head-standalone'}>
                <h2>
                    {children}
                </h2>
                {button}
            </div>
        </div>
    );
};

PCContentHeader.propTypes = {
    button: PropTypes.node,
    children: PropTypes.node.isRequired
};

export default PCContentHeader;
