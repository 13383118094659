import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {makePartsCatalogUrl} from '../../shared/parts-catalog-url-builder.util';

@inject('baseStore')
@observer
class PCPartsCatalogLink extends React.Component {
    render() {
        return (
            <a
                href={makePartsCatalogUrl(this.props.baseStore)}
            >
                <FormattedMessage
                    defaultMessage='New Parts Search'
                    id='pc.new-parts-search'
                />
            </a>
        );
    }
}

export default PCPartsCatalogLink;
