import _ from 'lodash';

import React from 'react';
import ReactModal from 'react-modal';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import './pc-sidebar-menu.component.scss';

import {makeEditProfileUrl} from '../../shared/myjd-url-builder.util';
import RouteLink from '../../shared/components/route-link/route-link.component';
import PCPartsCatalogLink from '../pc-parts-catalog-link/pc-parts-catalog-link.component';

@inject('baseStore', 'routerStore')
@observer
class PCSidebarMenu extends React.Component {
    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }

    logout() {
        this.props.baseStore.hideSidebarMenu();
        this.props.baseStore.userSession.logout();
    }

    render() {
        const currentUser = this.props.baseStore.userSession.currentUser;

        let registeredUserLinks;
        let logoutLink;

        if (_.get(currentUser, 'id')) {
            registeredUserLinks = (
                <React.Fragment>
                    <div className='menu-row'>
                        <a
                            href={makeEditProfileUrl(
                                this.props.baseStore,
                                this.props.routerStore
                            )}
                        >
                            <FormattedMessage
                                defaultMessage='Update My Profile'
                                id='pc-sidebar-menu.update-profile'
                            />
                        </a>
                    </div>
                    <div className='menu-row'>
                        <RouteLink
                            onClick={this.props.baseStore.hideSidebarMenu}
                            route='checkout'
                        >
                            <FormattedMessage
                                defaultMessage='Checkout'
                                id='pc-sidebar-menu.checkout'
                            />
                        </RouteLink>
                    </div>
                    <div className='menu-row'>
                        <RouteLink
                            onClick={this.props.baseStore.hideSidebarMenu}
                            route='orders'
                        >
                            <FormattedMessage
                                defaultMessage='Order History'
                                id='pc-sidebar-menu.order-history'
                            />
                        </RouteLink>
                    </div>
                    <div className='menu-row'>
                        <RouteLink
                            onClick={this.props.baseStore.hideSidebarMenu}
                            route='saved'
                        >
                            <FormattedMessage
                                defaultMessage='Saved Carts & Parts'
                                id='pc-sidebar-menu.saved'
                            />
                        </RouteLink>
                    </div>
                </React.Fragment>
            );
        }

        if (currentUser) {
            let userNameDisplay;

            if (currentUser.id) {
                userNameDisplay = (
                    <FormattedMessage
                        defaultMessage='Hi {name}'
                        id='pc-sidebar-menu.hello-registered'
                        values={currentUser}
                    />
                );
            } else {
                userNameDisplay = (
                    <FormattedMessage
                        defaultMessage='Hi Guest'
                        id='pc-sidebar-menu.hello-guest'
                    />
                );
            }

            logoutLink = (
                <div className='menu-row menu-user'>
                    {userNameDisplay}
                    <button onClick={this.logout}>
                        <FormattedMessage
                            defaultMessage='Logout'
                            id='pc-sidebar-menu.logout'
                        />
                    </button>
                </div>
            );
        }

        return (
            <ReactModal
                isOpen={this.props.baseStore.sidebarMenuVisible}
                className='menu'
                overlayClassName='sidebar-menu'
                onRequestClose={this.props.baseStore.hideSidebarMenu}
            >
                <div className='menu-row menu-header'>
                    <div className='title'>
                        <FormattedMessage
                            defaultMessage='Parts Checkout'
                            id='pc-sidebar-menu.parts-checkout'
                        />
                    </div>
                    <button
                        className='close-btn'
                        onClick={this.props.baseStore.hideSidebarMenu}
                    />
                </div>
                {logoutLink}
                {registeredUserLinks}
                <div className='menu-row'>
                    <a
                        href={FEEDBACK_URL}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <FormattedMessage
                            defaultMessage='Ask a Question'
                            id='pc-sidebar-menu.ask-question'
                        />
                    </a>
                </div>
                <div className='menu-row menu-parts-search'>
                    <PCPartsCatalogLink/>
                </div>
            </ReactModal>
        );
    }
}

export default PCSidebarMenu;
