
import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import './content-modal.component.scss';

@observer
class PCContentModal extends React.Component {
    render() {
        let modalClass = 'content-modal';
        let modalButtons;
        let modalHeader;

        if (this.props.type) {
            modalClass += ` ${this.props.type}`;
        }
        if (this.props.buttons) {
            modalButtons = (
                <div className='content-modal-buttons'>
                    {this.props.buttons}
                </div>
            );
        }
        if (this.props.title) {
            modalHeader = (
                <div className='content-modal-header'>
                    <div className='text-content'>
                        {this.props.title}
                    </div>
                    <div
                        className='icon close'
                        onClick={this.props.onRequestClose}
                    />
                </div>
            );
        }

        return (
            <ReactModal
                isOpen={this.props.isOpen}
                className={modalClass}
                overlayClassName='content-modal-backdrop'
                onRequestClose={this.props.onRequestClose}
            >
                {modalHeader}
                <div className='content-modal-content'>
                    {this.props.children}
                </div>
                {modalButtons}
            </ReactModal>
        );
    }
}

PCContentModal.propTypes = {
    buttons: PropTypes.node,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    title: PropTypes.node,
    type: PropTypes.string
};

export default PCContentModal;
