import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCPriceDisplay from '../../../shared/components/price-display/price-display.component';
import PCEstimatedShippingPriceDisplay from '../../pc-checkout-estimated-shipping-price-display/pc-checkout-estimated-shipping-price-display.component';

import './pc-checkout-summary-totals.component.scss';
import PcCheckoutSummaryTotalPartsWeightDisplay
    from '../../pc-checkout-total-part-weight-display/pc-checkout-summary-total-parts-weight-display.component';

@inject('checkoutStore')
@observer
class PCCheckoutSummaryTotals extends React.Component {
    render() {
        if (!this.props.checkoutStore.currencyCode) {
            return null;
        }

        let estimatedShipping;
        let estimatedSalesTax;
        const showTaxPerLineitem = this.props.checkoutStore.baseStore._cart.cart.showTaxPerLineitem;
        if (this.props.checkoutStore.estimatedShippingUpdateInProgress ||
            this.props.checkoutStore.estimatedShipping !== null) {
            estimatedShipping = (
                <div>
                    <div className='secondary-info pull-left'>
                        <FormattedMessage
                            defaultMessage='Shipping (Net)'
                            id='pc.shipping'
                        />
                    </div>
                    <div>
                        <PCEstimatedShippingPriceDisplay
                            value={this.props.checkoutStore.estimatedShipping}
                        />
                    </div>
                </div>
            );
        }

        if (this.props.checkoutStore.shippingMethodId === '-1') {
            estimatedShipping = undefined;
        }

        let taxTerm;
        let taxLabel;
        let taxTermText = this.props.checkoutStore.baseStore._cart.cart.taxName;
        let subTotalLabel;
        let onlyTaxTerm;
        if(showTaxPerLineitem && taxTermText!==null && taxTermText !== undefined){
            taxTerm = taxTermText.slice(taxTermText.length - 5);
            if(taxTerm.indexOf('(')>-1){
                onlyTaxTerm = taxTerm.slice(1,4);
            }else{
                taxTerm = '';
                onlyTaxTerm = (
                    <FormattedMessage
                        defaultMessage='Tax'
                        id='pc.tax'
                    />
                );
            }
            taxLabel = (
                <FormattedMessage
                    defaultMessage='Tax on shipping{taxTerm}'
                    id='pc.tax-on-shipping'
                    values={{
                        taxTerm:  taxTerm
                    }}
                />
            );
        }else{
            taxLabel = taxTermText;
        }

        subTotalLabel =  (
            <FormattedMessage
                defaultMessage='Total (Net)'
                id='pc.subtotal'
            />
        );

        let showSalesTax = true;
        if(showTaxPerLineitem){
            if(!this.props.checkoutStore.taxOnShippingFlag || this.props.checkoutStore.shippingMethodId === '-1'){
                showSalesTax = false;
            }
        }
        if (this.props.checkoutStore.estimatedSalesTax !== null) {
            estimatedSalesTax = (
                <div style={{ display: showSalesTax? 'block':'none'}}>
                    <div className='secondary-info pull-left'>
                        {taxLabel}
                    </div>
                    <div>
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            inline={true}
                            value={this.props.checkoutStore.estimatedSalesTax}
                        />
                    </div>
                </div>
            );
        }
        let estimatedTotalTax;
        let partPlusShippingTax = 0;
        const taxPercentage = this.props.checkoutStore.baseStore._cart.cart.taxPercentage;

        if (showTaxPerLineitem) {
            partPlusShippingTax
                = this.props.checkoutStore.estimatedSubtotal - this.props.checkoutStore.estimatedSubtotalWithoutTax;

            if (this.props.checkoutStore.estimatedSalesTax !== null) {
                partPlusShippingTax = partPlusShippingTax + this.props.checkoutStore.estimatedSalesTax;
            }

            estimatedTotalTax = (
                <div>
                    <div className='secondary-info pull-left'>
                        {onlyTaxTerm} ({taxPercentage}%)
                    </div>
                    <div>
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            inline={true}
                            value={partPlusShippingTax}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className='checkout-summary-totals shaded-block'>
                <h5>
                    <FormattedMessage
                        defaultMessage='Order Totals'
                        id='pc.order-totals'
                    />
                </h5>
                <div>
                    <div className='secondary-info pull-left'>
                        {subTotalLabel}
                    </div>
                    <div>
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            inline={true}
                            value={this.props.checkoutStore.estimatedSubtotalWithoutTax}
                        />
                    </div>
                </div>
                {estimatedShipping}
                {estimatedTotalTax}
                <div className='totals'>
                    <div className='pull-left'>
                        <FormattedMessage
                            defaultMessage='Total'
                            id='pc.estimated-total'
                        />
                    </div>
                    <div>
                        <PCEstimatedShippingPriceDisplay
                            value={this.props.checkoutStore.estimatedTotal}
                        />
                    </div>
                </div>
                <div className='totals'>
                    <div className='pull-left'>
                        <FormattedMessage
                            defaultMessage='Total Weight'
                            id='pc.total-weight'
                        />
                    </div>
                    <div>
                        <PcCheckoutSummaryTotalPartsWeightDisplay
                            weight={this.props.checkoutStore.totalPartWeight}
                            unit={this.props.checkoutStore.weightUnit}
                        />
                    </div>
                </div>
                <div className='dealer-message'>
                    <FormattedMessage
                        defaultMessage='*Prices are estimated and subject to change.'
                        id='pc.disclaimer-message'
                    />
                </div>
            </div>
        );
    }
}

export default PCCheckoutSummaryTotals;
