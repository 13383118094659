import _ from 'lodash';
import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchWithAuth, fetchJsonWithAuth} from './../fetch-json.util';

function patchCartLines(
    cartId,
    dealerId,
    cartName,
    cartLineInputs
) {
    return fetchWithAuth(
        buildUrl(CARTS_URL, {
            queryParams: {
                'cart-id': cartId,
                'dealer-id': dealerId,
                'cart-name': cartName
            }
        }),
        {
            method: 'PATCH',
            headers: {
                'Content-Type': JD_JSON_CONTENT_TYPE_V2
            },
            body: JSON.stringify(cartLineInputs)
        }
    ).then((response) => {
        if (response.ok) {
            return response;
        }
        return Promise.reject();
    });
}

function mapCartLinesToCartLineInputs(cartLines) {
    return _.map(cartLines, (cartLine) => {
        return {
            'part-number': cartLine.partNumber,
            'quantity': cartLine.quantityOrdered,
            'is-emergency-order-line': cartLine.isEmergencyOrderLine === true
        };
    });
}

function performOpOnCartLine(cartId, dealerId, cartName, cartLine, op) {
    const cartLineInputs = mapCartLinesToCartLineInputs([cartLine]);

    _.each(cartLineInputs, (cartLineInput) => {
        cartLineInput.op = op;
    });

    return patchCartLines(
        cartId,
        dealerId,
        cartName,
        cartLineInputs
    );
}

class CartsService {
    static getCarts(localeCd, cartId, dealerId) {
        return fetchJsonWithAuth(
            buildUrl(CARTS_URL, {
                queryParams: {
                    'locale-cd': localeCd,
                    'cart-id': cartId,
                    'dealer-id': dealerId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }

    static uploadCartLines(
        cartId,
        dealerId,
        cartName,
        cartLines,
        mergedOrderId,
        mergedCartId,
        localeCode
    ) {
        const init = {
            method: cartId ? 'PUT' : 'POST'
        };

        if (cartLines) {
            init.body = JSON.stringify(mapCartLinesToCartLineInputs(cartLines));
            init.headers = {
                'Content-Type': JD_JSON_CONTENT_TYPE_V2
            };
        }

        return fetchWithAuth(
            buildUrl(CARTS_URL, {
                queryParams: {
                    'cart-id': cartId,
                    'dealer-id': dealerId,
                    'cart-name': cartName,
                    'merged-order-id': mergedOrderId,
                    'merged-cart-id': mergedCartId,
                    'locale-cd': localeCode
                }
            }),
            init
        ).then((response) => {
            if (response.ok) {
                return response.text();
            }

            return Promise.reject();
        }).then((responseText) => {
            if (!_.isEmpty(responseText)) {
                // server can respond with the cart ID or a JSON object indicating an error
                try {
                    const responseJSON = JSON.parse(responseText); // Try to parse the response as JSON
                    return _.isObject(responseJSON) ? camelcaseKeys(responseJSON) : responseJSON;
                } catch(err) {
                    return responseText;
                }
            }

            return cartId;
        });
    }

    static updateCartLine(
        cartId,
        dealerId,
        cartName,
        cartLine
    ) {
        return performOpOnCartLine(
            cartId,
            dealerId,
            cartName,
            cartLine,
            'replace'
        );
    }

    static deleteCartLine(
        cartId,
        dealerId,
        cartName,
        cartLine
    ) {
        return performOpOnCartLine(
            cartId,
            dealerId,
            cartName,
            cartLine,
            'remove'
        );
    }

    static deleteCart(cartId) {
        return fetchWithAuth(buildUrl(CARTS_URL, {
            queryParams: {
                'cart-id': cartId
            }
        }), {
            method: 'DELETE'
        }).then((response) => {
            if (response.ok) {
                return response;
            }
            return Promise.reject();
        });
    }
}

export default CartsService;
