import _ from 'lodash';

import AnalyticsService from '../shared/services/analytics.service';
import AddressElementsService from '../shared/services/address-elements.service';
import CartsService from '../shared/services/carts.service';
import CustomerInfoService from '../shared/services/customer-info.service';
import MerchantInfoService from '../shared/services/merchant-info.service';
import OrdersService from '../shared/services/orders.service';
import PaymentMethodsService from '../shared/services/payment-methods.service';
import ShippingMethodsService from '../shared/services/shipping-methods.service';
import ShippingChargeService from '../shared/services/shipping-charge.service';
import WishlistService from '../shared/services/wishlist.service';

import PCMicroform from './pc-microform.class';

import {action, runInAction, computed, observable, reaction, when, toJS} from 'mobx';

class PCCheckoutStore {
    orderSubmitStates = {
        SENDING: 'sending',
        ERROR: 'error'
    };
    _previousCheckoutStep;
    @observable _activeCheckoutStep;
    @observable _currentOrder = {
        couponCode: '',
        billingInfo: null,
        paymentMethodId: null,
        purchaseOrderNumber: '',
        shippingInfo: null,
        shippingMethodId: null,
        estimatedShippingCharge: null,
        specialInstructions: '',
        transactionInfo: null,
        syncShippingAndBilling: true,
        estimatedSalesTax: null,
        taxTermText: null,
        showTaxPerLineitem: false,
        taxOnShippingFlag: false,
        orderLine: []
    };
    @observable _saveDialogVisible = false;
    @observable _reviewFilledIn = false;
    @observable _paymentTokenError = false;
    @observable _shippingMethods;
    @observable _paymentMethods;
    @observable _addressElements;
    @observable _merchantInfo;
    _customerInfo;
    @observable _visitedSteps = [];
    @observable _orderSubmitState;
    @observable _estimatedShippingUpdateRequested = false;
    @observable _estimatedShippingUpdateInProgress = false;

    // eslint-disable-next-line max-statements
    constructor(baseStore, routerStore) {
        this.baseStore = baseStore;
        this.routerStore = routerStore;
        this._reactions = [];

        this.microform = new PCMicroform();

        this.forceReturnToReview = this.forceReturnToReview.bind(this);
        this.saveCart = this.saveCart.bind(this);
        this.saveOrderLine = this.saveOrderLine.bind(this);
        this.generatePaymentTokenAndContinue = this.generatePaymentTokenAndContinue.bind(this);

        if (_.get(routerStore, 'route.params.cart-id') === undefined) {
            // Need a cart to do anything in checkout, we're done here.
            // Component will display an error message.
            return;
        }

        const loadShippingMethods = () => {
            ShippingMethodsService.getShippingMethods(
                this.baseStore.countryCode,
                this.baseStore.locale,
                this.baseStore.dealer.dealerId
            ).then(action((shippingMethods) => {
                this._shippingMethods = _.keyBy(shippingMethods, 'shippingMethodId');
            })).catch(action(() => {
                this._shippingMethods = null;
            }));
        };
        const populateShippingInfoDefaults = () => {
            if (!this._addressElements) {
                return;
            }

            if (!this.shippingMethod.collectShipping) {
                // the shipping method doesn't collect shipping, clear out the shipping info
                this._currentOrder.shippingInfo = null;
                return;
            }

            this._currentOrder.shippingInfo = _.fromPairs(
                _.map(this._addressElements, (addressElement) => {
                    let defaultAddressElementValue =
                        _.get(this._customerInfo, `${addressElement.addressElementId}.addressElementValue`) ||
                        addressElement.addressElementValue;

                    if (!defaultAddressElementValue &&
                        addressElement.addressElementDisplayType === 'email') {
                        defaultAddressElementValue = this.baseStore.userSession.currentUser.idEmail;
                    }

                    return [
                        addressElement.addressElementId,
                        defaultAddressElementValue || ''
                    ];
                })
            );
        };

        const loadPaymentMethods = () => {
            PaymentMethodsService.getPaymentMethods(
                this.baseStore.countryCode,
                this.baseStore.locale,
                this.baseStore.dealer.dealerId,
                _.isEmpty(this.baseStore.userSession.currentUser.id),
                this._currentOrder.shippingMethodId
            ).then(action((paymentMethods) => {
                this._paymentMethods = _.keyBy(paymentMethods, 'paymentMethodId');
            })).catch(action(() => {
                this._paymentMethods = null;
            }));
        };
        const populatePaymentDefaults = () => {
            this._currentOrder.syncShippingAndBilling = true;
            if (!this.paymentMethod || !this._addressElements || !this.merchantInfoLoaded) {
                // don't have enough information to do anything yet
                return;
            }

            if (!this.paymentMethod.collectPayment && !this.paymentMethod.collectCustomerInfo) {
                // the payment method doesn't collect payment or customer info, clear it
                this._currentOrder.transactionInfo = null;
                this._currentOrder.billingInfo = null;
                return;
            }

            if (this.paymentMethod.collectPayment) {
                // populate the transaction info
                this._currentOrder.transactionInfo = {
                    cardType: '',
                    cardExpirationMonth: '',
                    cardExpirationYear: '',
                    paymentToken: null
                };
            } else {
                // this payment method doesn't collect payment, clear it
                this._currentOrder.transactionInfo = null;
            }

            // populate the billing info
            this._currentOrder.billingInfo = _.fromPairs(
                _.map(this._addressElements, (addressElement) => {
                    // if the value exists in the shipping info prefer that,
                    // otherwise we use the one from the customer info
                    let defaultAddressElementValue =
                        _.get(this._currentOrder.shippingInfo, addressElement.addressElementId) ||
                        _.get(this._customerInfo, `${addressElement.addressElementId}.addressElementValue`) ||
                        addressElement.addressElementValue;

                    if (!defaultAddressElementValue &&
                        addressElement.addressElementDisplayType === 'email') {
                        // if we don't already have the address element then for email fields we can
                        // try to get and email off the user session
                        defaultAddressElementValue = this.baseStore.userSession.currentUser.idEmail;
                    }

                    return [
                        addressElement.addressElementId,
                        defaultAddressElementValue || ''
                    ];
                })
            );
        };

        const loadMerchantInfo = () => {
            MerchantInfoService.getMerchantInfo(
                this.baseStore.dealer.dealerId
            ).then(action((merchantInfo) => {
                this._merchantInfo = merchantInfo;
            })).catch(action(() => {
                this._merchantInfo = null;
            }));
        };

        // force to review as the first step, if we are not there already
        if (this.routerStore.route.name !== 'checkout.review') {
            this.forceReturnToReview();
        }

        // When the application is launched directly into checkout we want to inject
        // the "leaving" step into the browser history so it is available when they
        // try to leave the application using the back button.
        if (this.baseStore.launchRouteBaseName === 'checkout') {
            this.baseStore.startInternalNavigation();

            this.activeCheckoutStep = 'leaving';
            this.activeCheckoutStep = 'review';

            this.baseStore.endInternalNavigation();
        }

        // log analytics event as steps are encountered
        // this needs to be prior to the route reaction to capture the initial read off the route
        this._reactions.push(reaction(
            () => {
                return {
                    checkoutStep: this._activeCheckoutStep,
                    cartLine: _.get(this.baseStore.currentCart, 'cart.cartLine')
                };
            },
            (params) => {
                let partsForAnalytics;

                if (params.checkoutStep === 'review' || params.checkoutStep === 'confirm') {
                    if (!params.cartLine) {
                        // this will be called again when we have the required data
                        return;
                    }

                    partsForAnalytics = _.map(
                        params.cartLine,
                        (cartLine) => {
                            return {
                                productInfo: {
                                    baseCode: '',
                                    partNumber: cartLine.partNumber,
                                    quantity: cartLine.quantityOrdered,
                                    price: cartLine.unitPrice
                                }
                            };
                        }
                    );
                }

                AnalyticsService.writeCheckoutStepEncounteredEvent(
                    params.checkoutStep,
                    partsForAnalytics
                );
            }
        ));

        // copy the step name from the route into our state
        this._reactions.push(reaction(
            () => this.routerStore.route.name,
            (currentRouteName) => {
                const splitRouteName = currentRouteName.split('.');

                // this event will fire a final time as the user navigates away,
                // only update if we are in checkout
                if (splitRouteName[0] === 'checkout') {
                    this._activeCheckoutStep = splitRouteName[1];
                }
            },
            {
                fireImmediately: true
            }
        ));

        // copy the parts out of the cart and into the order
        this._reactions.push(reaction(
            () => _.get(this.baseStore.currentCart, 'cart.cartLine'),
            (cartLines) => {
                if (!cartLines) {
                    return;
                }

                this._currentOrder.orderLine = _.map(
                    cartLines,
                    (cartLine) => {
                        // copy the current quantity from the order over to the cart entry
                        const existingLine = _.find(this._currentOrder.orderLine, {
                            partNumber: cartLine.partNumber
                        });

                        if (existingLine) {
                            cartLine.quantityOrdered = existingLine.quantity;
                        }

                        const newLine = _.pick(cartLine, [
                            'partNumber',
                            'partDescription',
                            'quantityOrdered',
                            'quantityAvailable',
                            'unitPrice',
                            'taxPrice',
                            'isEmergencyOrderLine',
                            'partWeight',
                            'weightUnit'
                        ]);

                        newLine.quantity = newLine.quantityOrdered;
                        delete newLine.quantityOrdered;

                        return newLine;
                    }
                );
            },
            {
                fireImmediately: true
            }
        ));

        // when any step other than review is activated consider it filled in
        this._reactions.push(when(
            () => this._activeCheckoutStep !== 'review',
            () => {
                this._reviewFilledIn = true;
            }
        ));

        // as the user leaves steps mark them as visited,
        // any actions that depend on reading the previous step must be placed here
        this._reactions.push(reaction(
            () => this._activeCheckoutStep,
            (activeCheckoutStep) => {
                if (this._previousCheckoutStep) {
                    if (_.indexOf(this._visitedSteps, this._previousCheckoutStep) === -1) {
                        this._visitedSteps.push(this._previousCheckoutStep);
                    }
                }

                this._previousCheckoutStep = activeCheckoutStep;
            }
        ));

        // force the user back to review if they manage to get somewhere they shouldn't be
        this._reactions.push(reaction(
            () => this._activeCheckoutStep,
            (activeCheckoutStep) => {
                if (activeCheckoutStep === 'shipping-info' && !this.shippingMethodId ||
                    activeCheckoutStep === 'payment-info' && !this.paymentMethodId) {
                    this.forceReturnToReview();
                }
            }
        ));

        // load the shipping methods when we reach the shipping methods step
        this._reactions.push(when(
            () => this._activeCheckoutStep === 'shipping-method' &&
                  this.baseStore.dealer && _.isEmpty(this._shippingMethods),
            () => {
                loadShippingMethods();
            }
        ));

        // load the address elements and user information when we reach the shipping info or payment info steps
        this._reactions.push(when(
            () => (this._activeCheckoutStep === 'shipping-info' ||
                  this._activeCheckoutStep === 'payment-info') &&
                  _.isEmpty(this._addressElements),
            () => {
                // if the customer is not a guest then we need to load the customer info
                // so that we can pre-populate the address form
                let getCustomerInfoPromise;

                if (this.baseStore.userSession.currentUser.id) {
                    getCustomerInfoPromise = CustomerInfoService.getCustomerInfo();
                } else {
                    getCustomerInfoPromise = Promise.resolve();
                }

                Promise.all([
                    AddressElementsService.getAddressElements(
                        this.baseStore.countryCode,
                        this.baseStore.locale,
                        this.baseStore.userSession.currentUser.idEmail !== undefined
                    ),
                    getCustomerInfoPromise
                ]).then(action((results) => {
                    this._addressElements = results[0];
                    this._customerInfo = _.keyBy(results[1], 'addressElementId');
                })).catch(action(() => {
                    this._addressElements = null;
                    this._customerInfo = null;
                }));
            }
        ));

        // pre-populate the address fields into the order when we reach the shipping info step
        // and the address elements are loaded
        this._reactions.push(when(
            () => this._activeCheckoutStep === 'shipping-info' &&
                  !_.isEmpty(this._addressElements),
            () => {
                populateShippingInfoDefaults();
            }
        ));

        // clear collected shipping info and payment method when the shipping method changes
        this._reactions.push(reaction(
            () => this._currentOrder.shippingMethodId,
            () => {
                populateShippingInfoDefaults();

                // payment methods are linked to the shipping method, trigger a reload
                this._paymentMethods = undefined;
            }
        ));

        // handle changes to the shipping methods
        this._reactions.push(reaction(
            () => this._shippingMethods,
            () => {
                if (_.isEmpty(this._shippingMethods)) {
                    // there are no shipping methods, clear the previous selection
                    this._currentOrder.shippingMethodId = null;
                }

                if (this._currentOrder.shippingMethodId &&
                    !this._shippingMethods[this._currentOrder.shippingMethodId]) {
                    // the selected shipping method is no longer valid
                    this._currentOrder.shippingMethodId = null;
                    if (this.activeCheckoutStep !== 'review') {
                        this.activeCheckoutStep = 'shipping-method';
                    }
                }
            }
        ));

        // request an update to the estimated shipping charge when the shipping method changes
        this._reactions.push(reaction(
            () => this.shippingMethod,
            (shippingMethod) => {
                if (!shippingMethod || !shippingMethod.hasPrice) {
                    this._currentOrder.estimatedShippingCharge = null;
                }
                this._estimatedShippingUpdateInProgress = false;
                this._estimatedShippingUpdateRequested = true;
            }
        ));

        // update the estimated shipping charge when requested
        this._reactions.push(reaction(
            () => {
                return {
                    requested: this._estimatedShippingUpdateRequested,
                    inProgress: this._estimatedShippingUpdateInProgress
                };
            },
            ({
                requested, inProgress
            }) => {
                if (!requested || inProgress) {
                    return;
                }

                if (!this._currentOrder.shippingMethodId) {
                    // can't update when the shipping method doesn't exist, reset so we can try again
                    this._estimatedShippingUpdateRequested = false;
                    return;
                }

                this._currentOrder.estimatedShippingCharge = null;
                this._estimatedShippingUpdateInProgress = true;
                this._estimatedShippingUpdateRequested = false;
                this._currentOrder.estimatedSalesTax = null;
                this._currentOrder.taxTermText = null;
                this._currentOrder.showTaxPerLineitem = false;

                ShippingChargeService.getShippingCharge(
                    this.baseStore.currentCart.cart.cartId,
                    this._currentOrder.shippingMethodId,
                    this.baseStore.locale
                ).then(action((shippingCharge) => {
                    this._currentOrder.estimatedSalesTax = shippingCharge.taxValue;
                    this._currentOrder.taxTermText = shippingCharge.taxTermText;
                    this._currentOrder.showTaxPerLineitem = shippingCharge.showTaxPerLineitem;
                    this._currentOrder.taxOnShippingFlag = shippingCharge.taxOnShippingFlag;
                    if (!this._estimatedShippingUpdateInProgress) {
                        // update was not in progress, discard response
                        return;
                    }

                    this._currentOrder.estimatedShippingCharge = shippingCharge.shippingPrice;
                    this._estimatedShippingUpdateInProgress = false;
                })).catch(action(() => {
                    // setting to zero to display "contact your dealer"
                    this._currentOrder.estimatedShippingCharge = 0;
                    this._estimatedShippingUpdateInProgress = false;
                }));
            }
        ));

        // load the payment methods when we reach the payment methods step
        this._reactions.push(reaction(
            () => this._activeCheckoutStep === 'payment-method' &&
                  this.baseStore.dealer && _.isEmpty(this._paymentMethods),
            (shouldLoad) => {
                if (shouldLoad) {
                    loadPaymentMethods();
                }
            }
        ));

        // load the merchant information and payment microform when we reach the payment info step,
        // if the payment info collects payment
        this._reactions.push(when(
            () => this._activeCheckoutStep === 'payment-info' &&
                  _.get(this.paymentMethod, 'collectPayment') &&
                  this.baseStore.dealer && _.isEmpty(this._merchantInfo),
            () => {
                loadMerchantInfo();
                this.microform.load();
            }
        ));

        // pre-populate the payment fields into the order when we reach the payment info step
        // and the address elements are loaded
        this._reactions.push(when(
            () => this._activeCheckoutStep === 'payment-info' &&
                  !_.isEmpty(this._addressElements),
            () => {
                populatePaymentDefaults();
            }
        ));

        // Also pre-populate the payment fields in the order when we reach the payment info step
        // and both the address elements and merchant info are loaded.
        // This results in a double pre-population if the user picks a payment method that collects payment,
        // but this is required to catch the situation where the user changes from a payment method that
        // does not collect payment to one that does.
        // Pre-population is not an expensive operation.
        this._reactions.push(when(
            () => this._activeCheckoutStep === 'payment-info' &&
                  !_.isEmpty(this._addressElements) &&
                  !_.isEmpty(this._merchantInfo),
            () => {
                populatePaymentDefaults();
            }
        ));

        // clear collected payment info when the payment method changes
        this._reactions.push(reaction(
            () => this._currentOrder.paymentMethodId,
            () => {
                populatePaymentDefaults();
            }
        ));

        // handle changes to the payment methods
        this._reactions.push(reaction(
            () => this._paymentMethods,
            () => {
                if (_.isEmpty(this._paymentMethods)) {
                    // there are no payment methods, clear their selection
                    this._currentOrder.paymentMethodId = null;
                }

                if (this._currentOrder.paymentMethodId &&
                    !this._paymentMethods[this._currentOrder.paymentMethodId]) {
                    // selected payment method is no longer valid
                    this._currentOrder.paymentMethodId = null;
                    if (this.activeCheckoutStep !== 'review') {
                        this.activeCheckoutStep = 'payment-method';
                    }
                }
            }
        ));

        // keep the shipping info and billing info synchronized when applicable
        this._reactions.push(reaction(
            () => {
                return {
                    syncShippingAndBilling: this._currentOrder.syncShippingAndBilling,
                    // We need to actually access all of the address elements in the shipping info
                    // in order to get the reaction to fire whenever an update to an element is made.
                    shippingInfo: _.fromPairs(
                        _.map(this._addressElements, (addressElement) => {
                            return [
                                addressElement.addressElementId,
                                _.get(this._currentOrder.shippingInfo, addressElement.addressElementId)
                            ];
                        })
                    )
                };
            },
            ({
                syncShippingAndBilling, shippingInfo
            }) => {
                if (
                    // not synced
                    !syncShippingAndBilling ||
                    // nothing to sync
                    !shippingInfo ||
                    // nothing to copy the information into
                    !this._currentOrder.billingInfo
                ) {
                    return;
                }

                // copy the values over
                _.each(this._addressElements, (addressElement) => {
                    const shippingAddressElement = shippingInfo[addressElement.addressElementId];

                    if (shippingAddressElement) {
                        this._currentOrder.billingInfo[addressElement.addressElementId] =
                            shippingAddressElement;
                    }
                });
            }
        ));

        // when the billing/shipping info become un-synchronized, clear the billing info
        this._reactions.push(reaction(
            () => this._currentOrder.syncShippingAndBilling,
            (syncShippingAndBilling) => {
                if (syncShippingAndBilling) {
                    // synchronized, do nothing
                    return;
                }

                _.each(this._addressElements, (addressElement) => {
                    // Clearing the value here instead of defaulting because if the
                    // customer is not synchronizing then likely they are
                    // shipping to a location we don't know about.
                    this._currentOrder.billingInfo[addressElement.addressElementId] = addressElement.addressElementValue || '';
                });
            }
        ));

        // reload the data dependent on the dealerId if the dealerId changes
        this._reactions.push(reaction(
            () => this.baseStore.currentCart._cartReloaded,
            () => {
                if (this.baseStore.currentCart._cartReloaded) {
                    this.activeCheckoutStep = 'review';
                    this.baseStore.currentCart._cartReloaded = false;
                    if (!_.isEmpty(this._shippingMethods)) {
                        loadShippingMethods();
                    }
                    if (!_.isEmpty(this._paymentMethods)) {
                        loadPaymentMethods();
                    }
                    if (!_.isEmpty(this._merchantInfo)) {
                        loadMerchantInfo();
                    }
                }
            }
        ));
    }

    destroy() {
        _.each(this._reactions, (disposeReaction) => {
            disposeReaction();
        });
    }

    // general
    get summaryAvailable() {
        return this._activeCheckoutStep !== 'review';
    }
    get saveAvailable() {
        return this._activeCheckoutStep === 'review' &&
            _.get(this.baseStore.userSession, 'currentUser.id') !== undefined &&
            this.itemCount !== 0;
    }
    get saveDialogVisible() {
        return this._saveDialogVisible;
    }
    @action.bound
    showSaveDialog() {
        this._saveDialogVisible = true;
    }
    @action.bound
    hideSaveDialog() {
        this._saveDialogVisible = false;
    }

    saveCart(name, overwriteOldest, update) {
        const save = () => {
            return this.baseStore.currentCart.saveCartWithCheckoutOrder(
                this._currentOrder,
                name,
                update
            );
        };

        if (overwriteOldest) {
            // get all of the carts
            return CartsService.getCarts(
                this.baseStore.locale,
                undefined,
                this.baseStore.dealer.locationId
            ).then((carts) => {
                // delete the last one in the list
                const oldestCart = _.last(carts);

                if (!oldestCart) {
                    return Promise.reject();
                }
                return CartsService.deleteCart(oldestCart.cartId);
            }).then(save);
        }

        return save();
    }

    @computed get currencyCode() {
        return _.get(this.baseStore.currentCart.cart, 'priceUnit');
    }
    forceReturnToReview() {
        this.routerStore.router.navigate(
            'checkout.review',
            this.routerStore.route.params,
            {
                replace: true
            }
        );
    }

    get addressElements() {
        return this._addressElements;
    }
    get merchantInfo() {
        return this._merchantInfo;
    }

    // checkout steps
    set activeCheckoutStep(newStep) {
        this.routerStore.router.navigate(
            `checkout.${newStep}`,
            this.routerStore.route.params
        );
    }
    get activeCheckoutStep() {
        return this._activeCheckoutStep;
    }
    @action.bound
    activateNextStep() {
        let nextStep;

        switch (this._activeCheckoutStep) {
        case 'review':
            nextStep = 'shipping-method';
            break;
        case 'shipping-method':
            nextStep = this.shippingMethod.collectShipping ? 'shipping-info' : 'payment-method';
            break;
        case 'shipping-info':
            nextStep = 'payment-method';
            break;
        case 'payment-method':
            nextStep = this.paymentMethod.collectPayment || this.paymentMethod.collectCustomerInfo ? 'payment-info' : 'confirm';
            break;
        case 'payment-info':
            nextStep = 'confirm';
            break;
        default:
            break;
        }

        if (nextStep) {
            this.activeCheckoutStep = nextStep;
        }
    }
    @computed get availableCheckoutSteps() {
        const steps = ['review'];

        steps.push('shipping-method');
        // if the shipping method collects info show the shipping info page
        if (this._currentOrder.shippingMethodId &&
           this.shippingMethod.collectShipping) {
            steps.push('shipping-info');
        }

        steps.push('payment-method');
        // if the payment method collects info show the payment info page
        if (this._currentOrder.paymentMethodId &&
            (this._paymentMethods[this._currentOrder.paymentMethodId].collectPayment ||
             this._paymentMethods[this._currentOrder.paymentMethodId].collectCustomerInfo)) {
            steps.push('payment-info');
        }

        steps.push('confirm');

        return steps;
    }
    @computed get filledCheckoutSteps() {
        const steps = [];

        if (this._reviewFilledIn) {
            steps.push('review');
        }
        if (this._currentOrder.shippingMethodId) {
            steps.push('shipping-method');
        }
        if (this.shippingInfoComplete) {
            steps.push('shipping-info');
        }
        if (this._currentOrder.paymentMethodId) {
            steps.push('payment-method');
        }
        if (this.paymentInfoComplete) {
            steps.push('payment-info');
        }

        return steps;
    }

    // review
    @computed get orderLines() {
        return this._currentOrder.orderLine;
    }
    @action.bound
    removeOrderLine(orderLine) {
        const orderLineIndex = this._currentOrder.orderLine.findIndex(_.matches(orderLine));

        _.pullAt(this._currentOrder.orderLine, orderLineIndex);

        return this.baseStore.currentCart.removeCartLineWithOrderLine(orderLine)
            .then(action(() => {
                this._estimatedShippingUpdateRequested = true;
            }))
            .catch(action((response) => {
                // failed, restore
                this._currentOrder.orderLine.splice(orderLineIndex, 0, orderLine);

                throw response;
            }));
    }
    @action.bound
    updateOrderLineQuantity(orderLine, newQuantity) {
        const originalOrderLine = toJS(orderLine);

        orderLine.quantity = newQuantity;

        // if the quantity changes to be In Stock clear the emergency order flag
        if (orderLine.quantity <= orderLine.quantityAvailable) {
            orderLine.isEmergencyOrderLine = false;
        }

        // save the new quantity to the server
        return this.baseStore.currentCart.updateCartLineWithOrderLine(orderLine)
            .then(action(() => {
                this._estimatedShippingUpdateRequested = true;
            }))
            .catch(action((response) => {
                // failed, restore
                orderLine.quantity = originalOrderLine.quantity;
                orderLine.isEmergencyOrderLine = originalOrderLine.isEmergencyOrderLine;

                throw response;
            }));
    }
    @action.bound
    updateOrderLineEmergencyOrder(orderLine, newEmergencyOrder) {
        const originalOrderLine = toJS(orderLine);

        orderLine.isEmergencyOrderLine = newEmergencyOrder;

        return this.baseStore.currentCart.updateCartLineWithOrderLine(orderLine).catch(action((response) => {
            // failed, restore
            orderLine.isEmergencyOrderLine = originalOrderLine.isEmergencyOrderLine;

            throw response;
        }));
    }

    @computed get estimatedSubtotal() {
        return _.reduce(
            this._currentOrder.orderLine,
            (total, orderLine) => {
                if (_.isFinite(orderLine.unitPrice)) {
                    if(_.isFinite(orderLine.taxPrice)){
                        return total + (orderLine.unitPrice + orderLine.taxPrice) *  orderLine.quantity;
                    }else{
                        return total + orderLine.quantity * orderLine.unitPrice;
                    }
                }
                return total;
            },
            0
        );
    }

    @computed get estimatedSubtotalWithoutTax() {
        return _.reduce(
            this._currentOrder.orderLine,
            (total, orderLine) => {
                if (_.isFinite(orderLine.unitPrice)) {
                    return total + orderLine.quantity * orderLine.unitPrice;
                }
                return total;
            },
            0
        );
    }

    @computed get estimatedSalesTax() {
        return this._currentOrder.estimatedSalesTax;
    }

    @computed get totalPartWeight() {
        let totalPartWeight = 0;

        this._currentOrder.orderLine.map((order) => {
            if (order.partWeight > 0 && order.partWeight !== undefined) {
                totalPartWeight += order.partWeight * order.quantity;
            }
        });
        return totalPartWeight;
        // return this._currentOrder.orderLine.reduce((totalWeight, order) => totalWeight + order.partWeight * order.quantity, 0);
    }

    @computed get weightUnit() {
        let weightUnit;

        this._currentOrder.orderLine.map((order) => {
            if (order.weightUnit) {
                weightUnit = order.weightUnit;
            }
        });
        return weightUnit;
    }

    @computed get taxPrice() {
        let taxPrice;
        this._currentOrder.orderLine.map((order) => {
            if (order.taxPrice) {
                taxPrice = order.taxPrice;
            }
        });
        return taxPrice;
    }

    @computed get taxTermText() {
        return this._currentOrder.taxTermText;
    }

    @computed get showTaxPerLineitem() {
        return this._currentOrder.showTaxPerLineitem;
    }

    @computed get taxOnShippingFlag() {
        return this._currentOrder.taxOnShippingFlag;
    }


    @computed get estimatedShipping() {
        return this._currentOrder.estimatedShippingCharge;
    }
    @computed get estimatedShippingUpdateInProgress() {
        return this._estimatedShippingUpdateInProgress;
    }
    @computed get estimatedTotal() {
        let estimatedTotal = this.estimatedSubtotal;
        estimatedTotal = this.addShippingChrgAndSalesTax(estimatedTotal);

        return estimatedTotal;
    }

    addShippingChrgAndSalesTax(estimatedTotal) {
        let estimatedTotalUpdated = estimatedTotal;

        if (this._currentOrder.estimatedShippingCharge) {
            estimatedTotalUpdated += this._currentOrder.estimatedShippingCharge;
        }

        if (this._currentOrder.estimatedSalesTax) {
            estimatedTotalUpdated += this._currentOrder.estimatedSalesTax;
        }

        return estimatedTotalUpdated;
    }

    @computed get estimatedTotalWithoutTax() {
        let estimatedTotal = this.estimatedSubtotalWithoutTax;

        estimatedTotal = this.addShippingChrgAndSalesTax(estimatedTotal);

        return estimatedTotal;
    }

    @computed get itemCount() {
        return _.size(this._currentOrder.orderLine);
    }

    @computed get purchaseOrderNumber() {
        return this._currentOrder.purchaseOrderNumber;
    }
    @action.bound
    set purchaseOrderNumber(newValue) {
        this._currentOrder.purchaseOrderNumber = newValue;
    }

    @computed get specialInstructions() {
        return this._currentOrder.specialInstructions;
    }
    @action.bound
    set specialInstructions(newValue) {
        this._currentOrder.specialInstructions = newValue;
    }

    @computed get couponCode() {
        return this._currentOrder.couponCode;
    }
    @action.bound
    set couponCode(newValue) {
        this._currentOrder.couponCode = newValue;
    }

    saveOrderLine(orderLine, overwriteOldest) {
        this.baseStore.showLoadingOverlay();

        const onCatch = () => {
            this.baseStore.hideLoadingOverlay();
            return Promise.reject();
        };

        const save = () => {
            return WishlistService.addWishlistPart(
                this.baseStore.dealer.dealerId,
                orderLine.partNumber,
                orderLine.quantity
            ).then((response) => {
                this.baseStore.hideLoadingOverlay();
                return response;
            }).catch(onCatch);
        };

        if (overwriteOldest) {
            // get all of the saved parts
            return WishlistService.getWishlist(
                this.baseStore.locale,
                this.baseStore.dealer.dealerId
            ).then((parts) => {
                // delete the last one in the list
                const oldestPart = _.last(parts);

                if (!oldestPart) {
                    return Promise.reject();
                }
                return WishlistService.deleteWishlistPart(
                    oldestPart.partNumber
                );
            }).then(save).catch(onCatch);
        }

        return save();
    }

    // shipping method
    get shippingMethods() {
        return this._shippingMethods;
    }
    @computed get shippingMethodId() {
        return this._currentOrder.shippingMethodId;
    }
    @action.bound
    set shippingMethodId(newValue) {
        this._currentOrder.shippingMethodId = newValue;
    }
    @computed get shippingMethod() {
        return this._shippingMethods && this._shippingMethods[this._currentOrder.shippingMethodId];
    }
    @computed get shippingMethodVisited() {
        return _.includes(this._visitedSteps, 'shipping-method');
    }

    // shipping info
    @computed get shippingInfo() {
        return this._currentOrder.shippingInfo;
    }
    @computed get shippingInfoComplete() {
        if (!this._addressElements || !_.get(this.shippingMethod, 'collectShipping')) {
            return false;
        }

        // The shipping information is complete when all mandatory fields are populated.
        // Using reduce instead of find since we need to access all of the mandatory fields so that
        // this function will be called when any of them change.
        return _.reduce(this._addressElements, (complete, addressElement) => {
            if (addressElement.mandatoryCode &&
                _.isEmpty(this._currentOrder.shippingInfo[addressElement.addressElementId])) {
                return false;
            }
            return complete;
        }, true);
    }
    @computed get shippingInfoVisited() {
        return _.includes(this._visitedSteps, 'shipping-info');
    }

    // payment method
    get paymentMethods() {
        return this._paymentMethods;
    }
    @computed get paymentMethodId() {
        return this._currentOrder.paymentMethodId;
    }
    @action.bound
    set paymentMethodId(newValue) {
        this._currentOrder.paymentMethodId = newValue;
    }
    @computed get paymentMethod() {
        return this._paymentMethods && this._paymentMethods[this._currentOrder.paymentMethodId];
    }
    @computed get paymentMethodVisited() {
        return _.includes(this._visitedSteps, 'payment-method');
    }

    // payment info
    @computed get billingInfo() {
        return this._currentOrder.billingInfo;
    }
    @computed get transactionInfo() {
        return this._currentOrder.transactionInfo;
    }
    get syncShippingAndBilling() {
        return this._currentOrder.syncShippingAndBilling;
    }
    set syncShippingAndBilling(newValue) {
        runInAction(() => {
            this._currentOrder.syncShippingAndBilling = newValue;
        });
    }
    get paymentTokenError() {
        return this._paymentTokenError;
    }
    @computed get paymentInfoTransactionReady() {
        if (!this._addressElements ||
            !this.merchantInfoLoaded ||
            !this._currentOrder.billingInfo ||
            !_.get(this.paymentMethod, 'collectPayment') &&
            !_.get(this.paymentMethod, 'collectCustomerInfo')) {
            return false;
        }

        // The billing information is complete when all mandatory fields are populated.
        // Using reduce instead of find since we need to access all of the mandatory fields so that
        // this function will be called when any of them change.
        const billingInfoComplete = _.reduce(this._addressElements, (complete, addressElement) => {
            if (addressElement.mandatoryCode &&
                _.isEmpty(this._currentOrder.billingInfo[addressElement.addressElementId])) {
                return false;
            }
            return complete;
        }, true);

        // The transaction info is considered ready after the user has populated all of
        // the payment fields, if we collect payment.
        let transactionInfoReady = true;

        if (this.paymentMethod.collectPayment) {
            transactionInfoReady = this.microform.isValid &&
                !_.isEmpty(this._currentOrder.transactionInfo.cardType) &&
                !_.isEmpty(this._currentOrder.transactionInfo.cardExpirationMonth) &&
                !_.isEmpty(this._currentOrder.transactionInfo.cardExpirationYear);
        }

        return billingInfoComplete && transactionInfoReady;
    }
    generatePaymentTokenAndContinue() {
        if (!_.get(this.paymentMethod, 'collectPayment')) {
            this.activateNextStep();
            return;
        }

        this.baseStore.showLoadingOverlay();

        this.microform.createToken(
            this._currentOrder.transactionInfo.cardType,
            this._currentOrder.transactionInfo.cardExpirationMonth,
            this._currentOrder.transactionInfo.cardExpirationYear
        ).then(action((reponse) => {
            this._currentOrder.transactionInfo.paymentToken = reponse;
            this._paymentTokenError = false;
            this.baseStore.hideLoadingOverlay();
            this.activateNextStep();
        })).catch(action(() => {
            this._paymentTokenError = true;
            this.baseStore.hideLoadingOverlay();
        }));
    }
    @computed get paymentInfoComplete() {
        if (_.get(this.paymentMethod, 'collectPayment')) {
            // The transaction ready enables the Continue button, but the payment info is not complete
            // until we have collected the payment token.
            const paymentToken = _.get(this._currentOrder.transactionInfo, 'paymentToken');

            return paymentToken !== null && paymentToken !== undefined;
        }

        // when we are only collecting customer info the transaction ready will have all of the
        // checks we need
        return this.paymentInfoTransactionReady;
    }
    @computed get paymentInfoVisited() {
        return _.includes(this._visitedSteps, 'payment-info');
    }
    @computed get merchantInfoLoaded() {
        const collectPayment = _.get(this.paymentMethod, 'collectPayment');

        return collectPayment && !_.isEmpty(this._merchantInfo) || !collectPayment;
    }

    // confirm
    @computed get orderComplete() {
        // review
        if (!this._reviewFilledIn) {
            return false;
        }
        // shipping method
        if (!this._currentOrder.shippingMethodId) {
            return false;
        }
        // shipping info (if applicable)
        if (this.shippingMethod.collectShipping && !this.shippingInfoComplete) {
            return false;
        }
        // payment method
        if (!this._currentOrder.paymentMethodId) {
            return false;
        }
        // payment info (if applicable)
        if ((this.paymentMethod.collectPayment || this.paymentMethod.collectCustomerInfo) && !this.paymentInfoComplete) {
            return false;
        }

        // everything passed
        return true;
    }
    get orderSubmitState() {
        return this._orderSubmitState;
    }
    @action.bound
    submitOrder() {
        this._orderSubmitState = this.orderSubmitStates.SENDING;
        this.baseStore.showLoadingOverlay();

        OrdersService.postOrder(
            this.baseStore.currentCart.cart.cartId,
            this.baseStore.userSession.currentUser.idEmail,
            this.baseStore.dealer.dealerId,
            this.baseStore.locale,
            this._currentOrder,
            _.get(this._merchantInfo, 'paymentFlexResponse.publicKey')
        ).then((response) => {
            return response.text();
        }).then(action((orderId) => {
            this._orderSubmitState = undefined;
            this.baseStore.hideLoadingOverlay();

            // set up the params with the order ID
            const routeParams = this.baseStore.extendWithBaseRouteParameters({
                orderId: orderId
            });

            // remove the cart ID (we will clear it in a moment)
            delete routeParams['cart-id'];

            // navigate to order success
            this.routerStore.router.navigate(
                'order-complete',
                routeParams,
                () => {
                    // navigation complete, now that we have left the checkout process we can clear the current cart
                    this.baseStore.currentCart.newCart();
                }
            );
        })).catch(action(() => {
            this._orderSubmitState = this.orderSubmitStates.ERROR;
            this.baseStore.hideLoadingOverlay();
        }));
    }
}

export default PCCheckoutStore;
