import React from 'react';
import {observer, Provider} from 'mobx-react';
import {addLocaleData} from 'react-intl';

import PCIntlProvider from './pc-intl-provider/pc-intl-provider.component';
import PCLoadingOverlay from './pc-loading-overlay/pc-loading-overlay.component';
import PCFlashAlerts from './pc-flash-alerts/pc-flash-alerts.component';
import PCHeader from './pc-header/pc-header.component';
import PCSubHeader from './pc-sub-header/pc-sub-header.component';
import PCSidebarMenu from './pc-sidebar-menu/pc-sidebar-menu.component';
import PCFooter from './pc-footer/pc-footer.component';
import PCLogin from './pc-login/pc-login.component';
import PCDealerPolicies from './pc-dealer-policies/pc-dealer-policies.component';
import PCNewParts from './pc-new-parts/pc-new-parts.component';
import PCProfileDealers from './pc-profile-dealers/pc-profile-dealers.component';
import PCBaseStore from './pc-base.store';
import PCDealerSelectorStore from './pc-dealer-selector.store';

import PCCheckout from '../pc-checkout/pc-checkout.component';
import PCGenericError from '../pc-generic-error/pc-generic-error.component';
import PCOrders from '../pc-orders/pc-orders.component';
import PCSaved from '../pc-saved/pc-saved.component';
import PCAccountCreated from '../pc-account-created/pc-account-created.component';
import PCOrderComplete from '../pc-order-complete/pc-order-complete.component';

import PCPageLoading from '../shared/components/page-loading/page-loading.component';

import {createRouter} from 'router5';
import browserPlugin from 'router5/plugins/browser';
import {mobxPlugin, RouterStore} from 'mobx-router5';
import routes from '../../routes';

import '../../uxframe/css/uxframe-2.3.1.min.css';
import '../../uxframe/uxframe-overrides.scss';
import '../shared/shared-styles.scss';

// all locales for the application must be imported here
import ar from 'react-intl/locale-data/ar';
import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import el from 'react-intl/locale-data/el';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import hi from 'react-intl/locale-data/hi';
import hu from 'react-intl/locale-data/hu';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import nl from 'react-intl/locale-data/nl';
import no from 'react-intl/locale-data/no';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ru from 'react-intl/locale-data/ru';
import sv from 'react-intl/locale-data/sv';
import th from 'react-intl/locale-data/th';
import tr from 'react-intl/locale-data/tr';
import vi from 'react-intl/locale-data/vi';
import zh from 'react-intl/locale-data/zh';
import PCCheckoutStore from '../pc-checkout/pc-checkout.store';
addLocaleData([
    ...ar,
    ...cs,
    ...da,
    ...de,
    ...el,
    ...en,
    ...es,
    ...fi,
    ...fr,
    ...hi,
    ...hu,
    ...it,
    ...ja,
    ...ko,
    ...nl,
    ...no,
    ...pl,
    ...pt,
    ...ru,
    ...sv,
    ...th,
    ...tr,
    ...vi,
    ...zh
]);

@observer
class PCBase extends React.Component {
    constructor(props) {
        super(props);
        // copy the hash off now, starting the router will erase it
        const launchHash = window.location.hash;

        this.routerStore = new RouterStore();
        const router = createRouter(PCBaseStore.addBaseRouteParameters(routes))
            .usePlugin(mobxPlugin(this.routerStore))
            .usePlugin(browserPlugin());

        router.start();
        this.baseStore = new PCBaseStore(launchHash, this.routerStore);
        this.dealerSelectorStore = new PCDealerSelectorStore(this.baseStore, this.routerStore);
        this.baseStore.setDealerSelectorStore(this.dealerSelectorStore);
        this.checkoutStore = new PCCheckoutStore(
            this.baseStore,
            this.routerStore
        );
    }

    componentDidCatch() {
        this.baseStore.showCriticalError();
    }
    componentWillUnmount() {
        this.checkoutStore.destroy();
    }

    mapRouteBase(routeBase) {
        switch (routeBase) {
        case 'checkout':
            return (<PCCheckout/>);
        case 'orders':
            return (<PCOrders/>);
        case 'saved':
            return (<PCSaved/>);
        case 'account-created':
            return (<PCAccountCreated/>);
        case 'order-complete':
            return (<PCOrderComplete/>);
        case 'authenticate':
            return (<PCPageLoading standalone={true}/>);
        default:
            return (<PCGenericError/>);
        }
    }

    render() {
        if (this.baseStore.messages === undefined) {
            return (
                <PCPageLoading standalone={true}/>
            );
        }

        // determine which content component to display
        let pageComponent;

        if (this.baseStore.criticalError) {
            pageComponent = (
                <PCGenericError/>
            );
        } else if (this.baseStore.loginVisible) {
            pageComponent = (
                <PCLogin/>
            );
        } else {
            pageComponent = this.mapRouteBase(this.baseStore.routeBaseName);
        }

        return (
            <Provider
                baseStore={this.baseStore}
                dealerSelectorStore={this.dealerSelectorStore}
                routerStore={this.routerStore}
                checkoutStore={this.checkoutStore}
            >
                <PCIntlProvider>
                    <React.Fragment>
                        <header>
                            <PCHeader/>
                            <PCSubHeader/>
                            <PCSidebarMenu/>
                        </header>

                        {pageComponent}

                        <PCFooter/>

                        <PCDealerPolicies/>
                        <PCNewParts/>
                        <PCProfileDealers/>
                        <PCFlashAlerts/>
                        <PCLoadingOverlay/>
                    </React.Fragment>
                </PCIntlProvider>
            </Provider>
        );
    }
}

export default PCBase;
