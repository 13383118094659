export default [
    {
        children: [
            {
                name: 'order-details',
                path: '/:orderId'
            }
        ],
        name: 'orders',
        path: '/orders',
        registeredUserRequired: true,
        dealerSelectorVisible: true,
        cartVisible: true,
        newPartSearchVisible: true,
        secureCheckoutVisible: false
    }
];
