
import React from 'react';
import PropTypes from 'prop-types';

const PCFooterItem = ({item}) => {
    if (!item) {
        return null;
    }

    if (item.link) {
        return (
            <a href={item.link}>
                {item.text}
            </a>
        );
    }

    return (
        <span>{item.text}</span>
    );
};

PCFooterItem.propTypes = {
    item: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.string
    })
};

export default PCFooterItem;
