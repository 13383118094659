import _ from 'lodash';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCOrderCompleteStore from './pc-order-complete.store';
import PCOrderCompleteLine from './pc-order-complete-line.component';

import PCPageLoading from '../shared/components/page-loading/page-loading.component';
import PCLargeError from '../shared/components/large-error/large-error.component';
import PCPriceDisplay from '../shared/components/price-display/price-display.component';
import PCOrderDetailsInformation from '../shared/components/pc-order-details-information/pc-order-details-information.component';
import {makeNewProfileUrl} from '../shared/myjd-url-builder.util';

import './pc-order-complete.component.scss';

@inject('baseStore', 'routerStore')
@observer
class PCOrderComplete extends React.Component {
    constructor(props) {
        super(props);
        this._baseStore = props.baseStore;

        this._orderCompleteStore = new PCOrderCompleteStore(
            props.baseStore,
            props.routerStore
        );

        this.onPrint = this.onPrint.bind(this);
    }

    componentWillUnmount() {
        this._orderCompleteStore.destroy();
    }

    onPrint() {
        window.print();
    }

    render() {
        const order = this._orderCompleteStore.order;
        let dealerDetails;

        if (order === undefined) {
            return (
                <PCPageLoading standalone={true}/>
            );
        }
        if (order === null) {
            return (
                <PCLargeError>
                    <FormattedMessage
                        defaultMessage='Your order was submitted successfully however we are unable to load the completed order details.'
                        id='pc-order-complete.error'
                    />
                </PCLargeError>
            );
        }

        let taxTerm;
        let onlyTaxTerm;
        let taxLabel;
        if(order.taxTermText!==null && order.taxTermText !== undefined){
            taxTerm = order.taxTermText.slice(order.taxTermText.length - 5);
            if (taxTerm.indexOf('(') > -1) {
                onlyTaxTerm = taxTerm.slice(1, 4);
            } else {
                onlyTaxTerm = (
                    <FormattedMessage
                        defaultMessage='Tax'
                        id='pc.tax'
                    />
                );
            }
            if (taxTerm.indexOf('(')===-1) {
                taxTerm=(
                    <FormattedMessage
                        defaultMessage='Tax'
                        id='pc.tax'
                    />);
            }
        }
        let taxPercentage = order.taxPercentage;

        if(order.showTaxPerLineitem){
            taxLabel = (
                <FormattedMessage
                    defaultMessage='Tax on shipping{taxTerm}'
                    id='pc.tax-on-shipping'
                    values={{
                        taxTerm:  taxTerm
                    }}
                />
            );
        }else{
            taxLabel = order.taxTermText;
        }

        let guestMessage;

        if (this._baseStore.userSession.currentUser.idEmail &&
            !this._baseStore.userSession.guestIsRegistered) {
            guestMessage = (
                <div className='row success-block create-account-block'>
                    <h3>
                        <FormattedMessage
                            defaultMessage='Create an Account'
                            id='pc-order-complete.create-account-header'
                        />
                    </h3>
                    <div className='success-message'>
                        <FormattedMessage
                            defaultMessage='You are almost there!  Create an account to save your information for next time and experience a quicker checkout process.'
                            id='pc-order-complete.create-account-message'
                        />

                        <div className='create-account'>
                            <a
                                href={makeNewProfileUrl(
                                    this.props.baseStore,
                                    this.props.routerStore,
                                    order.orderId
                                )}
                                className='btn btn-primary'
                            >
                                <FormattedMessage
                                    defaultMessage='Create Account'
                                    id='pc-order-complete.create-account'
                                />
                            </a>
                        </div>

                    </div>
                </div>
            );
        }

        let shippingValueDisplay;
        let taxValueDisplay;

        if (order.shippingValue !== undefined) {
            shippingValueDisplay = (
                <tr>
                    <td className='caption'>
                        <FormattedMessage
                            defaultMessage='Shipping (Net)'
                            id='pc.shipping'
                        />
                    </td>
                    <td>
                        <PCPriceDisplay
                            currency={order.priceUnit}
                            inline={true}
                            value={order.shippingValue}
                        />
                    </td>
                </tr>
            );
        }

        let subtotalNShippingTaxValue = 0;
        let subtotalNShippingTaxValueDisplay;

        if (order.taxValue !== undefined) {
            subtotalNShippingTaxValue = order.taxValue;

            taxValueDisplay = (
                <tr>
                    <td className='caption'>
                        {taxLabel}
                    </td>
                    <td>
                        <PCPriceDisplay
                            currency={order.priceUnit}
                            inline={true}
                            value={order.taxValue}
                        />
                    </td>
                </tr>
            );
        }

        subtotalNShippingTaxValueDisplay = (
            <tr>
                <td className='caption'>
                    {onlyTaxTerm} ({taxPercentage}%)
                </td>
                <td>
                    <PCPriceDisplay
                        currency={order.priceUnit}
                        inline={true}
                        value={subtotalNShippingTaxValue}
                    />
                </td>
            </tr>
        );

        if (this.props.baseStore && this.props.baseStore.dealer) {
            dealerDetails = this.props.baseStore.dealer.dealerName;
        }
        if (dealerDetails && this.props.baseStore.dealer.formattedAddressLine2) {
            dealerDetails = `${dealerDetails },${this.props.baseStore.dealer.formattedAddressLine2}`;
        }

        let subtotalWithoutTax = order.subOrderValue;
        let totalTaxValueDisplay;

        if (order.showTaxPerLineitem) {
            const orderLines = order.orderLine;
            let totalTax = 0.0;
            orderLines.map((orderLine) =>
                totalTax = totalTax + (orderLine.totalPriceIncludingTax - orderLine.totalNetPrice)
            );
            if (totalTax > 0) {
                totalTaxValueDisplay = (
                    <tr>
                        <td className='caption'>
                            {onlyTaxTerm}
                        </td>
                        <td>
                            <PCPriceDisplay
                                currency={order.priceUnit}
                                inline={true}
                                value={totalTax}
                            />
                        </td>
                    </tr>
                );
            }

            subtotalWithoutTax = subtotalWithoutTax - totalTax

            if (order.taxValue !== undefined)
                subtotalNShippingTaxValue = subtotalNShippingTaxValue + totalTax
            else
                subtotalNShippingTaxValue = totalTax

            subtotalNShippingTaxValueDisplay = (
                <tr>
                    <td className='caption'>
                        {onlyTaxTerm} ({taxPercentage}%)
                    </td>
                    <td>
                        <PCPriceDisplay
                            currency={order.priceUnit}
                            inline={true}
                            value={subtotalNShippingTaxValue}
                        />
                    </td>
                </tr>
            );
        }

        let subTotalLabel = (
            <FormattedMessage
                defaultMessage='Total (Net)'
                id='pc.subtotal'
            />
        );

        return (
            <div className='container page pc-order-complete'>
                <div className='row success-block'>
                    <div className='row'>
                        <div className='col-md-16'>
                            <h3>
                                <FormattedMessage
                                    defaultMessage='Your order was sent successfully to:'
                                    id='pc-order-complete.success-header'
                                />
                            </h3>
                            <h5>{dealerDetails}</h5>
                        </div>
                        <div className='col-md-8 quote-number-header text-right'>
                            <div className='title-aside'>
                                <FormattedMessage
                                    defaultMessage='Quote Number'
                                    id='pc.quote-number'
                                />
                            </div>
                            <div>
                                {order.orderId}
                            </div>
                        </div>
                    </div>
                    <div className='success-message'>
                        <FormattedMessage
                            defaultMessage='Thank you for making your purchase.  We are sending your order information to your dealer to fulfill.  They will confirm any shipping charges and communicate your order total and status.'
                            id='pc-order-complete.success-message'
                        />
                    </div>
                </div>
                {guestMessage}

                <div className='row white-background' style={{padding:'0px 12px 12px 12px'}}>
                    <table className='items-table'>
                        <thead>
                        <tr className='border-bottom'>
                            <th>
                                <FormattedMessage
                                    defaultMessage='Items ({count})'
                                    id='pc.order-details.title-items'
                                    values={{
                                        count:  _.size(order.orderLine)
                                    }}
                                />
                            </th>
                            <th style={{textAlign: 'right'}}>
                                <FormattedMessage
                                    defaultMessage='Unit Weight'
                                    id='pc.weight'
                                />
                            </th>
                            <th style={{textAlign: 'center'}}>
                                <FormattedMessage
                                    defaultMessage='Quantity'
                                    id='pc.quantity'
                                />
                            </th>
                            <th style={{textAlign: 'center'}}>
                                <FormattedMessage
                                    defaultMessage='Order Type'
                                    id='pc.order-type'
                                />
                            </th>
                            <th style={{textAlign: 'right'}}>
                                <FormattedMessage
                                    defaultMessage='Unit Net Price'
                                    id='pc.unit-net-price'
                                />
                            </th>
                            <th style={{textAlign: 'right'}}>
                                <FormattedMessage
                                    defaultMessage='Total Net Price'
                                    id='pc.total-net-price'
                                />
                            </th>
                            <th style={{textAlign: 'right', display: order.showTaxPerLineitem ? '' : 'none'}}>
                                <FormattedMessage
                                    defaultMessage='{taxType} ({taxPercentage}%)'
                                    id='pc.tax-with-type'
                                    values={{
                                        taxType: onlyTaxTerm,
                                        taxPercentage: taxPercentage
                                    }}
                                />
                            </th>
                            <th style={{textAlign: 'right', display: order.showTaxPerLineitem ? '' : 'none'}}>
                                <FormattedMessage
                                    defaultMessage='Total Price'
                                    id='pc.total-incl-tax'
                                    values={{
                                        taxType: onlyTaxTerm,
                                        taxPercentage: taxPercentage
                                    }}
                                />
                            </th>
                        </tr>

                        </thead>
                        <tbody>
                        {
                            _.map(order.orderLine, (orderLine) => {
                                return (
                                    <PCOrderCompleteLine
                                        key={orderLine.partNumber}
                                        currency={order.priceUnit}
                                        showTaxPerLineitem={order.showTaxPerLineitem}
                                        orderLine={orderLine}
                                    />
                                );
                            })
                        }
                        </tbody>
                    </table>

                </div>

                <div className='row totals'>
                    <table className='pull-right'>
                        <tbody>
                            <tr>
                                <td className='caption'>
                                    {subTotalLabel}
                                </td>
                                <td>
                                    <PCPriceDisplay
                                        currency={order.priceUnit}
                                        inline={true}
                                        value={subtotalWithoutTax}
                                    />
                                </td>
                            </tr>
                            {shippingValueDisplay}
                            {subtotalNShippingTaxValueDisplay}
                            <tr className='total'>
                                <td className='caption'>
                                    <FormattedMessage
                                        defaultMessage='Total'
                                        id='pc.total'
                                    />
                                </td>
                                <td>
                                    <PCPriceDisplay
                                        currency={order.priceUnit}
                                        inline={true}
                                        value={order.totalOrderValue}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <FormattedMessage
                                        defaultMessage='*Prices are estimated and subject to change.'
                                        id='pc.disclaimer-message'
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <PCOrderDetailsInformation {...this._orderCompleteStore.order}/>

                <div className='action-buttons-row'>
                    <a
                        className='btn btn-hollow'
                        onClick={this.onPrint}
                    >
                        <FormattedMessage
                            defaultMessage='Print Order'
                            id='pc-order-complete.print'
                        />
                    </a>
                </div>
            </div>
        );
    }
}

export default PCOrderComplete;
