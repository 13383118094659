
export default [
    {
        name: 'order-complete',
        path: '/order-complete/:orderId',
        registeredUserRequired: false,
        dealerSelectorVisible: true,
        cartVisible: true,
        newPartsSearchVisible: true,
        secureCheckoutVisible: true
    }
];
