import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCLargeError from '../shared/components/large-error/large-error.component';

@inject('baseStore')
@observer
class PCGenericError extends React.Component {
    render() {
        let errorMessage;

        if (this.props.baseStore.currentCart.cart === null) {
            errorMessage = (
                <FormattedMessage
                    defaultMessage='Unable to load the cart.'
                    id='pc-generic-error.cart-load-fail'
                />
            );
        } else if (this.props.baseStore.criticalError) {
            errorMessage = (
                <FormattedMessage
                    defaultMessage='An error has occurred.'
                    id='pc-generic-error.unknown'
                />
            );
        } else {
            errorMessage = (
                <FormattedMessage
                    defaultMessage='The page could not be found.'
                    id='pc-generic-error.not-found'
                />
            );
        }

        return (
            <PCLargeError>
                {errorMessage}
            </PCLargeError>
        );
    }
}

export default PCGenericError;
