import _ from 'lodash';

import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCCheckoutSaveOrderLine from './pc-checkout-save-order-line.component';
import PCPriceDisplay from '../../../shared/components/price-display/price-display.component';
import PCQuantitySpinner from '../../../shared/components/quantity-spinner/quantity-spinner.component';
import PCWeightDisplay from '../../../shared/components/weight-display/weight-display.component';

import './pc-checkout-step-review.component.scss';

@inject('baseStore', 'checkoutStore')
@observer
class PCCheckoutStepReviewLine extends React.Component {
    constructor(props) {
        super(props);

        this.onChangeEmergencyOrder = this.onChangeEmergencyOrder.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onUpdateQuantity = this.onUpdateQuantity.bind(this);
        this.onCartUpdateFailed = this.onCartUpdateFailed.bind(this);
    }

    onCartUpdateFailed() {
        this.props.baseStore.showFailAlert(
            <FormattedMessage
                id='pc.cart-update-failed'
                defaultMessage='Unable to update the cart.'
            />
        );
    }

    onChangeEmergencyOrder(event) {
        this.props.checkoutStore.updateOrderLineEmergencyOrder(
            this.props.orderLine,
            event.target.checked
        ).catch(this.onCartUpdateFailed);
    }

    onRemove() {
        this.props.checkoutStore.removeOrderLine(
            this.props.orderLine
        ).catch(this.onCartUpdateFailed);
    }

    onUpdateQuantity(newQuantity) {
        this.props.checkoutStore.updateOrderLineQuantity(
            this.props.orderLine,
            newQuantity
        ).catch(this.onCartUpdateFailed);
    }

    render() {
        let availability;
        let showTaxPerLineItem = this.props.baseStore._cart.cart.showTaxPerLineitem;
        const lineItemTotalWithoutTax = this.props.orderLine.unitPrice * this.props.orderLine.quantity;
        let lineItemTotal;
        let taxPerLineItem;

        if (showTaxPerLineItem) {
            lineItemTotal = lineItemTotalWithoutTax + this.props.orderLine.taxPrice * this.props.orderLine.quantity;
            taxPerLineItem = this.props.orderLine.taxPrice * this.props.orderLine.quantity;
        }

        if (!_.isFinite(this.props.orderLine.quantityAvailable)) {
            availability = (
                <FormattedMessage
                    defaultMessage='Contact Your Dealer'
                    id='pc.contact-your-dealer'
                />
            );
        } else if (this.props.orderLine.quantity > this.props.orderLine.quantityAvailable) {
            availability = (
                <React.Fragment>
                    <FormattedMessage
                        defaultMessage='Available to Order'
                        id='pc-checkout-step-review.available-to-order'
                    />
                    <div className='emergency-order'>
                        <label>
                            <input
                                type='checkbox'
                                checked={this.props.orderLine.isEmergencyOrderLine}
                                onChange={this.onChangeEmergencyOrder}
                            />
                            <FormattedMessage
                                defaultMessage='Emergency Order'
                                id='pc.emergency-order'
                            />
                            <div className='fee'>
                                <FormattedMessage
                                    defaultMessage='(extra fee applies)'
                                    id='pc.emergency-order-fee'
                                />
                            </div>
                        </label>
                    </div>
                </React.Fragment>
            );
        } else {
            availability = (
                <span className='in-stock'>
                    <FormattedMessage
                        defaultMessage='In Stock'
                        id='pc-checkout-step-review.in-stock'
                    />
                </span>
            );
        }

        let actionButtonsAdditionalClass = '';
        let saveButton;

        if (this.props.baseStore.userSession.currentUser.id) {
            // registered user
            saveButton = (
                <PCCheckoutSaveOrderLine
                    orderLine={this.props.orderLine}
                />
            );
        } else {
            actionButtonsAdditionalClass = 'only-remove';
        }

        const actionButtons = (
            <React.Fragment>
                <button
                    className='delete-button'
                    onClick={this.onRemove}
                />
                {saveButton}
            </React.Fragment>
        );

        return (
            <tbody>
                <tr className='part-line-item'>
                    <td  >
                        <h4 className='part-name'>
                            {this.props.orderLine.partDescription || this.props.orderLine.partNumber}
                        </h4>
                        <div>
                            <FormattedMessage
                                defaultMessage='Part Number'
                                id='pc.part-number'
                            />
                            {' '}
                            {this.props.orderLine.partNumber}
                        </div>
                        <div className={`visible-lg action-buttons ${actionButtonsAdditionalClass}`}>
                            {actionButtons}
                        </div>
                    </td>
                    <td >

                        {availability}
                    </td>
                    <td style={{ textAlign : '-webkit-center'}}>
                        <PCQuantitySpinner
                            onChange={this.onUpdateQuantity}
                            quantity={this.props.orderLine.quantity}
                        />
                    </td>
                    <td>
                        <PCWeightDisplay
                            weight={this.props.orderLine.partWeight}
                            unit={this.props.orderLine.weightUnit}
                        />
                    </td>
                    <td >

                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            value={this.props.orderLine.unitPrice}
                        />
                    </td>
                    <td >
                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            value={lineItemTotalWithoutTax}
                        />
                    </td>
                    <td style={{ display: showTaxPerLineItem?'':'none'}}>

                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            value={ taxPerLineItem }
                        />
                    </td>
                    <td style={{ display: showTaxPerLineItem?'':'none'}}>

                        <PCPriceDisplay
                            currency={this.props.checkoutStore.currencyCode}
                            value={ lineItemTotal }
                        />
                    </td>
                    {/*<div className={`col-xs-24 hidden-lg action-buttons ${actionButtonsAdditionalClass}`}>
                        {actionButtons}
                    </div>*/}
                </tr>
            </tbody>

        );
    }
}

PCCheckoutStepReviewLine.propTypes = {
    orderLine: PropTypes.shape({
        quantityAvailable: PropTypes.number,
        partDescription: PropTypes.string,
        partNumber: PropTypes.string.isRequired,
        unitPrice: PropTypes.number,
        taxPrice: PropTypes.number,
        quantity: PropTypes.number.isRequired,
        partWeight: PropTypes.number,
        weightUnit: PropTypes.string,
        isEmergencyOrderLine: PropTypes.boolean
    }).isRequired
};

export default PCCheckoutStepReviewLine;
