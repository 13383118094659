import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from './../fetch-json.util';

class AddressElementsService {
    static getAddressElements(countryCd, localeCd, isGuestUser) {
        return fetchJsonWithAuth(
            buildUrl(ADDRESS_ELEMENTS_URL, {
                queryParams: {
                    'locale-cd': localeCd,
                    'iso2-country-code': countryCd,
                    'guest-user': isGuestUser
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default AddressElementsService;
