import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer, Provider} from 'mobx-react';

import PCCheckoutEmpty from './pc-checkout-empty/pc-checkout-empty.component';
import PCCheckoutLeaving from './pc-checkout-leaving/pc-checkout-leaving.component';
import PCCheckoutSaveCart from './pc-checkout-save-cart/pc-checkout-save-cart.component';
import PCCheckoutSteps from './pc-checkout-steps/pc-checkout-steps.component';
import PCCheckoutSummary from './pc-checkout-summary/pc-checkout-summary.component';

import PCContentHeader from '../shared/components/content-header/content-header.component';

import {makePartsCatalogUrl} from '../shared/parts-catalog-url-builder.util';

import './pc-checkout.component.scss';

@inject('baseStore', 'routerStore', 'checkoutStore')
@observer
class PCCheckout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const routeCartId = _.get(this.props.routerStore, 'route.params.cart-id');
        const currentCart = this.props.baseStore.currentCart;

        let checkoutBody;

        if (routeCartId === undefined ||
            routeCartId && currentCart.cart && currentCart.itemCount === 0) {
            // no cart or empty cart
            checkoutBody = (
                <PCCheckoutEmpty/>
            );
        } else if (this.props.checkoutStore.activeCheckoutStep === 'leaving') {
            // attempt to leave checkout via back button
            checkoutBody = (
                <PCCheckoutLeaving/>
            );
        } else {
            // save cart button and dialog
            let saveButton;

            if (this.props.checkoutStore.saveAvailable) {
                saveButton = (
                    <PCCheckoutSaveCart/>
                );
            }

            // summary sidebar
            let checkoutSummary;
            let dealerDetails;
            let processClass = 'process-container';

            if (this.props.checkoutStore.summaryAvailable) {
                processClass += ' col-lg-16';

                checkoutSummary = (
                    <div className='col-lg-7 col-lg-offset-1'>
                        <div className='summary-margin hidden-lg'/>
                        <PCCheckoutSummary/>
                    </div>
                );
            }

            if (this.props.baseStore && this.props.baseStore.dealer) {
                dealerDetails = this.props.baseStore.dealer.dealerName;
            }

            if (dealerDetails && this.props.baseStore.dealer.formattedAddressLine2) {
                dealerDetails = `${dealerDetails },${this.props.baseStore.dealer.formattedAddressLine2}`;
            }

            checkoutBody = (
                <div className='row'>
                    <div className={processClass}>
                        <PCContentHeader button={saveButton}>
                            <FormattedMessage
                                defaultMessage='Items sold & shipped by:'
                                id='pc.sold-shipped-by'
                            />
                            <span className='navigation continue-shopping-link'>
                                <a href={makePartsCatalogUrl(this.props.baseStore)}>
                                    <FormattedMessage
                                        id='pc.continue-shopping'
                                        defaultMessage='Continue Shopping'
                                    />
                                </a>
                            </span>
                        </PCContentHeader>
                        <h5>{dealerDetails}</h5>
                        <div className='content'>
                            <PCCheckoutSteps/>
                        </div>
                    </div>

                    {checkoutSummary}
                </div>
            );
        }

        return (
            <Provider
                checkoutStore={this.props.checkoutStore}
            >
                <div className='checkout white-background'>
                    <div className='container page'>
                        {checkoutBody}
                    </div>
                </div>
            </Provider>
        );
    }
}

export default PCCheckout;
