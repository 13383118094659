import {observable, reaction, action, toJS} from 'mobx';
import _ from 'lodash';
import WishlistService from '../shared/services/wishlist.service';

export default class PCWishlist {
    @observable _wishlist;

    constructor(baseStore, routerStore) {
        this._baseStore = baseStore;
        this._routerStore = routerStore;

        this._reactions = [
            reaction(() => {
                return {
                    customerId: _.get(this._baseStore, 'userSession.currentUser.id'),
                    dealerId: _.get(this._baseStore, 'dealer.dealerId'),
                    locale: this._baseStore.locale
                };
            }, ({
                customerId, dealerId, locale
            }) => {
                if (!customerId || !dealerId || !locale) {
                    return;
                }

                if (!this._wishlist) {
                    WishlistService.getWishlist(locale, dealerId, customerId)
                        .then(action((wishlist) => {
                            this._wishlist = wishlist;
                        }))
                        .catch(action(() => {
                            this._wishlist = null;
                        }));
                }
            }, {
                fireImmediately: true
            }),
            // when the location ID changes delete the wishlist so it can be reloaded
            reaction(
                () => _.get(this._baseStore, 'dealer.locationId'),
                () => {
                    this._wishlist = undefined;
                }
            )
        ];
    }

    @action
    updateWishlistPartQuantity(part, newQuantity) {
        part.quantity = newQuantity;
    }

    @action.bound
    deleteWishlistPart(part) {
        const originalWishlist = toJS(this._wishlist);

        this._wishlist.remove(part);

        return WishlistService.deleteWishlistPart(
            part.partNumber,
            _.get(this._baseStore, 'userSession.currentUser.id')
        ).catch(action(() => {
            this._wishlist = originalWishlist;
            return Promise.reject();
        }));
    }

    destroy() {
        this._reactions.forEach((destroyReaction) => destroyReaction());
    }

    get wishlist() {
        return this._wishlist;
    }
}
