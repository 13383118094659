import _ from 'lodash';

import {action, observable, reaction} from 'mobx';

import ProfileDealersService from '../shared/services/profile-dealers.service';
import CustomerInfoService from '../shared/services/customer-info.service';

class PCProfileDealers {
    // used to ask the user to change or activate their profile dealer
    @observable _nonProfileDealer;
    @observable _suggestedProfileDealer;
    @observable _replaceError = false;

    // use to ask the user to pick one of their profile dealers
    @observable _chooseProfileDealers;

    // once the user interactions are complete this final set is sent to the dealer selector
    @observable _cleanProfileDealers;

    constructor(baseStore, dealerSelectorStore, routerStore) {
        this.baseStore = baseStore;
        this.dealerSelectorStore = dealerSelectorStore;

        // get the profile dealers when it becomes possible to do so
        reaction(
            () => {
                let routeDealerId = _.get(routerStore.router.getState(), 'params.dealer-id');

                return {
                    locale: baseStore.locale,
                    userId: _.get(baseStore.userSession.currentUser, 'id'),
                    dealerId: _.get(baseStore.dealer, 'dealerId') || routeDealerId,
                    dealerSelectorInitialized: dealerSelectorStore.initialized,
                    dealerSelectorOpen: baseStore.dealerSelectorOpen
                };
            },
            (data) => {
                if (!data.locale ||
                    !data.dealerSelectorInitialized ||
                    !data.userId ||
                    data.dealerSelectorOpen) {
                    // No locale OR dealer selector not ready, we can't do anything with dealers yet.
                    // OR
                    // No registered user, profile dealers are only for registered users.
                    // OR
                    // The dealer selector is open, meaning we have already gone through
                    // this once and decided to prompt the user for a dealer.  Once the
                    // dealer selector is closed we'll run through this logic again.
                    return;
                }

                // clear the message code, we might have set it to ask the user to pick a dealer
                this.dealerSelectorStore.setDialogMessageCode();

                const matchingProfileDealer = _.find(this._cleanProfileDealers, {
                    dealerId: data.dealerId
                });

                if (matchingProfileDealer) {
                    // the newly selected dealer is in the profile dealers, set it as selected
                    _.each(this._cleanProfileDealers, (profileDealer) => {
                        profileDealer.selected = false;
                    });
                    matchingProfileDealer.selected = true;
                    return;
                }

                ProfileDealersService.getProfileDealers(
                    data.locale,
                    data.userId,
                    data.dealerId
                ).then(action((profileDealers) => {
                    // conversion to objects that dealer selector can use
                    this._mappedProfileDealers = _.map(profileDealers, (profileDealer) => {
                        profileDealer.selected = profileDealer.dealerId === data.dealerId;

                        return profileDealer;
                    });
                    const selectedNonProfileDealer = _.find(
                        this._mappedProfileDealers,
                        {
                            selected: true,
                            isProfileDealer: false
                        }
                    );

                    if (selectedNonProfileDealer) {
                        // selected dealer is a non-profile dealer, ask the user what to do
                        this._nonProfileDealer = selectedNonProfileDealer;
                        this._suggestedProfileDealer = _.find(
                            this._mappedProfileDealers,
                            (profileDealer) => {
                                return profileDealer.dealerId !== selectedNonProfileDealer.dealerId &&
                                        profileDealer.industryName === selectedNonProfileDealer.industryName;
                            }
                        );
                    } else if (!_.isEmpty(this._mappedProfileDealers) &&
                        _.countBy(this._mappedProfileDealers, 'selected').true === undefined) {
                        // there are no selected profile dealers

                        if (_.size(this._mappedProfileDealers) === 1) {
                            // only one, auto-select it
                            this.selectProfileDealer(this._mappedProfileDealers[0]);
                        } else {
                            // more than one, ask the user to pick
                            this._chooseProfileDealers = this._mappedProfileDealers;
                        }
                    } else if (_.isEmpty(this._mappedProfileDealers)) {
                        // the user does not have a profile dealer, force them to pick one using the dealer selector
                        this.dealerSelectorStore.setDialogMessageCode('selectDealer');
                        this.dealerSelectorStore.changeDealer();
                    } else {
                        // don't need to ask the user anything
                        this._cleanProfileDealers = this._mappedProfileDealers;
                    }
                })).catch(() => {
                    this.baseStore.showCriticalError();
                });
            },
            {
                fireImmediately: true
            }
        );
    }

    @action.bound
    _resetChangePrompt() {
        this._nonProfileDealer = undefined;
        this._suggestedProfileDealer = undefined;
        this._replaceError = false;
    }

    @action.bound
    useProfileDealer() {
        this._cleanProfileDealers = _.reject(
            this._mappedProfileDealers,
            (profileDealer) => {
                // select the dealer that was suggested
                profileDealer.selected = profileDealer.dealerId === this._suggestedProfileDealer.dealerId;

                // only keep the profile dealers
                return !profileDealer.isProfileDealer;
            }
        );

        this._resetChangePrompt();
    }

    @action.bound
    replaceProfileDealer() {
        this.baseStore.showLoadingOverlay();

        CustomerInfoService.putCustomerInfo(
            this._nonProfileDealer.dealerId
        ).then(action(() => {
            this._cleanProfileDealers = _.reject(
                this._mappedProfileDealers,
                (profileDealer) => {
                    // select the dealer that we replaced
                    profileDealer.selected = profileDealer.dealerId === this._nonProfileDealer.dealerId;
                    // set everything as a profile dealer, only the one we just used to replace would be non-profile
                    profileDealer.isProfileDealer = true;

                    // don't keep the profile dealer that we replaced
                    return profileDealer.dealerId === this._suggestedProfileDealer.dealerId;
                }
            );
            this.baseStore.currentCart.fetchCart();
            this._resetChangePrompt();
            this.baseStore.hideLoadingOverlay();
        })).catch(action(() => {
            this._replaceError = true;
            this.baseStore.hideLoadingOverlay();
        }));
    }

    @action.bound
    selectProfileDealer(profileDealer) {
        profileDealer.selected = true;

        // this can be called from the choose dialog or
        // if there is only one profile dealer found during the mapping process
        this._cleanProfileDealers = this._chooseProfileDealers || this._mappedProfileDealers;
        this._chooseProfileDealers = undefined;
    }

    get nonProfileDealer() {
        return this._nonProfileDealer;
    }
    get suggestedProfileDealer() {
        return this._suggestedProfileDealer;
    }
    get chooseProfileDealers() {
        return this._chooseProfileDealers;
    }
    get cleanProfileDealers() {
        return this._cleanProfileDealers;
    }
    get replaceError() {
        return this._replaceError;
    }
}

export default PCProfileDealers;
