import _ from 'lodash';
import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth, fetchWithAuth} from './../fetch-json.util';

const HTTP_CREATED = 201;

export default class WishlistService {
    static getWishlist(localeCd, dealerId) {
        return fetchJsonWithAuth(
            buildUrl(WISHLIST_URL, {
                queryParams: {
                    'locale-cd': localeCd,
                    'dealer-id': dealerId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }

    static addWishlistPart(dealerId, partNumber, quantity) {
        return fetchWithAuth(buildUrl(WISHLIST_URL, {
            queryParams: {
                'dealer-id': dealerId,
                'part-number': partNumber,
                'quantity': quantity
            }
        }), {
            method: 'POST',
            headers: {
                'Content-Type': JD_JSON_CONTENT_TYPE_V1
            }
        }).then((response) => {
            if (response.ok) {
                if (response.status === HTTP_CREATED) {
                    return undefined;
                }
                return response.json();
            }
            return Promise.reject();
        }).then((responseJSON) => {
            if (!_.isEmpty(responseJSON)) {
                return camelcaseKeys(responseJSON);
            }

            return undefined;
        });
    }

    static deleteWishlistPart(partNumber) {
        return fetchWithAuth(buildUrl(WISHLIST_URL, {
            queryParams: {
                'part-number': partNumber
            }
        }), {
            method: 'DELETE'
        }).then((response) => {
            if (response.ok) {
                return response;
            }
            return Promise.reject();
        });
    }
}
