
import React from 'react';
import {inject, observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';

import './pc-header.component.scss';

@inject('baseStore')
@observer
class PCHeader extends React.Component {
    render() {
        let secureCheckout;
        let displayBaner;
        const url = 'partscatalog.deere.com';
        const rootUrl = WEBPACK_ROOT_URL.split('/')[2];

        if (this.props.baseStore.routeBaseInfo.secureCheckoutVisible) {
            secureCheckout = (
                <div className='secure-checkout'>
                    <FormattedMessage
                        defaultMessage='Secure Checkout'
                        id='pc.secure-checkout'
                    />
                </div>
            );
        }

        if (rootUrl !== 'partscheckout.deere.com') {
            displayBaner = (
                <div className='non-prod-banner'>
                    <div>
                        {'Please note that this is a test site which cannot be used to order parts.'}
                    </div>
                    <div>
                        <span>{'To order parts please use '}
                            <a href='https://partscatalog.deere.com'>{url}</a>
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className='row header-uxf'>
                <div className='col-lg-5'>
                    <div className='icon-uxf-logo'>
                        <span className='sr-only'>{'John Deere'}</span>
                    </div>
                </div>
                <div className='col-lg-14'>
                    {displayBaner}
                </div>
                <div className='col-lg-5'>
                    {secureCheckout}
                </div>

            </div>

        );
    }
}

export default PCHeader;
