import _ from 'lodash';

import React from 'react';
import {inject, PropTypes} from 'mobx-react';
import {PropTypes as ReactPropTypes} from 'prop-types';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';
import {FormattedMessage} from 'react-intl';

@inject('baseStore')
class ItemsTableBody extends React.Component {
    render() {
        const {
            items, currency, showTaxPerLineitem
        } = this.props;
        const rows = items.map(
            (item, index) => {
                const addPartByIdToCart = _.partial(
                    this.props.baseStore.currentCart.addPartByIdToCart,
                    item.partNumber,
                    item.quantity,
                    'Order History'
                );

                let weightDisplay;

                if (item.weight && item.weightUnit) {
                    weightDisplay = `${item.weight} ${item.weightUnit}`;
                }else{
                    weightDisplay = (
                        <FormattedMessage
                            defaultMessage='Contact Your Dealer'
                            id='pc.contact-your-dealer'
                        />
                    );
                }

                return (
                    <React.Fragment>
                        <tr>
                            <td className='part-description'>
                                {item.partDescription || item.partNumber}
                            </td>
                            <td rowSpan={3} style={{textAlign: 'right'}}>
                                {weightDisplay}
                            </td>

                            <td rowSpan={3} style={{textAlign: 'center', minWidth: '125px'}}>
                                {item.quantity}
                            </td>
                            <td rowSpan={3} style={{textAlign: 'center'}}>
                                {item.orderType}
                            </td>
                            <td rowSpan={3} style={{textAlign: 'right'}}>
                                <PCPriceDisplay
                                    currency={currency}
                                    inline={true}
                                    value={item.unitPrice}
                                />
                            </td>
                            <td rowSpan={3} style={{textAlign: 'right'}}>
                                <PCPriceDisplay
                                    currency={currency}
                                    inline={true}
                                    value={item.totalNetPrice}
                                />
                            </td>
                            <td rowSpan={3} style={{textAlign: 'right', display: showTaxPerLineitem ? '' : 'none'}}>
                                <PCPriceDisplay
                                    currency={currency}
                                    inline={true}
                                    value={item.totalPriceIncludingTax}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <FormattedMessage
                                    defaultMessage='Part Number'
                                    id='pc.part-number'
                                />
                                {' '}
                                <span>{item.partNumber}</span>
                            </td>
                        </tr>
                        <tr className='border-bottom'>
                            <td>
                                <button
                                    className='hidden-xs hidden-sm hidden-md btn btn-hollow'
                                    onClick={addPartByIdToCart}
                                    style={{ margin: '15px 0px 15px 0px'}}
                                >
                                    <FormattedMessage
                                        defaultMessage='Add to Cart'
                                        id='pc.add-to-cart'
                                    />
                                </button>
                            </td>
                        </tr>
                    </React.Fragment>

                );
            }
        );

        return (
            <React.Fragment>{rows}</React.Fragment>
        );
    }
}

ItemsTableBody.propTypes = {
    items: PropTypes.arrayOrObservableArray,
    currency: ReactPropTypes.string,
    showTaxPerLineitem: PropTypes.bool
};

export default ItemsTableBody;
