import React from 'react';
import PCPriceDisplay from '../../shared/components/price-display/price-display.component';
import PropTypes from 'prop-types';

const PCOrderDetailsSummaryItem = ({
    messageComponent, currency, value, className
}) => {
    if (value === undefined) {
        return null;
    }

    return (
        <tr className={`text-right ${className || ''}`}>
            <td className='caption'>
                {messageComponent}
            </td>
            <td>
                <PCPriceDisplay
                    currency={currency}
                    value={value}
                    inline={true}
                />
            </td>
        </tr>
    );
};

PCOrderDetailsSummaryItem.propTypes = {
    messageComponent: PropTypes.element.isRequired,
    currency: PropTypes.string,
    value: PropTypes.number,
    className: PropTypes.string
};

export default PCOrderDetailsSummaryItem;
