import buildUrl from 'build-url';
import camelcaseKeys from 'camelcase-keys-recursive';
import {fetchJsonWithAuth} from './../fetch-json.util.js';

class MerchantInfoService {
    static getMerchantInfo(dealerId) {
        return fetchJsonWithAuth(
            buildUrl(MERCHANT_INFO_URL, {
                queryParams: {
                    'dealer-id': dealerId
                }
            })
        ).then((result) => {
            return camelcaseKeys(result);
        });
    }
}

export default MerchantInfoService;
