
import React from 'react';
import {inject, observer} from 'mobx-react';
import {FormattedMessage} from 'react-intl';
import RouteLink from '../../shared/components/route-link/route-link.component';
import PCPartsCatalogLink from '../pc-parts-catalog-link/pc-parts-catalog-link.component';

import './pc-sub-header.component.scss';

const maxPartsDisplay = 99;

@inject('baseStore', 'dealerSelectorStore')
@observer
class PCSubHeader extends React.Component {
    constructor(props) {
        super(props);

        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.addDealerSelector = this.addDealerSelector.bind(this);
    }

    componentWillUnmount() {
        this.props.dealerSelectorStore.detach();
    }

    addDealerSelector(targetElement) {
        if (!targetElement) {
            this.props.dealerSelectorStore.detach();
            return;
        }

        this.props.dealerSelectorStore.attach(targetElement);
    }

    render() {
        let userNameDisplay = undefined;
        if (this.props.baseStore.loginVisible) {
            return null;
        }

        // All pages need the dealer selector in some capacity so just hide the
        // header component instead of not rendering it.
        // Using height 0 instead of visibility because we need the modal dialog
        // hosted by the component to be able to show.
        let dealerSelectorStyle;

        if (!this.props.baseStore.routeBaseInfo.dealerSelectorVisible) {
            dealerSelectorStyle = {
                overflow: 'hidden',
                height: 0
            };
        }

        let newPartSearchLink;

        if (this.props.baseStore.routeBaseInfo.newPartSearchVisible) {
            newPartSearchLink = (
                <div className='new-parts-search'>
                    <PCPartsCatalogLink/>
                </div>
            );
        }

        let cart;

        if (this.props.baseStore.routeBaseInfo.cartVisible) {
            let itemCount = this.props.baseStore.currentCart.itemCount;

            if (itemCount > maxPartsDisplay) {
                itemCount = `${maxPartsDisplay}+`;
            }
            cart = (
                <RouteLink
                    className='cart-button'
                    route='checkout'
                >
                    <span>{itemCount}</span>
                </RouteLink>
            );
        }
        const currentUser = this.props.baseStore.userSession.currentUser;
        if (currentUser) {
            if (currentUser.id) {
                userNameDisplay = (
                    <FormattedMessage
                        defaultMessage='Hi, {name}'
                        id='pc-sidebar-menu.hello-registered'
                        values={currentUser}
                    />
                );
            } else {
                userNameDisplay = (
                    <FormattedMessage
                        defaultMessage='Hi Guest'
                        id='pc-sidebar-menu.hello-guest'
                    />
                );
            }
        }


        return (
            <div className='pc-sub-header'>
                <div className='content'>
                    <div
                        style={dealerSelectorStyle}
                        className='dealer-selector-host'
                        ref={this.addDealerSelector}
                    />
                </div>
                {newPartSearchLink}
                {cart}
                <span className= 'ds-container ds-title'>{userNameDisplay}</span>
                <button
                    className='menu-button'
                    onClick={this.props.baseStore.toggleSidebarMenu}
                />
            </div>
        );
    }
}

export default PCSubHeader;
