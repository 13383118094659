
export default [
    {
        name: 'authenticate',
        path: '/authenticate',
        registeredUserRequired: false,
        dealerSelectorVisible: false,
        cartVisible: false,
        newPartSearchVisible: false,
        secureCheckoutVisible: false
    }
];
