import _ from 'lodash';

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {inject, observer} from 'mobx-react';

import PCCheckoutStepContainer from './pc-checkout-step-container.component';
import PCCheckoutStepReview from './pc-checkout-step-review/pc-checkout-step-review.component';
import PCCheckoutStepShippingMethod from './pc-checkout-step-shipping-method/pc-checkout-step-shipping-method.component';
import PCCheckoutStepShippingInfo from './pc-checkout-step-shipping-info/pc-checkout-step-shipping-info.component';
import PCCheckoutStepPaymentMethod from './pc-checkout-step-payment-method/pc-checkout-step-payment-method.component';
import PCCheckoutStepPaymentInfo from './pc-checkout-step-payment-info/pc-checkout-step-payment-info.component';
import PCCheckoutStepConfirm from './pc-checkout-step-confirm/pc-checkout-step-confirm.component';

@inject('checkoutStore')
@observer
class PCCheckoutSteps extends React.Component {
    _stepDefinitions = {
        'review': (
            <FormattedMessage
                defaultMessage='Order Review'
                id='pc-checkout-steps.order-review'
            >
                {
                    (title) =>
                        (<PCCheckoutStepContainer
                            id='review'
                            title={title}
                        >
                            <PCCheckoutStepReview/>
                        </PCCheckoutStepContainer>)
                }
            </FormattedMessage>
        ),
        'shipping-method': (
            <FormattedMessage
                defaultMessage='Shipping Method'
                id='pc-checkout-steps.shipping-method'
            >
                {
                    (title) =>
                        (<PCCheckoutStepContainer
                            id='shipping-method'
                            title={title}
                        >
                            <PCCheckoutStepShippingMethod/>
                        </PCCheckoutStepContainer>)
                }
            </FormattedMessage>
        ),
        'shipping-info': (
            <FormattedMessage
                defaultMessage='Shipping Information'
                id='pc-checkout-steps.shipping-info'
            >
                {
                    (title) =>
                        (<PCCheckoutStepContainer
                            id='shipping-info'
                            title={title}
                        >
                            <PCCheckoutStepShippingInfo/>
                        </PCCheckoutStepContainer>)
                }
            </FormattedMessage>
        ),
        'payment-method': (
            <FormattedMessage
                defaultMessage='Payment Method'
                id='pc-checkout-steps.payment-method'
            >
                {
                    (title) =>
                        (<PCCheckoutStepContainer
                            id='payment-method'
                            title={title}
                        >
                            <PCCheckoutStepPaymentMethod/>
                        </PCCheckoutStepContainer>)
                }
            </FormattedMessage>
        ),
        'payment-info': (
            <FormattedMessage
                defaultMessage='Payment Information'
                id='pc-checkout-steps.payment-info'
            >
                {
                    (title) =>
                        (<PCCheckoutStepContainer
                            id='payment-info'
                            title={title}
                        >
                            <PCCheckoutStepPaymentInfo/>
                        </PCCheckoutStepContainer>)
                }
            </FormattedMessage>
        ),
        'confirm': (
            <FormattedMessage
                defaultMessage='Confirm Order'
                id='pc-checkout-steps.confirm-order'
            >
                {
                    (title) =>
                        (<PCCheckoutStepContainer
                            id='confirm'
                            title={title}
                        >
                            <PCCheckoutStepConfirm/>
                        </PCCheckoutStepContainer>)
                }
            </FormattedMessage>
        )
    };

    render() {
        const stepComponents = _.map(this.props.checkoutStore.availableCheckoutSteps, (stepId) => {
            return (
                <div key={stepId}>
                    {this._stepDefinitions[stepId]}
                </div>
            );
        });

        return (
            <div>
                {stepComponents}
            </div>
        );
    }
}

export default PCCheckoutSteps;
