import React from 'react';
import {inject, observer} from 'mobx-react';

import PCProfileDealersChooseDialog from './pc-profile-dealers-choose-dialog.component';
import PCProfileDealersSwitchDialog from './pc-profile-dealers-switch-dialog.component';

import './pc-profile-dealers.component.scss';

@inject('baseStore')
@observer
class PCProfileDealers extends React.Component {
    render() {
        const profileDealers = this.props.baseStore.profileDealers;

        if (!profileDealers) {
            return null;
        }
        if (profileDealers.nonProfileDealer &&
            profileDealers.suggestedProfileDealer) {
            return (<PCProfileDealersSwitchDialog/>);
        }
        if (profileDealers.chooseProfileDealers) {
            return (<PCProfileDealersChooseDialog/>);
        }

        return null;
    }
}

export default PCProfileDealers;
